import React, {Fragment, useState} from 'react';

import {isValidEmail} from "../../Utils/FormValidators";
import Button from "../../Elements/Button/Button";
import Dialog from "../../Elements/Dialog/Dialog";
import Input from "../../Elements/Input/Input";

const GiftBookButton = ({onGiftBook, ...props}) => {
    const [giftModalOpen, setGiftModalOpen] = useState(false);
    const [giftEmail, setGiftEmail] = useState('');
    const [error, setError] = useState(null);
    const [bookGifted, setBookGifted] = useState(null);

    const handleEmailInputBlur = () => {
        if (!!giftEmail && !isValidEmail(giftEmail)) {
            setError('Invalid e-mail address');
        } else {
            setError(null);
        }
    }

    const handleSendGift = async () => {
        const response = await onGiftBook(giftEmail);

        setBookGifted({
            status: response,
        });
    }

    const handleCloseModal = () => {
        setGiftModalOpen(false);
        setGiftEmail('');
        setError(null);
        setBookGifted(null);
    }

    return <Fragment>
        <Button icon="gift" iconPosition="left" size="large" {...props} onClick={() => setGiftModalOpen(true)}>
            <span className="BoldText UppercaseText">Gift</span>
        </Button>
        <Dialog open={giftModalOpen} onClose={handleCloseModal}>
            {!bookGifted && <Fragment>
                <h2>Gift this book</h2>
                <p className="MarginBottom6">Enter e-mail address of the person you wish to send this book as a gift and the person will receive and e-mail with instructions.</p>
                <Input color="dark" error={!!error} placeholder="E-mail" value={giftEmail} onChange={setGiftEmail} onBlur={handleEmailInputBlur}/>
                {!!error && <div className="DangerText MarginTop2">{error}</div>}
                <Button className="MarginTop4" onClick={handleSendGift} disabled={!giftEmail || !isValidEmail(giftEmail)} stretch size="large" color="turquoise" iconPosition="left" icon="gift">
                    <span className="SemiBoldText">Send Gift</span>
                </Button>
            </Fragment>}
            {bookGifted && <Fragment>
                {bookGifted.status && <div className="MarginBottom6">
                    <h2 className="MarginBottom2">Gift sent!</h2>
                    <p>The book has been successfully sent to {giftEmail}. They should receive an e-mail shortly.</p>
                </div>}
                {!bookGifted.status && <div className="MarginBottom6">
                    <h2 className="MarginBottom2">Whoops</h2>
                    <p>There seems to be an error while trying to send this gift. Please try again.</p>
                </div>}
                <Button onClick={handleCloseModal} stretch size="large" color="turquoise">
                    <span className="SemiBoldText">Close</span>
                </Button>
            </Fragment>}
        </Dialog>
    </Fragment>;
};

export default GiftBookButton;
