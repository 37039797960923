import React from 'react';

import './PageEmptyState.scss';

const PageEmptyState = ({title, description, actions}) => {
    return (
        <div className="PageEmptyState">
            <div className="PageEmptyState__Image">
                <div className="PageEmptyState__Image__Placeholder"/>
            </div>
            <h2 className="PageEmptyState__Heading">{title}</h2>
            <div className="PageEmptyState__Description">{description}</div>
            <div className="PageEmptyState__Actions">{actions}</div>
        </div>
    );
};

export default PageEmptyState;
