import React, {Component} from 'react';
import Icon from "../../Elements/Icon/Icon";
import {getFormattedScrptPrice} from "../../Utils/Ethereum";

import './AddTokensAllowanceStep.scss';
import {SupportedTokens} from "../../Common/constants";

class AddTokensAllowanceStep extends Component {
    render() {
        const {amount, onSetAllowance} = this.props;

        return (
            <div className="AddTokensAllowanceStep">
                <h2 className="AddTokensModal__StepHeading">Allow the transaction</h2>
                <p className="AddTokensModal__StepDescription">You are holding the complete ownership of your money. This is why we need your permission to move your funds.</p>
                <div className="AddTokensAllowanceStep__Options">
                    <div className="AddTokensAllowanceStep__Option" onClick={() => onSetAllowance('once')}>
                        <div className="AddTokensAllowanceStep__Option__Heading">
                            <div className="AddTokensAllowanceStep__Option__Heading__Label">One-time Approval</div>
                            <Icon className="AddTokensAllowanceStep__Option__Heading__Icon" icon="chevron-right"/>
                        </div>
                        <div className="AddTokensAllowanceStep__Option__Description">
                            Allow Scriptarnica to use <span className="BoldText">{getFormattedScrptPrice(amount || 0, SupportedTokens.USDT)} USDT</span> this time only.
                        </div>
                    </div>
                    <div className="AddTokensAllowanceStep__Option" onClick={() => onSetAllowance('max')}>
                        <div className="AddTokensAllowanceStep__Option__Tag">Recommended</div>
                        <div className="AddTokensAllowanceStep__Option__Heading">
                            <div className="AddTokensAllowanceStep__Option__Heading__Label">Unlimited Approval</div>
                            <Icon className="AddTokensAllowanceStep__Option__Heading__Icon" icon="chevron-right"/>
                        </div>
                        <div className="AddTokensAllowanceStep__Option__Description">
                            Allow Scriptarnica to use your funds in the future in order to save time and transaction fee.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



export default AddTokensAllowanceStep;
