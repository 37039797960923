import {countries, languages} from 'countries-list';

/**
 * @enum {string}
 */
import {ethers} from "ethers";

/**
 * @enum {string}
 */
export const LocalStorageKeys = {
    INTRODUCTION_PASSED: 'scrpt_introduction_finished',
    APP_SETTINGS: 'scrpt_app_settings',
    RECENT_SEARCHES: 'scriptarnica.recent_searches',
};

/**
 * @enum {string}
 */
export const DeviceTypes = {
    MOBILE: 'mobile',
    TABLET: 'tablet',
    DESKTOP: 'desktop',
};

/**
 * @enum {number}
 */
export const DeviceCutoffMap = {
    [DeviceTypes.MOBILE]: 766,
    [DeviceTypes.TABLET]: 957,
};

/**
 * @enum {string}
 */
export const FormInputTypes = {
    TEXT: 'text',
    TEXTAREA: 'textarea',
    SELECT: 'select',
    NUMBER: 'number',
    UPLOAD: 'upload',
};

/**
 * @enum {string}
 */
export const AppThemeTypes = {
    DEFAULT: 'default',
    DARK: 'dark',
    TRADITIONAL: 'traditional',
    GREEN: 'green',
    BLUE: 'blue',
};

/**
 * @enum {string}
 */
export const AppFontSizeTypes = {
    EXTRA_SMALL: 'extra_small',
    SMALL: 'small',
    NORMAL: 'normal',
    MEDIUM: 'medium',
    LARGE: 'large',
    EXTRA_LARGE: 'extra_large',
};

/**
 * @enum {string}
 */
export const AppFontFamilyTypes = {
    OPEN_SANS: 'open_sans',
    LITERATA: 'literata',
    ROBOTO: 'roboto',
    MERRIWEATHER: 'merriweather',
};

/**
 * @enum {string}
 */
export const AppSpacingTypes = {
    CONDENSED: 'condensed',
    NORMAL: 'normal',
    SPREAD: 'spread',
};

/**
 * @enum {string}
 */
export const GenreTypes = {
    MUSIC: 'music',
    POLITICS: 'politics',
    RELIGION: 'religion',
}

/**
 * @enum {string}
 */
export const CategoryTypes = {
    ART_ARCHITECTURE: 'art_architecture',
    BIOGRAPHY: 'biography',
    BUSINESS: 'business',
    CHILDRENS: 'childrens',
    CLASSICS: 'classics',
    COOKBOOK: 'cookbook',
    CRIME: 'crime',
    LANGUAGES: 'languages',
    ENCYCLOPEDIA: 'encyclopedia',
    ADVENTURE: 'adventure',
    SAGA: 'saga',
    MYTHS_LEGENDS: 'myths_legends',
    HISTORICAL: 'historical',
    HEALTH_FITNESS: 'health_fitness',
    HORROR: 'horror',
    HUMOR: 'humor',
    MANGA: 'manga',
    PHILOSOPHY: 'philosophy',
    POLITICS: 'politics',
    PSYCHOLOGY: 'psychology',
    RELIGION: 'religion',
    SCIENCE: 'science',
    SELF_HELP: 'self_help',
    SPORTS: 'sports',
    TECHNOLOGY: 'technology',
    TRAVEL: 'travel',
    MYSTERY: 'mystery',
    ROMANCE: 'romance',
    NOVEL: 'novel',
    FICTION: 'fiction',
    POETRY: 'poetry',
    PLAY: 'play',
    SCI_FI: 'sci-fi',
    SHORT_STORIES: 'short-stories',
    NON_FICTION: 'non-fiction',
    THRILLER: 'thriller',
}

export const CategoriesData = {
    [CategoryTypes.PLAY]: {
        slug: CategoryTypes.PLAY,
        label: 'Play',
        icon: "cat-mistery",
    },
    [CategoryTypes.ART_ARCHITECTURE]: {
        slug: CategoryTypes.ART_ARCHITECTURE,
        label: 'Art & Architecture',
        icon: "cat-mistery",
    },
    [CategoryTypes.BIOGRAPHY]: {
        slug: CategoryTypes.BIOGRAPHY,
        label: 'Biography',
        icon: "cat-mistery",
    },
    [CategoryTypes.BUSINESS]: {
        slug: CategoryTypes.BUSINESS,
        label: 'Business',
        icon: "cat-mistery",
    },
    [CategoryTypes.CHILDRENS]: {
        slug: CategoryTypes.CHILDRENS,
        label: 'Children\'s',
        icon: "cat-mistery",
    },
    [CategoryTypes.CLASSICS]: {
        slug: CategoryTypes.CLASSICS,
        label: 'Classics',
        icon: "cat-mistery",
    },
    [CategoryTypes.COOKBOOK]: {
        slug: CategoryTypes.COOKBOOK,
        label: 'Cookbook',
        icon: "cat-mistery",
    },
    [CategoryTypes.CRIME]: {
        slug: CategoryTypes.CRIME,
        label: 'Crime',
        icon: "cat-mistery",
    },
    [CategoryTypes.LANGUAGES]: {
        slug: CategoryTypes.LANGUAGES,
        label: 'Languages',
        icon: "cat-mistery",
    },
    [CategoryTypes.ENCYCLOPEDIA]: {
        slug: CategoryTypes.ENCYCLOPEDIA,
        label: 'Encyclopedia',
        icon: "cat-mistery",
    },
    [CategoryTypes.ADVENTURE]: {
        slug: CategoryTypes.ADVENTURE,
        label: 'Adventure',
        icon: "cat-mistery",
    },
    [CategoryTypes.SAGA]: {
        slug: CategoryTypes.SAGA,
        label: 'Saga',
        icon: "cat-mistery",
    },
    [CategoryTypes.MYTHS_LEGENDS]: {
        slug: CategoryTypes.MYTHS_LEGENDS,
        label: 'Myths & Legends',
        icon: "cat-mistery",
    },
    [CategoryTypes.HISTORICAL]: {
        slug: CategoryTypes.HISTORICAL,
        label: 'Historical',
        icon: "cat-mistery",
    },
    [CategoryTypes.HEALTH_FITNESS]: {
        slug: CategoryTypes.HEALTH_FITNESS,
        label: 'Health & Fitness',
        icon: "cat-mistery",
    },
    [CategoryTypes.HORROR]: {
        slug: CategoryTypes.HORROR,
        label: 'Horror',
        icon: "cat-mistery",
    },
    [CategoryTypes.HUMOR]: {
        slug: CategoryTypes.HUMOR,
        label: 'Humor',
        icon: "cat-mistery",
    },
    [CategoryTypes.MANGA]: {
        slug: CategoryTypes.MANGA,
        label: 'Manga',
        icon: "cat-mistery",
    },
    [CategoryTypes.PHILOSOPHY]: {
        slug: CategoryTypes.PHILOSOPHY,
        label: 'Philosophy',
        icon: "cat-mistery",
    },
    [CategoryTypes.POLITICS]: {
        slug: CategoryTypes.POLITICS,
        label: 'Politics',
        icon: "cat-mistery",
    },
    [CategoryTypes.PSYCHOLOGY]: {
        slug: CategoryTypes.PSYCHOLOGY,
        label: 'Psychology',
        icon: "cat-mistery",
    },
    [CategoryTypes.RELIGION]: {
        slug: CategoryTypes.RELIGION,
        label: 'Religion',
        icon: "cat-mistery",
    },
    [CategoryTypes.SCIENCE]: {
        slug: CategoryTypes.SCIENCE,
        label: 'Science',
        icon: "cat-mistery",
    },
    [CategoryTypes.SELF_HELP]: {
        slug: CategoryTypes.SELF_HELP,
        label: 'Slef Help',
        icon: "cat-mistery",
    },
    [CategoryTypes.SPORTS]: {
        slug: CategoryTypes.SPORTS,
        label: 'Sports',
        icon: "cat-mistery",
    },
    [CategoryTypes.TECHNOLOGY]: {
        slug: CategoryTypes.TECHNOLOGY,
        label: 'Technology & IT',
        icon: "cat-mistery",
    },
    [CategoryTypes.TRAVEL]: {
        slug: CategoryTypes.TRAVEL,
        label: 'Travel',
        icon: "cat-mistery",
    },
    [CategoryTypes.MYSTERY]: {
        slug: CategoryTypes.MYSTERY,
        label: "Mystery",
        icon: 'cat-mistery',
    },
    [CategoryTypes.ROMANCE]: {
        slug: CategoryTypes.ROMANCE,
        label: "Romance",
        icon: 'cat-romance',
    },
    [CategoryTypes.NOVEL]: {
        slug: CategoryTypes.NOVEL,
        label: "Novel",
        icon: 'cat-mistery',
    },
    [CategoryTypes.FICTION]: {
        slug: CategoryTypes.FICTION,
        label: "Fiction",
        icon: 'cat-non-fiction',
    },
    [CategoryTypes.PLAY]: {
        slug: CategoryTypes.PLAY,
        label: "Poetry",
        icon: 'cat-non-fiction',
    },
    [CategoryTypes.POETRY]: {
        slug: CategoryTypes.POETRY,
        label: "Play",
        icon: 'cat-non-fiction',
    },
    [CategoryTypes.SCI_FI]: {
        slug: CategoryTypes.SCI_FI,
        label: "Science Fiction",
        icon: 'cat-sci-fi',
    },
    [CategoryTypes.SHORT_STORIES]: {
        slug: CategoryTypes.SHORT_STORIES,
        label: "Short Stories",
        icon: 'cat-short-stories',
    },
    [CategoryTypes.NON_FICTION]: {
        slug: CategoryTypes.NON_FICTION,
        label: "Non Fiction",
        icon: 'cat-non-fiction',
    },
    [CategoryTypes.THRILLER]: {
        slug: CategoryTypes.THRILLER,
        label: "Thriller",
        icon: 'cat-thriller',
    },
}

/**
 * @enum {string}
 */
export const SupportedTokens = {
    USDT: 'USDT',
    SCRPT: 'SCRPT',
}

export const CountriesData = Object.keys(countries).sort((a, b) => {
    if (["US", "GB"].includes(a)) {
        return -1;
    }

    return 1;
}).map(countryKey => {
    return {
        ...countries[countryKey],
        countryKey,
    };
});

export const LanguagesData = Object.keys(languages).sort((a, b) => {
    if (a === "en") {
        return -1;
    }

    return 1;
}).map(languageKey => {
    return {
        ...languages[languageKey],
        languageKey,
    };
});

export const MAX_ALLOWANCE_THRESHOLD = ethers.BigNumber.from('115700000000000000000000000000000000000000000000000000000000000000000000000000');

export const APP_ENV = process.env.REACT_APP_ENV;

export const AUTH0_URL = process.env.REACT_APP_AUTH0_URL;
export const AUTH0_REDIRECT_BASE = process.env.REACT_APP_AUTH0_REDIRECT_BASE;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;

export const API_BASE = process.env.REACT_APP_API_BASE;
export const FORTMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY;
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
export const ETHEREUM_NETWORK = process.env.REACT_APP_ETHEREUM_NETWORK;
