import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Icon.scss';

const Icon = ({icon, className, ...props}) => {
    if (!icon) return null;

    const iconClass = `ScrptIcon-${icon.toLowerCase()}`;

    if (icon.startsWith('highlight-')) {
        return <span className={classNames('ScrptIcon', iconClass, className)} {...props}>
            <span className="path1"/>
            <span className="path2"/>
            <span className="path3"/>
            <span className="path4"/>
            <span className="path5"/>
            <span className="path6"/>
        </span>
    }

    return (
        <i className={classNames('ScrptIcon', iconClass, className)} {...props}/>
    )
};

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default Icon;
