export function isScriptBalanceLoaded(state) {
    return state.account.loadedScriptBalance;
}

export function getScriptBalance(state) {
    return state.account.scriptBalance;
}

export function arePaymentMethodsLoaded(state) {
    return state.account.paymentMethodsLoaded;
}

export function getPaymentMethods(state) {
    return Object.values(state.account.paymentMethods);
}
