import axios from "axios";

import {API_BASE} from "../Common/constants";

const Api = axios.create({
    baseURL: `${API_BASE}`,
});

class ApiProviderClass {
    Api = Api;

    /**
     * @param {string} key
     * @param {string|null} value
     */
    setHeader(key, value) {
        this.Api.defaults.headers.common[key] = value;
    }

    setAuthorizationHeader(jwt) {
        this.setHeader('Authorization', `Bearer ${jwt}`);
    }
}

export const ApiProvider = new ApiProviderClass();

export default Api;
