class NavigatorUtil {
    static exists() {
        return !!window.navigator;
    }

    static canShare() {
        return NavigatorUtil.exists() && !!window.navigator.share;
    }

    /**
     * @param title
     * @param text
     */
    static async shareData(title, text) {
        if (!NavigatorUtil.exists()) return;

        await window.navigator.share({
            title: title,
            text: text,
        });
    }
}

export default NavigatorUtil;
