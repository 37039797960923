import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from "../../Elements/Dialog/Dialog";
import WalletProviderSelect from "../WalletProviderSelect/WalletProviderSelect";
import FortmaticService from "../../Services/FortmaticService";
import {SupportedTokens} from "../../Common/constants";
import {getFormattedScrptPrice} from "../../Utils/Ethereum";

class AllowanceModal extends Component {
    ModalSteps = {
        PROVIDER: 'provider',
        ALLOWANCE: 'allowance',
    };

    state = {
        provider: null,
        step: this.ModalSteps.PROVIDER,
        actionInProgress: false,
    };

    fetchUserAllowance() {

    }

    handleProviderSelect = async (provider) => {
        let wallet;

        this.setState({
            actionInProgress: true,
        });

        switch (provider) {
            case 'fortmatic':
                wallet = await FortmaticService.getUserAccount();
                break;
            default:
                break;
        }

        if (wallet) {
            const allowance = await FortmaticService.getUserAllowance(SupportedTokens.USDT, true);

            this.setState({
                step: this.ModalSteps.ALLOWANCE,
                wallet,
                provider,
                allowance,
            });
        }

        this.setState({
            actionInProgress: false,
        });
    }

    handleModalClose = () => {
        this.setState({
            provider: null,
            step: this.ModalSteps.PROVIDER,
        });
    }

    render() {
        const {open, onClose} = this.props;
        const {step, actionInProgress, allowance} = this.state;

        let maxAllowance;

        if (allowance) {
            maxAllowance = FortmaticService.isAllowanceMaxed(SupportedTokens.USDT, allowance);
        }

        return (
            <Dialog open={open} onClose={onClose} onAfterClose={this.handleModalClose}>
                {actionInProgress && <div className="DialogContent__Progress"/>}
                {step === this.ModalSteps.PROVIDER && <div>
                    <WalletProviderSelect onSelect={this.handleProviderSelect}/>
                </div>}
                {step === this.ModalSteps.ALLOWANCE && <div>
                    {maxAllowance && <div>Allowance is set to max</div>}
                    {!maxAllowance && <div>
                        Allowance: {getFormattedScrptPrice(allowance, SupportedTokens.USDT)}
                    </div>}
                </div>}
            </Dialog>
        );
    }
}

AllowanceModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default AllowanceModal;
