import React, {useState} from 'react';
import Button from "../../Elements/Button/Button";
import {getFormattedScrptPrice} from "../../Utils/Ethereum";
import {SupportedTokens} from "../../Common/constants";
import Icon from "../../Elements/Icon/Icon";
import Blockies from "react-blockies";
import ButtonGroup from "../../Elements/ButtonGroup/ButtonGroup";

import './WithdrawTokensModalConfirmStep.scss'

const WithdrawTokensModalConfirmStep = ({amount, address, onBack, onConfirm}) => {
    const [scrptAmount, toggleScrptAmount] = useState(false);
    const [swapAmount, toggleSwapAmount] = useState(false);
    const [addressCorrect, toggleAddress] = useState(false);

    const isValidForm = scrptAmount && swapAmount && addressCorrect;

    return (
        <div className="WithdrawTokensModalConfirmStep">
            <h2 className="WithdrawTokensModalConfirmStep__Heading">Final Confirmation</h2>
            <div className="WithdrawTokensModalConfirmStep__Description">Check all the information twice and confirm the withdrawal.</div>
            <div className="WithdrawTokensModalConfirmStep__Option" onClick={() => toggleScrptAmount(!scrptAmount)}>
                <div className="WithdrawTokensModalConfirmStep__Option__Check__IconWrapper">
                    <Icon icon="swap" className="WithdrawTokensModalConfirmStep__Option__Check__IconWrapper__Icon"/>
                </div>
                <div className="WithdrawTokensModalConfirmStep__Option__Info">
                    <div className="WithdrawTokensModalConfirmStep__Option__Info__Amount">{getFormattedScrptPrice(amount, SupportedTokens.SCRPT)} SCRPT</div>
                    <div className="WithdrawTokensModalConfirmStep__Option__Info__Desc">will swap into...</div>
                </div>
                <div className={`WithdrawTokensModalConfirmStep__Option__Check ${scrptAmount && 'WithdrawTokensModalConfirmStep__Option__Check--Active'}`}>
                    {scrptAmount && <Icon icon="check" className="WithdrawTokensModalConfirmStep__Option__Check__IconWrapper__Icon"/>}
                </div>
            </div>
            <div className="WithdrawTokensModalConfirmStep__Option" onClick={() => toggleSwapAmount(!swapAmount)}>
                <div className="WithdrawTokensModalConfirmStep__Option__Check__IconWrapper">
                    <Icon icon="eth"/>
                </div>
                <div className="WithdrawTokensModalConfirmStep__Option__Info">
                    <div className="WithdrawTokensModalConfirmStep__Option__Info__Amount">{getFormattedScrptPrice(amount, SupportedTokens.USDT)} USDT</div>
                    <div className="WithdrawTokensModalConfirmStep__Option__Info__Desc">which will be sent to...</div>
                </div>
                <div className={`WithdrawTokensModalConfirmStep__Option__Check ${swapAmount && 'WithdrawTokensModalConfirmStep__Option__Check--Active'}`}>
                    {swapAmount && <Icon icon="check"/>}
                </div>
            </div>
            <div className="WithdrawTokensModalConfirmStep__Option" onClick={() => toggleAddress(!addressCorrect)}>
                <div className="WithdrawTokensModalConfirmStep__Option__Check__IconWrapper">
                    <Blockies size={8} scale={3} seed={address} className="WithdrawTokensModalConfirmStep__Option__Check__IconWrapper__Blockie"/>
                </div>
                <div className="WithdrawTokensModalConfirmStep__Option__Info">
                    <span className="WithdrawTokensModalConfirmStep__Option__Info__Address">{address}</span>
                </div>
                <div className={`WithdrawTokensModalConfirmStep__Option__Check ${addressCorrect && 'WithdrawTokensModalConfirmStep__Option__Check--Active'}`}>
                    {addressCorrect && <Icon icon="check"/>}
                </div>
            </div>
            <div>
                <ButtonGroup>
                    <Button onClick={onBack} size="large" color="white">
                        <Icon icon="chevron-left"/>
                    </Button>
                    <Button stretch disabled={!isValidForm} size="large" color="turquoise" onClick={onConfirm}>
                        {!isValidForm && <span className="UppercaseText">Tick all the items</span>}
                        {isValidForm && <span className="UppercaseText">Confirm</span>}
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    );
};

WithdrawTokensModalConfirmStep.propTypes = {

};

export default WithdrawTokensModalConfirmStep;
