import React from 'react';
import Button from "../../Elements/Button/Button";
import Icon from "../../Elements/Icon/Icon";
import {Link} from "react-router-dom";
import Container from "../../Elements/Container/Container";

import './PublishedBooks.scss';
import BookCover from "../BookCover/BookCover";

const PublishedBooks = ({books, onNewBook}) => {
    return (
        <div className="PublishedBooks">
            <Container>
                <div className="PublishedBooks__Container">
                    <div className="PublishedBooks__Header">
                        <h2>Your Books</h2>
                        <Button color="turquoise" onClick={onNewBook}>
                            <Icon icon="plus"/>
                            <span>Create new Project</span>
                        </Button>
                    </div>
                    <div className="PublishedBooks__Tabs">
                        <div className="PublishedBooks__Tab PublishedBooks__Tab--Active">
                            <span>Published ({books.length})</span>
                        </div>
                    </div>
                    <div className="PublishedBooks__List">
                        {books.map(book => <Link key={book.id} to={`/book/${book.id}`} className="PublishedBooks__List__Item">
                            <BookCover book={book} className="PublishedBooks__List__Item__BookCover"/>
                            <h3>{book.title}</h3>
                            <div>{book.author}</div>
                        </Link>)}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default PublishedBooks;
