import React, {useEffect, useState} from 'react';
import Button from "../../Elements/Button/Button";
import {getFormattedScrptPrice, isValidAddress} from "../../Utils/Ethereum";
import {SupportedTokens} from "../../Common/constants";
import Icon from "../../Elements/Icon/Icon";
import ButtonGroup from "../../Elements/ButtonGroup/ButtonGroup";

import './WithdrawTokensModalAddressStep.scss';

const WithdrawTokensModalAddressStep = ({initialAddress, recentAddresses, amount, onBack, onConfirmAddress}) => {
    const [address, setAddress] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        if (initialAddress) {
            setAddress(initialAddress);
        }
    }, [initialAddress]);

    function handleAddressChange(value) {
        if (!isValidAddress(value)) {
            setError("Invalid wallet address");
        } else {
            setError(null);
        }

        setAddress(value);
    }

    return (
        <div className="WithdrawTokensModalAddressStep">
            <div className="WithdrawTokensModalAddressStep__Description">Withdraw <span className="SemiBoldText">{getFormattedScrptPrice(amount, SupportedTokens.USDT)} USDT</span> to</div>
            <div className="WithdrawTokensModalAddressStep__Input">
                <input type="text" value={address} placeholder="0x..." className="WithdrawTokensModalAddressStep__Input__Control" onChange={event => handleAddressChange(event.target.value)}/>
            </div>
            <div className="WithdrawTokensModalAddressStep__InputError">{error ?? ''}</div>
            {recentAddresses?.length && <div>
                <div>Previously withdrawn to:</div>
            </div>}
            <div className="WithdrawTokensModalAddressStep__Actions">
                <ButtonGroup>
                    <Button onClick={onBack} size="large" color="white">
                        <Icon icon="chevron-left"/>
                    </Button>
                    <Button stretch size="large" color="turquoise" disabled={!!error || !address} onClick={() => onConfirmAddress(address)}>
                        {!!address && <span className="UppercaseText">Confirm address</span>}
                        {!address && <span className="UppercaseText">Enter the address</span>}
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    );
};

WithdrawTokensModalAddressStep.propTypes = {

};

export default WithdrawTokensModalAddressStep;
