import {combineReducers} from "redux";

import AppReducer from "./App/App.reducer";
import AuthReducer from "./Auth/Auth.reducer";
import BookReducer from "./Book/Book.reducer";
import AccountReducer from "./Account/Account.reducer";
import WriteReducer from "./Write/Write.reducer";

const reducers = combineReducers({
    app: AppReducer,
    auth: AuthReducer,
    book: BookReducer,
    account: AccountReducer,
    write: WriteReducer,
});

export default reducers;
