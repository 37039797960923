import React from 'react';
import {Link, NavLink} from "react-router-dom";

import './DesktopHeader.scss';
import Container from "../../Elements/Container/Container";

const DesktopHeader = () => {
    return (
        <div className="DesktopHeader">
            <Container>
                <div className="DesktopHeader__Content">
                    <Link to="/" className="DesktopHeader__Logo">Scriptarnica</Link>
                    <div className="DesktopHeader__Navigation">
                        <NavLink to="/home" strict className="DesktopHeader__NavLink" activeClassName="DesktopHeader__NavLink--Active">
                            <span>Home</span>
                        </NavLink>
                        <NavLink to="/write" strict className="DesktopHeader__NavLink" activeClassName="DesktopHeader__NavLink--Active">
                            <span>Write</span>
                        </NavLink>
                        <NavLink to="/search" strict className="DesktopHeader__NavLink" activeClassName="DesktopHeader__NavLink--Active">
                            <span>Search</span>
                        </NavLink>
                        <NavLink to="/profile" strict className="DesktopHeader__NavLink" activeClassName="DesktopHeader__NavLink--Active">
                            <span>Menu</span>
                        </NavLink>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default DesktopHeader;
