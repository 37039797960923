import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Swiper, SwiperSlide} from "swiper/react";
import classnames from 'classnames';

import {IntroductionSlideFirst, IntroductionSlideSecond, IntroductionSlideThird} from "./IntroductionSlide";

import './IntroductionSlider.scss';
import Icon from "../../Elements/Icon/Icon";

class IntroductionSlider extends Component {
    state = {
        currentSlide: 0,
    }

    /**
     * @param {Swiper} slide
     */
    handleSlideChange = (slide) => {
        this.setState({
            currentSlide: slide.realIndex,
        });
    };

    render() {
        const {onSkip} = this.props;
        const {currentSlide} = this.state;

        return (
            <div className={classnames("IntroductionSlider", `IntroductionSlider__Slide--${currentSlide}`)}>
                <Swiper slidesPerView={1} touchReleaseOnEdges={true}
                        onSlideChange={this.handleSlideChange}>
                    <SwiperSlide>
                        <IntroductionSlideFirst/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <IntroductionSlideSecond/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <IntroductionSlideThird/>
                    </SwiperSlide>
                </Swiper>
                <div className="IntroductionSlider__SliderInfo">
                    <div className="IntroductionSlider__SliderInfo__Dots">
                        <div className={classnames(
                            "IntroductionSlider__SliderInfo__Dot",
                            {"IntroductionSlider__SliderInfo__Dot--Active": currentSlide === 0}
                        )}/>
                        <div className={classnames(
                            "IntroductionSlider__SliderInfo__Dot",
                            {"IntroductionSlider__SliderInfo__Dot--Active": currentSlide === 1}
                        )}/>
                        <div className={classnames(
                            "IntroductionSlider__SliderInfo__Dot",
                            {"IntroductionSlider__SliderInfo__Dot--Active": currentSlide === 2}
                        )}/>
                    </div>
                    <div className="IntroductionSlider__SliderInfo__Actions">
                        {currentSlide !== 2 && <div className="IntroductionSlider__SliderInfo__Actions__Skip" onClick={onSkip}>Skip</div>}
                        {currentSlide === 2 && <div className="IntroductionSlider__SliderInfo__Actions__SkipButton" onClick={onSkip}>
                            <Icon icon="arrow-right"/>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}

IntroductionSlider.propTypes = {
    onSkip: PropTypes.func.isRequired,
}

export default IntroductionSlider;
