import React, {Component} from 'react';
import {Link} from "react-router-dom";

import Icon from "../../Elements/Icon/Icon";
import './SearchButton.scss';

class SearchButton extends Component {
    render() {
        return (
            <Link className="SearchButton" to="/search">
                <Icon icon="search" className="SearchButton__Icon"/>
                <span className="SearchButton__Label">What would you like to read?</span>
            </Link>
        );
    }
}

export default SearchButton;
