import Auth0 from "auth0-js";

import {AUTH0_CLIENT_ID, AUTH0_REDIRECT_BASE, AUTH0_URL} from "../Common/constants";

const auth0 = new Auth0.WebAuth({
    domain: AUTH0_URL,
    clientID: AUTH0_CLIENT_ID,
    responseType: "code token id_token",
    redirectUri: `${AUTH0_REDIRECT_BASE}/auth0-login`,
    scope: "openid email profile",
});

class Auth0Service {
    static passwordLessLogin(email) {
        auth0.passwordlessStart({
            send: "code",
            email,
            connection: "email",
        }, cb => {
            console.log(cb);
        });
    }

    static loginWithOtpCode(email, code) {
        auth0.passwordlessLogin({
            connection: 'email',
            email: email,
            verificationCode: code
        }, cb => {
            console.log(cb);
        });
    }

    static loginWithSocial(social) {
        auth0.authorize({
            redirectUri: `${AUTH0_REDIRECT_BASE}/oauth`,
            connection: social
        });
    }

    static parseHash(hash, onSuccess, onError) {
        auth0.parseHash({
            hash,
        }, (error, authResult) => {
            if (error) {
                onError(error)
                return;
            }

            auth0.client.userInfo(authResult.accessToken, (err, user) => {
                onSuccess({
                    authResult,
                    user,
                });
            });
        });
    }

    static checkSession(onSuccess, onError) {
        auth0.checkSession({}, function (err, authResult) {
            if (err) {
                onError(err)
                return;
            }

            onSuccess(authResult);
        });
    }

    static logout() {
        auth0.logout({
            returnTo: `${AUTH0_REDIRECT_BASE}/login`,
            clientID: AUTH0_CLIENT_ID,
        });
    }
}

export default Auth0Service;
