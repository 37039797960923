class User {
    constructor(data) {
        /** @type {string} */
        this.id = data.id;

        /** @type {string} */
        this.email = data.email;

        /** @type {string} */
        this.nickname = data.nickname;

        /** @type {string} */
        this.scriptarnicaWallet = data.scriptarnicaWallet;
    }

    static buildFromResponse(response) {
        return new User({
            id: response.id,
            email: response.email,
            nickname: response.nickname,
            scriptarnicaWallet: response.wallet,
        });
    }
}

export default User;
