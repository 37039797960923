import React from 'react';
import ReactMarkdown from "react-markdown";

import './BookDescription.scss';

const BookDescription = ({book}) => {
    if (!book) return null;

    return (
        <div className="BookDescription" lang="en">
            <ReactMarkdown source={book.description}/>
        </div>
    );
};

export default BookDescription;
