import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from "react-router-dom";

import Book from "../../Core/Book/Book.model";

import Dialog from "../../Elements/Dialog/Dialog";
import {bindActionCreators} from "redux";
import {accountActions} from "../../Core/actions";
import {getScriptBalance, isScriptBalanceLoaded} from "../../Selectors/AccountSelectors";
import {getFormattedScrptPrice} from "../../Utils/Ethereum";
import Button from "../../Elements/Button/Button";

import './BuyBookModal.scss';
import {SupportedTokens} from "../../Common/constants";

class BuyBookModal extends PureComponent {
    state = {
        inProgress: true,
        bookPurchased: false,
    };

    async componentDidMount() {
        const {accountActions} = this.props;

        await accountActions.fetchUserScriptBalance();

        this.setState({
            inProgress: false,
        });
    }

    handleBuyBook = async () => {
        const {accountActions, book} = this.props;

        this.setState({
            inProgress: true,
        });

        const response = await accountActions.buyBook(book);

        this.setState({
            inProgress: false,
            bookPurchased: response,
        });
    }

    handleAfterModalClose = () => {
        this.setState({
            inProgress: false,
            bookPurchased: false,
        });
    }

    render() {
        const {scriptBalanceLoaded, scriptBalance, book, open, onClose} = this.props;
        const {inProgress, bookPurchased} = this.state;

        if (bookPurchased) {
            return <Redirect to={`/read/${book.id}`}/>;
        }

        return (
            <Dialog className="BuyBookModal" open={open} onClose={onClose}>
                {inProgress && <div className="BuyBookModal__ProgressOverlay"/>}
                <h2 className="BuyBookModal__Heading">Buy {book.title}</h2>
                <div className="BuyBookModal__Description">Double check the information and confirm your purchase.</div>
                <div className="BuyBookModal__Divider"/>
                {scriptBalanceLoaded && <div className="BuyBookModal__PriceInfo">
                    <div className="BuyBookModal__PriceInfo__Label">Book price</div>
                    <div className="BuyBookModal__PriceInfo__Balance">{getFormattedScrptPrice(book.price, SupportedTokens.SCRPT)} SCRPT</div>
                    <div className="BuyBookModal__PriceInfo__Description">You will have {getFormattedScrptPrice(scriptBalance.sub(book.price), SupportedTokens.SCRPT)} SCRPT left</div>
                    <Button stretch color="turquoise" size="large" onClick={this.handleBuyBook}>
                        <span className="BoldText UppercaseText">Confirm Purchase</span>
                    </Button>
                </div>}
            </Dialog>
        );
    }
}

BuyBookModal.propTypes = {
    book: PropTypes.instanceOf(Book).isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        scriptBalance: getScriptBalance(state),
        scriptBalanceLoaded: isScriptBalanceLoaded(state),
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        accountActions: bindActionCreators(accountActions, dispatch),
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(BuyBookModal));
