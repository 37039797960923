import React, {Component} from 'react';
import {connect} from 'react-redux';

import AppLayout from "../../Elements/AppLayout/AppLayout";
import MainFooter from "../../Components/MainFooter/MainFooter";
import HomePageHeader from "../../Components/HomePageHeader/HomePageHeader";
import ContinueReading from "../../Components/ContinueReading/ContinueReading";
import HomeNavigation from "../../Components/HomeNavigation/HomeNavigation";

class HomePage extends Component {
    render() {
        return (
            <AppLayout pageId="HomePage" renderFooter={() => <MainFooter/>} renderHeader={() => <HomePageHeader/>}>
                <ContinueReading/>
                <HomeNavigation/>
            </AppLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HomePage);
