import React, {Component, createRef} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import _ from 'lodash';

import AppLayout from "../../Elements/AppLayout/AppLayout";
import BookContent from "../../Components/BookContent/BookContent";
import {getBook, isBookLoaded} from "../../Selectors/BookSelectors";
import {bookActions} from "../../Core/actions";
import ReadingFooter from "../../Components/ReadingFooter/ReadingFooter";
import PageLoader from "../../Components/PageLoader/PageLoader";
import ReadingSettings from "../../Components/ReadingSettings/ReadingSettings";
import ReadingNavigation from "../../Components/ReadingNavigation/ReadingNavigation";
import AppSettingsService from "../../Services/AppSettingsService";

import './ReadBookPages.scss';
import ReadingMenu from "../../Components/ReadingMenu/ReadingMenu";

class ReadBookPage extends Component {
    state = {
        bookContentLoaded: false,
        bookContent: null,
        contextOpen: false,
        bookProgress: 0,
        menuOpen: false,
        appSettings: {},
    };

    contentRef = createRef();

    async componentDidMount() {
        const {bookActions, book, bookLoaded, bookId} = this.props;

        let _book = book;

        if (!bookLoaded) {
            _book = await bookActions.fetchBook(bookId);
        }

        const content = await bookActions.fetchBookContent(_book);

        const appSettings = AppSettingsService.getAppSettings();

        this.setState({
            bookContentLoaded: true,
            bookContent: content,
            appSettings,
        });
    }

    handleContentClick = () => {
        const {contextOpen} = this.state;

        this.setState({
            contextOpen: !contextOpen,
        });
    };

    handleScroll = _.debounce((ref) => {
        const newProgress = ref.current.scrollTop / this.contentRef.current.clientHeight * 100;

        this.setState({
            bookProgress: newProgress,
        });
    }, 100);

    /**
     * @param {'change_theme'|'change_font_family'|'change_spacing'|'change_font_size'} action
     * @param {AppSpacingTypes} [value]
     */
    handleAction = (action, value) => {
        const {appSettings} = this.state;

        switch (action) {
            case 'change_theme':
                const allThemes = AppSettingsService.getThemeOptions();

                const currentThemeIndex = allThemes.indexOf(appSettings.theme);

                const updatedThemeSettings = AppSettingsService.setThemeSetting(allThemes[currentThemeIndex + 1] ?? allThemes[0]);

                this.setState({
                    appSettings: updatedThemeSettings,
                });
                break;
            case 'change_font_family':
                const allFontFamilies = AppSettingsService.getFontFamilyOptions();

                const currentFamilyIndex = allFontFamilies.indexOf(appSettings.fontFamily);

                const updatedFontFamilySettings = AppSettingsService.setFontFamilySetting(allFontFamilies[currentFamilyIndex + 1] ?? allFontFamilies[0]);

                this.setState({
                    appSettings: updatedFontFamilySettings,
                });
                break;
            case 'change_spacing':
                const updateSpacingSettings = AppSettingsService.setSpacingSetting(value);

                this.setState({
                    appSettings: updateSpacingSettings,
                });
                break;
            case 'change_font_size':
                const allFontSizes = AppSettingsService.getFontSizeOptions();

                const currentFontSizeIndex = allFontSizes.indexOf(appSettings.fontSize) ?? 0;

                const nextFontSizeIndex = _.clamp(currentFontSizeIndex + value, 0, allFontSizes.length - 1);

                const updateFontSizeSettings = AppSettingsService.setFontSizeSetting(allFontSizes[nextFontSizeIndex]);

                this.setState({
                    appSettings: updateFontSizeSettings,
                });
                break;
            default:
                break;
        }
    }

    getClassesForSettings = (appSettings) => {
        let classes = ['ReadBookPage'];

        classes.push(`ReadBookPage--Theme__${appSettings.theme}`);
        classes.push(`ReadBookPage--Spacing__${appSettings.spacing}`);
        classes.push(`ReadBookPage--FontSize__${appSettings.fontSize}`);
        classes.push(`ReadBookPage--FontFamily__${appSettings.fontFamily}`);

        return classes.join(' ');
    };

    handleMenuOpen = () => {
        this.setState({
            menuOpen: true,
        });
    }

    handleMenuClose = () => {
        this.setState({
            menuOpen: false,
        });
    }

    handleGoTo = (name) => {
        const element = document.getElementById(name);

        if (element) {
            element.scrollIntoView();
        }

        this.handleMenuClose();
    }

    render() {
        const {book, bookLoaded} = this.props;
        const {bookContent, bookContentLoaded, contextOpen, bookProgress, appSettings, menuOpen} = this.state;

        if (!bookLoaded || !bookContentLoaded) {
            return <AppLayout pageId="BookPage">
                <PageLoader text="Fetching Book..."/>
            </AppLayout>;
        }

        return (
            <AppLayout pageId="ReadBookPage" className={this.getClassesForSettings(appSettings)} renderFooter={() => <ReadingFooter progress={bookProgress}/>} onContentScroll={this.handleScroll}>
                <ReadingNavigation visible={contextOpen} book={book} onMenuOpen={this.handleMenuOpen}/>
                <BookContent outerRef={this.contentRef}
                             content={bookContent}
                             onContentClick={this.handleContentClick}/>
                <ReadingSettings visible={contextOpen} appSettings={appSettings} onAction={this.handleAction}/>
                <ReadingMenu open={menuOpen} content={bookContent} handleGoTo={this.handleGoTo} onClose={this.handleMenuClose}/>
            </AppLayout>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const {match: {params: {bookId}}} = ownProps;

    return {
        bookId,
        book: getBook(state, bookId),
        bookLoaded: isBookLoaded(state, bookId),
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        bookActions: bindActionCreators(bookActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReadBookPage);
