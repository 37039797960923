import {BigNumber} from "ethers";

import Api from "../Utils/ApiProvider";

class SidechainService {
    /**
     * @param {string} address
     */
    static async getUserScriptBalance(address) {
        const {data} = await Api.get(`/api/v1/user/balance`)

        if (!data?.value) {
            return BigNumber.from(0);
        }

        return BigNumber.from(data.value);
    }
}


export default SidechainService;
