import React, {Component} from 'react';
import {connect} from "react-redux";
import {Redirect, Route, withRouter} from "react-router-dom";

import {isIntroductionFinished} from "../../Selectors/AppSelectors";
import {isLoggedIn} from "../../Selectors/AuthSelectors";

class PrivateRoute extends Component {
    render() {
        const {component: Component, introductionFinished, isLoggedIn, ...props} = this.props;

        return (
            <Route {...props} render={renderProps =>
                introductionFinished ? (
                    isLoggedIn ? (
                        <Component {...renderProps} />
                    ) : (
                        <Redirect to={{
                            pathname: "/login",
                            state: {from: renderProps.location},
                        }}/>
                    )
                ) : (
                    <Redirect to={{
                        pathname: "/intro",
                        state: {from: renderProps.location},
                    }}/>
                )
            }/>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        introductionFinished: isIntroductionFinished(state),
        isLoggedIn: isLoggedIn(state),
    };
};

export default withRouter(connect(
    mapStateToProps,
)(PrivateRoute));
