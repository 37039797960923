import React, {Component} from 'react';
import {connect} from 'react-redux';
import AppLayout from "../../Elements/AppLayout/AppLayout";
import MainFooter from "../../Components/MainFooter/MainFooter";
import ProfileHeader from "../../Components/ProfileHeader/ProfileHeader";

class ProfileBillingPage extends Component {
    render() {
        return (
            <AppLayout pageId="ProfileBillingPage"
                       renderHeader={() => <ProfileHeader title="App Settings"/>}
                       renderFooter={() => <MainFooter/>}>
                asd
            </AppLayout>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

export default connect(
    mapStateToProps,
)(ProfileBillingPage);
