import React from 'react';

import './HomePageHeader.scss';
import Container from "../../Elements/Container/Container";

const getDayTimeClass = () => {
    const date = new Date();
    const hour = date.getHours();

    if (hour >= 4 && hour < 12) {
        return "HomePageHeader--Morning";
    }

    if (hour >= 12 && hour < 20) {
        return "HomePageHeader--Afternoon";
    }

    return "HomePageHeader--Evening";
};

const getDayTimeHeading = () => {
    const date = new Date();
    const hour = date.getHours();

    if (hour >= 4 && hour < 12) {
        return "Good morning,";
    }

    if (hour >= 12 && hour < 20) {
        return "Good afternoon,";
    }

    return "Good evening,";
};

const getDayTimeMessage = () => {
    const date = new Date();
    const hour = date.getHours();

    if (hour >= 4 && hour < 12) {
        return "the best day starts with a book!";
    }

    if (hour >= 12 && hour < 20) {
        return "nothing like an afternoon spent reading a great book!";
    }

    return "the best day ends with a book!";
};

const HomePageHeader = () => {
    return <div className={`HomePageHeader ${getDayTimeClass()}`}>
        <Container>
            <h2 className="HomePageHeader__Heading">{getDayTimeHeading()}</h2>
            <p>{getDayTimeMessage()}</p>
        </Container>
    </div>;
};

export default HomePageHeader;
