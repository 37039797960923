import React from 'react';
import _ from 'lodash';

import FallbackCover1 from './1@3x.png';
import FallbackCover2 from './2@3x.png';
import FallbackCover3 from './3@3x.png';
import FallbackCover4 from './4@3x.png';
import FallbackCover5 from './5@3x.png';
import Image from "../../Elements/Image/Image";

const images = [FallbackCover1, FallbackCover2, FallbackCover3, FallbackCover4, FallbackCover5];

/**
 * @param className
 * @param {Book} book
 * @return {JSX.Element}
 * @constructor
 */
const BookCover = ({className, book}) => {
    return (
        <Image className={`BookCover ${className}`} src={book.coverUrl} fallbackSrc={images[_.random(0, images.length - 1)]}/>
    );
};

export default BookCover;
