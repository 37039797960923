import React, {Component} from 'react';
import {connect} from 'react-redux';

import AppLayout from "../../Elements/AppLayout/AppLayout";
import MainFooter from "../../Components/MainFooter/MainFooter";
import LibraryHeader from "../../Components/LibraryHeader/LibraryHeader";
import MyLibrary from "../../Components/MyLibrary/MyLibrary";
import {areOwnedBooksLoaded, getUserOwnedBooks} from "../../Selectors/BookSelectors";
import {bindActionCreators} from "redux";
import {bookActions} from "../../Core/actions";
import PageLoader from "../../Components/PageLoader/PageLoader";

class LibraryPage extends Component {
    componentDidMount() {
        const {bookActions, booksLoaded} = this.props;

        if (!booksLoaded) {
            bookActions.fetchUserLibrary();
        }
    }

    render() {
        const {books, booksLoaded} = this.props;

        return (
            <AppLayout pageId="LibraryPage"
                       stickyHeader
                       renderHeader={() => <LibraryHeader/>}
                       renderFooter={() => <MainFooter/>}>
                {!booksLoaded && <PageLoader text="Loading your library..."/>}
                {booksLoaded && <MyLibrary books={books}/>}
            </AppLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        books: getUserOwnedBooks(state),
        booksLoaded: areOwnedBooksLoaded(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        bookActions: bindActionCreators(bookActions, dispatch),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LibraryPage);
