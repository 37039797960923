import React, {Component} from 'react';

import './ReadingMenuContent.scss';

class ReadingMenuContent extends Component {
    handlePartClick = (partIndex) => {
        const {handleGoTo} = this.props;

        handleGoTo(`part_${partIndex}`);
    }

    handleChapterClick = (partIndex, chapterIndex) => {
        const {handleGoTo} = this.props;

        handleGoTo(`chapter_${partIndex}_${chapterIndex}`);
    }
    render() {
        const {content} = this.props;

        return (
            <div className="ReadingMenuContent">
                <div className="ReadingMenuContent__Heading">Table of content</div>
                {content.map((part, partIndex) => <div key={part.name + partIndex} className="ReadingMenuContent__PartWrapper">
                    {!!part.name && <div className="ReadingMenuContent__Part" onClick={() => this.handlePartClick(partIndex)}>{part.name}</div>}
                    {part.chapters.map((chapter, chapterIndex) => <div key={chapter.name + chapterIndex} className="ReadingMenuContent__ChapterWrapper">
                        {!!chapter.name && <div className="ReadingMenuContent__Chapter" onClick={() => this.handleChapterClick(partIndex, chapterIndex)}>{chapter.name}</div>}
                    </div>)}
                </div>)}
            </div>
        );
    }
}

export default ReadingMenuContent;
