import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Link} from "react-router-dom";

import Icon from "../Icon/Icon";

import './Button.scss';

const buttonColorClassMap = {
    'white': "Button--White",
    'turquoise': "Button--Turquoise",
}

const buttonSizeClassMap = {
    'large': "Button--SizeLarge",
}

const Button = ({children, className, type, icon, to, disabled, stretch, color, size, iconPosition, onClick}) => {
    let ButtonTag = 'button';

    if (to) {
        ButtonTag = Link;
    }

    return (
        <ButtonTag className={classNames(
            "Button",
            buttonColorClassMap[color],
            buttonSizeClassMap[size],
            {
                "Button--Stretch": stretch,
                "Button--Disabled": disabled,
            },
            className,
        )} to={to} type={type} onClick={onClick}>
            {icon && iconPosition === 'left' && <div className="Button__Icon">
                <Icon icon={icon}/>
            </div>}
            <div className="Button__Content">
                {children}
            </div>
            {icon && iconPosition === 'right' && <div className="Button__Icon">
                <Icon icon={icon}/>
            </div>}
        </ButtonTag>
    );
};

Button.propTypes = {
    icon: PropTypes.string,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    color: PropTypes.oneOf(['white', 'turquoise']),
    stretch: PropTypes.bool,
};

Button.defaultProps = {
    type: "button",
    iconPosition: 'right',
}

export default Button;
