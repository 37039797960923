import React, {useEffect, useState} from 'react';
import Button from "../../Elements/Button/Button";
import Icon from "../../Elements/Icon/Icon";
import ButtonGroup from "../../Elements/ButtonGroup/ButtonGroup";

import './AddTokensStripeAmountStep.scss';

const AddTokensStripeAmountStep = ({onSetAmount, onBack, initialAmount}) => {
    const [amount, setAmount] = useState('');
    const [error, setError] = useState(null);

    function handleAmountChange(newAmount) {
        const parsedAmount = parseInt(newAmount);

        if (parsedAmount < 1) {
            setError("Minimum amount is 10 USD");
        } else {
            setError(null);
        }

        setAmount(newAmount);
    }

    useEffect(() => {
        if (initialAmount) {
            handleAmountChange(initialAmount);
        } else {
            handleAmountChange('1');
        }
        // We are disabling this because it gets to complicated to write hook compatible code.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className="AddTokensStripeAmountStep__Description">Select amount of USD</div>
            <div className="AddTokensStripeAmountStep__Input">
                <input className="AddTokensStripeAmountStep__Input__Control" autoFocus value={amount} onChange={event => handleAmountChange(event.target.value)} type="text" pattern="[0-9]+([,\.][0-9]+)?"  inputMode="numeric"/>
                <div className="AddTokensStripeAmountStep__Input__Token">USD</div>
            </div>
            {!!error && <div className="WithdrawTokensModalAmountStep__InputError">{error}</div>}
            <ButtonGroup>
                <Button onClick={onBack} size="large" color="white">
                    <Icon icon="chevron-left"/>
                </Button>
                <Button color="turquoise" size="large" onClick={() => onSetAmount(amount)} disabled={!!error || !amount} stretch>
                    <span className="BoldText">Go to Checkout</span>
                </Button>
            </ButtonGroup>
        </div>
    );
};


export default AddTokensStripeAmountStep;
