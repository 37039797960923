import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import _ from 'lodash';

import AppLayout from "../../Elements/AppLayout/AppLayout";
import SearchHeader from "../../Components/SearchHeader/SearchHeader";
import {bookActions} from "../../Core/actions";
import SearchingEmptyState from "../../Components/SearchingEmptyState/SearchingEmptyState";

import './SearchPage.scss';
import SearchResults from "../../Components/SearchResults/SearchResults";
import MainFooter from "../../Components/MainFooter/MainFooter";
import AdvancedFiltersModal from "../../Components/AdvancedFiltersModal/AdvancedFiltersModal";

class SearchPage extends Component {
    state = {
        query: '',
        filters: {},
        searching: false,
        recentSearches: [],
        advancedFiltersOpen: false,
    };

    componentDidMount() {
        const {bookActions, location: {search}} = this.props;

        const filters = {};

        const queryData = new URLSearchParams(search);

        const searchQuery = queryData.get('query') ?? '';
        const priceFromFilter = queryData.get('priceFrom');
        const priceToFilter = queryData.get('priceTo');
        const ratingFilter = queryData.get('rating');
        const categoriesFilter = queryData.getAll('categories');

        if (priceFromFilter && priceToFilter) {
            filters.price = [parseInt(priceFromFilter), parseInt(priceToFilter)];
        }

        if (ratingFilter) {
            filters.rating = parseInt(ratingFilter);
        }

        if (categoriesFilter && categoriesFilter?.length > 0) {
            filters.categories = categoriesFilter;
        }

        const recentSearches = bookActions.getRecentSearches();

        this.setState({
            recentSearches,
        });

        if (searchQuery || Object.keys(filters).length > 0) {
            this.setState({
                query: searchQuery,
                searching: true,
                filters,
            }, () => this.debouncedSearch(searchQuery, filters));
        }
    }

    /**
     * @param {string} query
     * @param {Object} filters
     * @param {number[]} filters.price
     * @param {CategoryTypes[]} filters.categories
     * @param {number} filters.rating
     */
    search = async (query, filters) => {
        const {bookActions, history} = this.props;

        this.setState({
            searching: true,
        });

        const queryData = new URLSearchParams();

        queryData.append('query', query);

        if (filters.price) {
            queryData.append('priceFrom', filters.price[0]);
            queryData.append('priceTo', filters.price[1]);
        }

        if (filters.categories && filters.categories?.length > 0) {
            filters.categories.forEach(category => {
                queryData.append('categories', category);
            });
        }

        if (filters.rating) {
            queryData.append('rating', filters.rating);
        }

        history.push(`?${queryData.toString()}`);

        const books = await bookActions.findBooksBySearch(query, filters);

        this.setState({
            searching: false,
            searchResults: books,
        });
    };

    debouncedSearch = _.debounce(this.search, 1000);

    handleQueryChange = (value) => {
        const {filters} = this.state;

        this.setState({
            query: value,
            searching: true,
        }, () => this.debouncedSearch(value, filters));
    };

    /**
     * @param {Book} book
     */
    handleBookSelect = (book) => {
        const {bookActions, history} = this.props;

        const recentSearches = bookActions.addBookToRecentSearches(book);

        this.setState({
            recentSearches,
        });

        history.push(`/book/${book.id}`);
    };

    handleFilersClick = () => {
        this.setState({
            advancedFiltersOpen: true,
        });
    };

    handleFilersClose = () => {
        this.setState({
            advancedFiltersOpen: false,
        });
    };

    handleFilersApply = (filters) => {
        const {query} = this.state;

        this.setState({
            filters,
        }, () => this.debouncedSearch(query, filters));
    };

    render() {
        const {query, searching, searchResults, recentSearches, advancedFiltersOpen, filters} = this.state;

        return <AppLayout pageId="SearchPage"
                          stickyHeader
                          renderHeader={() => <SearchHeader searching={searching}
                                                            onFiltersClick={this.handleFilersClick}
                                                            onChange={this.handleQueryChange}
                                                            query={query}
                                                            filters={filters}/>}
                          renderFooter={() => <MainFooter/>}>
            <div className="SearchPage__Container">
                {searching && <SearchingEmptyState/>}
                {!searching && searchResults?.length > 0 && <SearchResults label={`${searchResults.length} books fit your search results`} results={searchResults} onBookSelect={this.handleBookSelect}/>}
                {!searching && !query && !searchResults?.length && <SearchResults label="Recently searched" results={recentSearches} onBookSelect={this.handleBookSelect}/>}
                <AdvancedFiltersModal open={advancedFiltersOpen} onSubmit={this.handleFilersApply} activeFilters={filters} onClose={this.handleFilersClose}/>
            </div>
        </AppLayout>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        bookActions: bindActionCreators(bookActions, dispatch),
    }
}

export default connect(
    null,
    mapDispatchToProps,
)(SearchPage);
