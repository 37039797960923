import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Button from "../../Elements/Button/Button";
import {getScriptBalance, isScriptBalanceLoaded} from "../../Selectors/AccountSelectors";
import {bindActionCreators} from "redux";
import {accountActions} from "../../Core/actions";
import {getAmountForToken, getFormattedScrptPrice} from "../../Utils/Ethereum";
import FortmaticService from "../../Services/FortmaticService";
import Icon from "../../Elements/Icon/Icon";

import './AddTokensSwapStep.scss';
import {SupportedTokens} from "../../Common/constants";

class AddTokensSwapStep extends Component {
    state = {
        swapValue: '1',
        balanceLoaded: false,
        balance: null,
    };

    async componentDidMount() {
        const balance = await FortmaticService.getUserBalance(SupportedTokens.USDT, true);

        this.setState({
            balance,
            balanceLoaded: true,
        });
    }

    handleInputChange = (event) => {
        this.setState({
            swapValue: event.target.value,
        });
    };

    getCurrentSwapAmount = () => {
        const {swapValue} = this.state;

        return getAmountForToken(swapValue || 0, SupportedTokens.USDT);
    }

    handleSwap = () => {
        const {onSwapConfirm} = this.props;

        const swapAmount = this.getCurrentSwapAmount();

        onSwapConfirm(swapAmount);
    };

    hasFormError = () => {
        const {balanceLoaded, balance} = this.state;

        const swapAmount = this.getCurrentSwapAmount();

        return !balanceLoaded || swapAmount.gt(balance);
    }

    render() {
        const {swapValue, balanceLoaded, balance} = this.state;

        const swapAmount = this.getCurrentSwapAmount();

        return (
            <div className="AddTokensSwapStep">
                <h2 className="AddTokensModal__StepHeading">Get some SCRPT!</h2>
                <p className="AddTokensModal__StepDescription">How many SCRPT would you like to buy? (1 SCRPT = 1 USD ≈ 1 USDT)</p>
                <div className="AddTokensSwapStep__Input">
                    <input className="AddTokensSwapStep__Input__Control" autoFocus value={swapValue} onChange={this.handleInputChange} type="text" pattern="[0-9]+([,\.][0-9]+)?"  inputMode="numeric"/>
                    <div className="AddTokensSwapStep__Input__Token">SCRPT</div>
                </div>
                {this.hasFormError() && <div className="AddTokensSwapStep__FormError">
                    {balanceLoaded && swapAmount.gt(balance) && <span>Insufficient balance for swap</span>}
                </div>}
                <div className="AddTokensSwapStep__SwapInfo">
                    <div className="AddTokensSwapStep__SwapInfo__For">For</div>
                    <div className="AddTokensSwapStep__SwapInfo__Token">
                        <Icon icon="eth"/>
                        <span>Tether</span>
                    </div>
                    <div className="AddTokensSwapStep__SwapInfo__Cost">
                        <div className="AddTokensSwapStep__SwapInfo__Cost__Amount">{getFormattedScrptPrice(swapAmount, SupportedTokens.USDT)}</div>
                        <div className="AddTokensSwapStep__SwapInfo__Cost__Token">USDT</div>
                    </div>
                    <div className="AddTokensSwapStep__SwapInfo__Balance">
                        {balanceLoaded && <div>Current Balance: {getFormattedScrptPrice(balance, SupportedTokens.USDT)} USDT</div>}
                        {!balanceLoaded && <div>Loading your balance...</div>}
                    </div>
                </div>
                <Button color="turquoise" disabled={this.hasFormError()} size="large" onClick={this.handleSwap} stretch>
                    <span className="BoldText UppercaseText">Swap</span>
                </Button>
            </div>
        );
    }
}

AddTokensSwapStep.propTypes = {
    onSwapConfirm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        currentScript: getScriptBalance(state),
        scriptLoaded: isScriptBalanceLoaded(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        accountActions: bindActionCreators(accountActions, dispatch),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddTokensSwapStep);
