import {BigNumber} from "ethers";

import {
    CREATE_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD,
    FETCH_PAYMENT_METHODS,
    FETCH_SCRIPT_BALANCE
} from "./Account.actions";

const initialState = {
    scriptBalance: BigNumber.from(0),
    paymentMethods: {},
    paymentMethodsLoaded: false,
    loadedScriptBalance: false,
}

const AccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SCRIPT_BALANCE:
            return {
                ...state,
                scriptBalance: action.balance,
                loadedScriptBalance: true,
            };
        case FETCH_PAYMENT_METHODS:
            return {
                ...state,
                paymentMethodsLoaded: true,
                paymentMethods: action.paymentMethods.reduce((acc, pm) => {
                    if (!acc[pm.id]) {
                        acc[pm.id] = pm;
                    }

                    return acc;
                }, {}),
            };
        case CREATE_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethods: {
                    ...state.paymentMethods,
                    [action.paymentMethod.id]: action.paymentMethod,
                },
            };
        case DELETE_PAYMENT_METHOD:
            const pms = {...state.paymentMethods,};

            delete pms[action.paymentMethodId];

            return {
                ...state,
                paymentMethods: pms,
            };
        default:
            return state;
    }
};

export default AccountReducer;
