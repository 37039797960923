import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {Redirect} from 'react-router-dom';

import {authActions} from "../../Core/actions";

import AppLayout from "../../Elements/AppLayout/AppLayout";
import LoginCodeForm from "../../Components/LoginCodeForm/LoginCodeForm";
import LoginCodeHeader from "../../Components/LoginCodeHeader/LoginCodeHeader";

class LoginCodePage extends Component {
    constructor(props) {
        super(props);

        const {location: {state, search}} = props;

        const searchParams = new URLSearchParams(search);

        const emailParam = searchParams.get("email");

        this.state = {
            email: state?.email || emailParam,
        };
    }

    handleFormSubmit = async (code) => {
        const {authActions} = this.props;
        const {email} = this.state;

        return authActions.loginWithOtpCode(email, code);
    }

    handleResendCode = async () => {
        const {authActions} = this.props;
        const {email} = this.state;

        await authActions.passwordLessLogin(email);
    }

    render() {
        const {email} = this.state;

        if (!email) {
            return <Redirect to="/login"/>
        }

        return (
            <AppLayout pageId="LoginCodePage" renderHeader={() => <LoginCodeHeader/>}>
                <LoginCodeForm email={email} onSubmit={this.handleFormSubmit} onResend={this.handleResendCode}/>
            </AppLayout>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authActions: bindActionCreators(authActions, dispatch)
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(LoginCodePage);
