import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";

import {authActions} from "../../Core/actions";

import AppLayout from "../../Elements/AppLayout/AppLayout";
import LoginPageForm from "../../Components/LoginPageForm/LoginPageForm";
import {isLoggedIn} from "../../Selectors/AuthSelectors";

class LoginPage extends Component {
    componentDidMount() {
        const {history, loggedIn} = this.props;

        if (loggedIn) {
            history.push('/home');
        }
    }

    handleFormSubmit = async (email) => {
        const {history, authActions} = this.props;

        const response = await authActions.passwordLessLogin(email);

        if (response) {
            history.push('/login-code', {
                email,
            });
        }
    }

    handleSocialLogin = async (social) => {
        const {authActions} = this.props;

        authActions.loginWithSocialAccount(social);
    };

    render() {
        return (
            <AppLayout pageId="LoginPage">
                <LoginPageForm onSocialLogin={this.handleSocialLogin} onSubmit={this.handleFormSubmit}/>
            </AppLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: isLoggedIn(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        authActions: bindActionCreators(authActions, dispatch),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginPage);
