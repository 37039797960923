import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";

import {accountActions} from "../../Core/actions";

import AppLayout from "../../Elements/AppLayout/AppLayout";
import MainFooter from "../../Components/MainFooter/MainFooter";
import {getScriptBalance, isScriptBalanceLoaded} from "../../Selectors/AccountSelectors";
import {getUser} from "../../Selectors/AuthSelectors";
import ScriptBalance from "../../Components/ScriptBalance/ScriptBalance";
import ProfileNavigation from "../../Components/ProfileNavigation/ProfileNavigation";
import AddTokensModal from "../../Components/AddTokensModal/AddTokensModal";

import './ProfilePage.scss';
import WithdrawTokensModal from "../../Components/WithdrawTokensModal/WithdrawTokensModal";
import AllowanceModal from "../../Components/AllowanceModal/AllowanceModal";

class ProfilePage extends Component {
    state = {
        fetchingAllowance: false,
        allowance: 0,
        addTokensOpen: false,
        withdrawTokensOpen: false,
        allowanceOpen: false,
    };

    async componentDidMount() {
        const {accountActions} = this.props;

        accountActions.fetchUserScriptBalance();
    }

    handleActionClick = (action) => {
        switch (action) {
            case 'add-more':
                this.handleAddMoreTokens();
                break;
            case 'withdraw':
                this.handleWithdrawTokens();
                break;
            case 'set-allowance':
                this.handleManageAllowance();
                break;
            default:
                break;
        }
    }

    handleAddMoreTokens = () => {
        this.setState({
            addTokensOpen: true,
        });
    };

    handleAddMoreClose = () => {
        this.setState({
            addTokensOpen: false,
        });
    };

    handleWithdrawTokens = () => {
        this.setState({
            withdrawTokensOpen: true,
        });
    }

    handleWithdrawClose = () => {
        this.setState({
            withdrawTokensOpen: false,
        });
    };

    handleManageAllowance = () => {
        this.setState({
            allowanceOpen: true,
        });
    }

    handleAllowanceClose = () => {
        this.setState({
            allowanceOpen: false,
        });
    };

    render() {
        const {scriptBalance, scriptBalanceLoaded} = this.props;
        const {addTokensOpen, withdrawTokensOpen, allowanceOpen} = this.state;

        return (
            <AppLayout pageId="ProfilePage" background="dark-gray" renderFooter={() => <MainFooter/>}>
                <div className="ProfilePage__Content">
                    <ScriptBalance loading={!scriptBalanceLoaded}
                                   balance={scriptBalance}
                                   onAction={this.handleActionClick}/>
                    <ProfileNavigation/>
                    <AddTokensModal open={addTokensOpen} onClose={this.handleAddMoreClose}/>
                    <WithdrawTokensModal open={withdrawTokensOpen} balance={scriptBalance} onClose={this.handleWithdrawClose}/>
                    <AllowanceModal open={allowanceOpen} onClose={this.handleAllowanceClose}/>
                </div>
            </AppLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: getUser(state),
        scriptBalance: getScriptBalance(state),
        scriptBalanceLoaded: isScriptBalanceLoaded(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        accountActions: bindActionCreators(accountActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProfilePage);
