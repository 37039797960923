const initialState = {
    myProjects: {},
};

const WriteReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default WriteReducer;
