import React, {Component} from 'react';

import AppLayout from "../../Elements/AppLayout/AppLayout";
import CheckEmailEmptyState from "../../Components/CheckEmailEmptyState/CheckEmailEmptyState";

class CheckEmailPage extends Component {
    render() {
        return (
            <AppLayout pageId="CheckEmailPage">
                <CheckEmailEmptyState/>
            </AppLayout>
        );
    }
}

export default CheckEmailPage;
