import Api from "../../Utils/ApiProvider";
import BookService from "../../Services/BookService";
import Book from "../Book/Book.model";

export const parseEpub = (file) => {
    return async dispatch => {
        try {
            const formData = new FormData();

            formData.append('input.epub', file);

            const {data} = await Api.post(`/api/v1/convert/epub-md`, formData);

            const parsed = BookService.decodeBookContent(data);

            return {
                success: true,
                parsed,
            };
        } catch (e) {
            return {
                success: false,
            };
        }
    };
}

export const fetchPublishedBooks = () => {
    return async dispatch => {
        try {
            const {data} = await Api.get(`/api/v1/author/books`);

            const books = data.map(bookResponse => Book.buildFromResponse(bookResponse));

            return {
                success: true,
                books,
            };
        } catch (e) {
            return {
                success: false,
            };
        }
    };
}

/**
 * @param {data} bookData
 * @param {File} coverImage
 * @return {function(*): Promise<{success: boolean, book: *}|{success: boolean}|undefined>}
 */
export const publishBook = (bookData, coverImage) => {
    return async dispatch => {
        try {
            const {data} = await Api.post(`/api/v1/book`, bookData);

            if (!data) {
                return {
                    success: false,
                };
            }

            let book = Book.buildFromResponse(data);

            if (coverImage) {
                const formData = new FormData();

                formData.append('file', coverImage);

                const {data: coverData} = await Api.patch(`/api/v1/book/${book.id}`, formData);

                book = Book.buildFromResponse(coverData);
            }

            return {
                success: true,
                book,
            };
        } catch (e) {
            console.error(e);
            return {
                success: false,
            };
        }
    };
};
