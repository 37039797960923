import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import Dropzone from "react-dropzone";

import './FormInput.scss';

import {FormContext} from "./Form";
import {FormInputTypes} from "../../Common/constants";

class FormInput extends Component {
    static contextType = FormContext;

    componentDidMount() {
        const {registerField} = this.context;
        const {field} = this.props;

        registerField(field, this.validateInput);
    }

    handleInputChange = (event) => {
        const {field} = this.props;
        const {onFieldChange} = this.context;

        const value = event.target.value;

        onFieldChange(field, value);
    }

    handleSelectChange = (value) => {
        const {field} = this.props;
        const {onFieldChange} = this.context;

        onFieldChange(field, value);
    }

    handleUploadFile = (files) => {
        const {field} = this.props;
        const {onFieldChange} = this.context;

        const file = files[0];

        onFieldChange(field, file);
    }

    validateInput = () => {};

    render() {
        const {field, type, label, placeholder, options, selectComponents, previewDimensions, ...props} = this.props;
        const {values} = this.context;

        const inputValue = values[field] ?? '';

        return (
            <div className="FormInput">
                {label && <div className="FormInput__Label">{label}</div>}
                {type === FormInputTypes.TEXT && <input value={inputValue} onChange={this.handleInputChange} placeholder={placeholder}
                                                        type="text" className="FormInput__Control FormInput__Control--Text"/>}
                {type === FormInputTypes.TEXTAREA && <textarea value={inputValue} onChange={this.handleInputChange} placeholder={placeholder} className="FormInput__Control FormInput__Control--Textarea"/>}
                {type === FormInputTypes.SELECT && <ReactSelect components={{
                    ...selectComponents,
                    IndicatorSeparator: null,
                }} onChange={this.handleSelectChange} value={inputValue} options={options} classNamePrefix="FormInput__ControlSelect" {...props}/>}
                {type === FormInputTypes.UPLOAD && <Dropzone onDrop={this.handleUploadFile}>
                    {({getRootProps, getInputProps}) => <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!inputValue && <div className="FormInput__ControlUpload__Placeholder" style={{...previewDimensions}}>
                            {placeholder ?? "Upload your file"}
                        </div>}
                        {!!inputValue && <img className="FormInput__ControlUpload__PreviewImage" src={URL.createObjectURL(inputValue)} alt="" width={previewDimensions?.width ?? 100} height={previewDimensions?.height ?? 100}/>}
                    </div>}
                </Dropzone>}
            </div>
        );
    }
}

FormInput.propTypes = {
    type: PropTypes.oneOf(Object.values(FormInputTypes)),
    field: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    selectComponents: PropTypes.object,
};

FormInput.defaultProps = {
    type: FormInputTypes.TEXT,
    options: [],
    selectComponents: {},
};

export default FormInput;
