import React from 'react';
import {Link} from "react-router-dom";

import Container from "../../Elements/Container/Container";
import Rating from "../Rating/Rating";

import './MyLibrary.scss';
import BookCover from "../BookCover/BookCover";

const MyLibrary = ({books}) => {
    return (
        <Container>
            <div className="MyLibrary">
                <h2 className="MyLibrary__Heading">My Library</h2>
                <div className="MyLibrary__Description">Books that you have purchased or started reading partially.</div>
                {books.length > 0 && <div className="MyLibrary__Books">
                    {books.map(book => <Link to={`/book/${book.id}`} key={book.id} className="MyLibrary__Book">
                        <div className="MyLibrary__Book__Cover">
                            <BookCover book={book} className="MyLibrary__Book__Cover__Image"/>
                        </div>
                        <div className="MyLibrary__Book__Info">
                            <Rating rating={book.rating} votes={book.votes}/>
                            <div className="MyLibrary__Book__Info__Title">{book.title}</div>
                            <div className="MyLibrary__Book__Info__Author">{book.author}</div>
                        </div>
                    </Link>)}
                </div>}
            </div>
        </Container>
    );
};

export default MyLibrary;
