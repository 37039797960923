import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './OtpCodeInput.scss';

class OtpCodeInput extends Component {
    state = {
        isFocused: false,
    }

    handleInputChange = (event) => {
        const {onChange} = this.props;

        const value = event.target.value;

        const isValid = value.length <= 6;

        if (!isValid) return;

        onChange(value, event);
    };

    setFocus = (value) => {
        this.setState({
            isFocused: value,
        });
    }

    render() {
        const {value, disabled} = this.props;
        const {isFocused} = this.state;

        return (
            <div className={classNames("OtpCodeInput", {
                "OtpCodeInput--Disabled": disabled,
            })}>
                <input pattern="[0-9]*" inputMode="numeric" onFocus={() => this.setFocus(true)}
                       onBlur={() => this.setFocus(false)}
                       className="OtpCodeInput__Input" autoFocus disabled={disabled} type="number" name="otp-code" id="otp-code" value={value} onChange={this.handleInputChange}/>
                <div className="OtpCodeInput__Display">
                    <div className="OtpCodeInput__Display__Group">
                        <div className={classNames("OtpCodeInput__Display__GroupNumber", {
                            "OtpCodeInput__Display__GroupNumber--Active": isFocused && value.length === 0,
                        })}>{value[0]}</div>
                        <div className={classNames("OtpCodeInput__Display__GroupNumber", {
                            "OtpCodeInput__Display__GroupNumber--Active": isFocused && value.length === 1,
                        })}>{value[1]}</div>
                        <div className={classNames("OtpCodeInput__Display__GroupNumber", {
                            "OtpCodeInput__Display__GroupNumber--Active": isFocused && value.length === 2,
                        })}>{value[2]}</div>
                    </div>
                    <div className="OtpCodeInput__Display__Group">
                        <div className={classNames("OtpCodeInput__Display__GroupNumber", {
                            "OtpCodeInput__Display__GroupNumber--Active": isFocused && value.length === 3,
                        })}>{value[3]}</div>
                        <div className={classNames("OtpCodeInput__Display__GroupNumber", {
                            "OtpCodeInput__Display__GroupNumber--Active": isFocused && value.length === 4,
                        })}>{value[4]}</div>
                        <div className={classNames("OtpCodeInput__Display__GroupNumber", {
                            "OtpCodeInput__Display__GroupNumber--Active": isFocused && value.length >= 5,
                        })}>{value[5]}</div>
                    </div>
                </div>
            </div>
        );
    }
}

OtpCodeInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export default OtpCodeInput;
