import React from 'react';
import {withRouter} from "react-router-dom";
import Container from "../../Elements/Container/Container";
import Icon from "../../Elements/Icon/Icon";

import './ProfileHeader.scss';

const ProfileHeader = ({title, history}) => {
    return <div className="ProfileHeader">
        <Container>
            <div className="ProfileHeader__Content">
                <div className="ProfileHeader__Content__BackButton" onClick={history.goBack}>
                    <Icon icon="chevron-left"/>
                </div>
                {!!title && <div className="ProfileHeader__Content__Heading">{title}</div>}
            </div>
        </Container>
    </div>;
};

export default withRouter(ProfileHeader);
