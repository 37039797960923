import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import Container from "../../Elements/Container/Container";
import Icon from "../../Elements/Icon/Icon";

import './SearchHeader.scss';

const SearchHeader = ({searching, query, history, onChange, onFiltersClick, filters}) => {
    const activeFiltersCount = Object.keys(filters)?.length ?? 0;

    const showFilterIcon = !searching && (!!query || activeFiltersCount > 0);

    return <div className="SearchHeader">
        <Container>
            <div className="SearchHeader__Content">
                <div onClick={history.goBack} className="SearchHeader__Content__Button">
                    <Icon icon="chevron-left" className="SearchHeader__Content__Button__Icon"/>
                </div>
                <div className="SearchHeader__Content__Input">
                    <input className="SearchHeader__Content__Input__Control"
                           type="text"
                           placeholder="Enter keyword, title, author, genre..."
                           value={query}
                           onChange={event => onChange(event.target.value)}
                           autoFocus={!showFilterIcon}/>
                </div>
                {showFilterIcon && <div className="SearchHeader__Content__Button" onClick={onFiltersClick}>
                    {activeFiltersCount > 0 && <div className="SearchHeader__Content__Button__Number">{activeFiltersCount}</div>}
                    <Icon icon="sliders" className="SearchHeader__Content__Button__Icon"/>
                </div>}
            </div>
        </Container>
    </div>;
};

SearchHeader.propTypes = {
    query: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default withRouter(SearchHeader);
