import React from 'react';

import FirstSlideImage from './slide1-img@3x.png';
import SecondSlideImage from './slide2-img@3x.png';
import ThirdSlideImage from './slide3-img@3x.png';

import './IntroductionSlide.scss';

export const IntroductionSlideFirst = () => {
    return (
        <div className="IntroductionSlide IntroductionSlide--First IntroductionSlide--Inverted">
            <div className="IntroductionSlide__FeaturedImage">
                <img className="IntroductionSlide__FeaturedImage__Image" alt="" src={FirstSlideImage}/>
            </div>
            <div className="IntroductionSlide__Content">
                <div className="UppercaseText">For readers</div>
                <h1 className="IntroductionSlide__Heading">Read the books you love</h1>
                <div className="IntroductionSlide__Description">And explore new ones as being published a chapter by chapter.</div>
            </div>
        </div>
    );
};

export const IntroductionSlideSecond = () => {
    return (
        <div className="IntroductionSlide IntroductionSlide--Second IntroductionSlide--Inverted">
            <div className="IntroductionSlide__FeaturedImage">
                <img className="IntroductionSlide__FeaturedImage__Image" alt="" src={SecondSlideImage}/>
            </div>
            <div className="IntroductionSlide__Content">
                <div className="UppercaseText">For writers</div>
                <h1 className="IntroductionSlide__Heading">Take control over your writing</h1>
                <div className="IntroductionSlide__Description">Write books without hefty fees and demotivating conditions.</div>
            </div>
        </div>
    );
};

export const IntroductionSlideThird = () => {
    return (
        <div className="IntroductionSlide IntroductionSlide--Third IntroductionSlide--Inverted">
            <div className="IntroductionSlide__FeaturedImage">
                <img className="IntroductionSlide__FeaturedImage__Image" alt="" src={ThirdSlideImage}/>
            </div>
            <div className="IntroductionSlide__Content">
                <div className="UppercaseText">For freelance contributors</div>
                <h1 className="IntroductionSlide__Heading">Contribute on exiting projects</h1>
                <div className="IntroductionSlide__Description">Collaborate on exciting book projects and contribute to their greatness.</div>
            </div>
        </div>
    );
};
