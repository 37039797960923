import React, {Component} from 'react';
import {connect} from "react-redux";
import {Route} from "react-router-dom";

import {getBook, isBookLoaded} from "../../Selectors/BookSelectors";

import AppLayout from "../../Elements/AppLayout/AppLayout";
import Container from "../../Elements/Container/Container";
import BookHeader from "../../Components/BookHeader/BookHeader";
import {bindActionCreators} from "redux";
import {bookActions, accountActions} from "../../Core/actions";
import MainFooter from "../../Components/MainFooter/MainFooter";
import PageLoader from "../../Components/PageLoader/PageLoader";
import BuyBookModal from "../../Components/BuyBookModal/BuyBookModal";
import BookDescription from "../../Components/BookDescription/BookDescription";
import BookAuthor from "../../Components/BookAuthor/BookAuthor";

class BookPage extends Component {
    constructor(props) {
        super(props);

        const {bookId} = props;

        this.state = {
            buyBookOpen: false,
            tabs: [
                {
                    label: "Overview",
                    value: "overview",
                    to: `/book/${bookId}`,
                },
                {
                    label: "Author",
                    value: "author",
                    to: `/book/${bookId}/author`,
                },
                {
                    label: "Reviews",
                    value: "reviews",
                    to: `/book/${bookId}/reviews`,
                },
                {
                    label: "Book Club",
                    value: "book-club",
                    to: `/book/${bookId}/book-club`,
                },
            ],
        };
    }

    componentDidMount() {
        const {bookActions, bookLoaded, bookId} = this.props;

        if (!bookLoaded) {
            bookActions.fetchBook(bookId);
        }
    }

    handleBookBuy = async () => {
        const {book, accountActions, history} = this.props;

        if (book.isFree()) {
            const response = await accountActions.buyBook(book);

            if (response) {
                history.push(`/read/${book.id}`);
            }
        }

        this.setState({
            buyBookOpen: true,
        });
    };

    handleGiftBook = async (email) => {
        const {book, accountActions} = this.props;

        return await accountActions.giftBook(book, email);
    }

    handleBuyClose = () => {
        this.setState({
            buyBookOpen: false,
        });
    };

    render() {
        const {tabs, buyBookOpen} = this.state;
        const {book, bookLoaded} = this.props;

        if (!bookLoaded) {
            return <AppLayout pageId="BookPage" renderFooter={() => <MainFooter/>}>
                <PageLoader text="Fetching Book..."/>
            </AppLayout>;
        }

        return (
            <AppLayout pageId="BookPage" tabs={tabs}
                       renderHeader={() => <BookHeader book={book}
                                                       onBuyBook={this.handleBookBuy}
                                                       onGiftBook={this.handleGiftBook}/>}>
                <Container>
                    <Route path="/book/:bookId" exact render={(routerProps) => <BookDescription {...routerProps} book={book}/>}/>
                    <Route path="/book/:bookId/author" exact render={(routerProps) => <BookAuthor {...routerProps} book={book}/>}/>
                </Container>
                <BuyBookModal book={book} open={buyBookOpen} onClose={this.handleBuyClose}/>
            </AppLayout>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const {match: {params: {bookId}}} = ownProps;

    return {
        bookId,
        book: getBook(state, bookId),
        bookLoaded: isBookLoaded(state, bookId),
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        bookActions: bindActionCreators(bookActions, dispatch),
        accountActions: bindActionCreators(accountActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BookPage);
