import {keccak256} from "js-sha3";
import {ethers} from "ethers";
import {APP_ENV, SupportedTokens} from "../Common/constants";

/**
 * @param price
 * @param {SupportedTokens} token
 * @return {string}
 */
export function getFormattedScrptPrice(price, token) {
    const formattedPrice = parseFloat(ethers.utils.formatUnits(price, getDecimalsForToken(token)));

    return parseFloat(formattedPrice.toFixed(2)).toString();
}

export function getAmountForEthers(ethersValue) {
    return ethers.utils.parseEther(ethersValue);
}

/**
 * @param {SupportedTokens} [token]
 */
export function getDecimalsForToken(token) {
    switch (token) {
        case SupportedTokens.USDT:
            // This is because on staging our imitation of a tether token is 18 decimals
            if (APP_ENV !== 'production') {
                return 18;
            }

            return 6;
        case SupportedTokens.SCRPT:
            // This is because on staging our imitation of a tether token is 18 decimals
            if (APP_ENV !== 'production') {
                return 18;
            }

            return 6;
        default:
            return 18;
    }
}

/**
 * @param {Number|String} tokenValue
 * @param {SupportedTokens} token
 * @return {BigNumber}
 */
export function getAmountForToken(tokenValue, token) {
    return ethers.utils.parseUnits(tokenValue.toString(), getDecimalsForToken(token));
}

/**
 * Checks if the given string is an address
 *
 * @method isAddress
 * @param {String} address the given HEX address
 * @return {Boolean}
 */
export function isValidAddress(address) {
    if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
        // check if it has the basic requirements of an address
        return false;
    } else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
        // If it's all small caps or all all caps, return true
        return true;
    } else {
        // Otherwise check each case
        return isChecksumAddress(address);
    }
}

/**
 * Checks if the given string is a checksummed address
 *
 * @method isChecksumAddress
 * @param {String} address the given HEX address
 * @return {Boolean}
 */
function isChecksumAddress(address) {
    // Check each case
    address = address.replace('0x','');
    const addressHash = keccak256(address.toLowerCase());
    for (let i = 0; i < 40; i++ ) {
        // the nth letter should be uppercase if the nth digit of casemap is 1
        if ((parseInt(addressHash[i], 16) > 7 && address[i].toUpperCase() !== address[i]) || (parseInt(addressHash[i], 16) <= 7 && address[i].toLowerCase() !== address[i])) {
            return false;
        }
    }
    return true;
}
