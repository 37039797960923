import React, {Component} from 'react';
import PropTypes from 'prop-types';
import OtpCodeInput from "../OtpCodeInput/OtpCodeInput";

import './LoginCodeForm.scss';
import Container from "../../Elements/Container/Container";

class LoginCodeForm extends Component {
    state = {
        code: '',
        error: null,
        inProgress: false,
        codeResent: false,
    }

    handleSubmit = async () => {
        const {code} = this.state;
        const {onSubmit} = this.props;

        this.setState({
            inProgress: true,
            error: null,
        });

        await onSubmit(code);

        this.setState({
            inProgress: false,
        });
    }

    handleResendCode = async () => {
        const {onResend} = this.props;

        this.setState({
            codeResent: true,
        });

        onResend();
    };

    handleCodeChange = (code) => {
        this.setState({
            code,
        }, () => {
            if (code.length === 6) {
                this.handleSubmit();
            }
        });
    }

    render() {
        const {code, inProgress, codeResent} = this.state;
        const {email} = this.props;

        return (
            <div className="LoginCodeForm">
                <div className="LoginCodeForm__Content">
                    <Container>
                        <h2 className="LoginCodeForm__Content__Heading">Check Your Email</h2>
                        <p className="LoginCodeForm__Content__Description">We just sent you a temporary sign-in code. Please check your inbox.</p>
                        <OtpCodeInput disabled={inProgress} onChange={this.handleCodeChange} value={code}/>
                        {!codeResent && <div className="LoginCodeForm__Content__ResendButton" onClick={this.handleResendCode}>Resend
                            Code</div>}
                        {codeResent && <div className="LoginCodeForm__Content__ResendInfo">A new code has been sent to {email}</div>}
                    </Container>
                </div>
            </div>
        );
    }
}

LoginCodeForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onResend: PropTypes.func.isRequired,
};

export default LoginCodeForm;
