import React from 'react';
import {withRouter} from "react-router-dom";
import Container from "../../Elements/Container/Container";
import Icon from "../../Elements/Icon/Icon";

import './ExploreHeader.scss';

const ExploreHeader = ({history, onFiltersToggle}) => {
    return <div className="ExploreHeader">
        <Container>
            <div className="ExploreHeader__Content">
                <div onClick={history.goBack} className="ExploreHeader__Content__Button">
                    <Icon className="ExploreHeader__Content__Button__Icon" icon="chevron-left"/>
                </div>
                <div onClick={onFiltersToggle} className="ExploreHeader__Content__Button">
                    <Icon className="ExploreHeader__Content__Button__Icon" icon="sliders"/>
                </div>
            </div>
        </Container>
    </div>;
};

export default  withRouter(ExploreHeader);
