import React, {Component} from 'react';
import _ from 'lodash';
import TextareaAutosize from 'react-textarea-autosize';

import Icon from "../../Elements/Icon/Icon";
import Container from "../../Elements/Container/Container";

import './PreviewEditor.scss';
import BookService from "../../Services/BookService";
import ReactMarkdown from "react-markdown";
import Button from "../../Elements/Button/Button";

const updateNodeWithAction = (action, data) => {
    switch (action) {
        case 'part':
            return {
                type: 'heading',
                depth: 1,
            };
        case 'chapter':
            return {
                type: 'heading',
                depth: 2,
            };
        case 'paragraph':
            return {
                type: 'paragraph',
            };
        case 'change':
            return {
                children: data,
            };
        default:
            return {};
    }
};

class EditorBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            node: props.block,
            nodeText: props.block.content,
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {block, action} = this.props;
        const {node, nodeText} = this.state;

        if (node !== nextState.node
            || nodeText !== nextState.nodeText
            || action !== nextProps.action
            || (nextProps.reverts && nextProps.reverts.includes(block.id))) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {reverts, block, action} = this.props;

        if (reverts && reverts !== prevProps.reverts && reverts.includes(block.id)) {
            this.setState({
                node: block,
                nodeText: block.content,
            });
        }

        if (action !== prevProps.action && action?.type === "update") {
            console.log(action);
            this.setState({
                node: action.data,
                nodeText: action.data.content,
            });
        }
    }

    handleAction = (action, data) => {
        const {onAction} = this.props;
        const {node} = this.state;

        const actionData = {};

        const updatedNode = updateNodeWithAction(action, data);

        actionData.type = 'update';
        actionData.data = updatedNode;

        const newNode = {
            ...node,
            ...updatedNode,
        };

        newNode.content = BookService.getNodeContent(newNode);

        actionData.data = newNode;

        this.setState({
            node: newNode,
            nodeText: newNode.content,
        });

        onAction(actionData);
    };

    cancelEditing = () => {
        const {onAction} = this.props;
        const {node} = this.state;

        this.setState({
            nodeText: node.content,
        });

        onAction({
            type: "view",
        });
    }

    finishEditing = () => {
        const {nodeText} = this.state;

        const updatedNode = BookService.parseMarkdownIntoAst(nodeText)?.children?.[0];

        if (updatedNode) {
            this.handleAction('change', updatedNode.children);
        }
    };

    handleTextChange = (event) => {
        this.setState({
            nodeText: event.target.value,
        });
    }

    render() {
        const {node, nodeText} = this.state;
        const {action} = this.props;

        if (action?.type === "delete") return null;

        const isEditing = action?.type === "editing";

        return <div className={`EditorBlock`} data-node-id={node.id} id={`node_${node.id}`}>
            <div className={`EditorBlock__Content ${isEditing ? "EditorBlock__Content--Editing" : ""}`}>
                {!isEditing && <ReactMarkdown className="EditorBlock__Content__View" source={node.content}/>}
                {isEditing && <div>
                    <TextareaAutosize value={nodeText} autoFocus onChange={this.handleTextChange}/>
                    <div className="EditorBlock__Content__Actions">
                        <Button className="EditorBlock__Content__Action" color="white" onClick={this.finishEditing}>Save Changes</Button>
                        <Button className="EditorBlock__Content__Action" color="white" onClick={this.cancelEditing}>Cancel</Button>
                    </div>
                </div>}
            </div>
        </div>
    }
}

class PreviewEditor extends Component {
    constructor(props) {
        super(props);

        const {ast} = props;

        const nodes = ast?.children ?? [];

        if (!nodes.length) {
            nodes.push(BookService.getEmptyRichAstNode('0'));
        }

        this.state = {
            nodes,
            showChapters: true,
            showChanges: true,
            currentMode: "view",
            actions: {},
            recentActions: [],
            forcedReverts: [],
        };
    }

    handleAction = (action, nodeId) => {
        const {
            actions,
            recentActions,
            forcedReverts,
        } = this.state;

        const existingActionData = actions[nodeId]?.data ?? {};

        let updatedRecent = [...recentActions];
        let updatedReverts = [...forcedReverts];

        if (updatedRecent.includes(nodeId)) {
            _.pull(updatedRecent, nodeId);
        }

        if (updatedReverts.includes(nodeId)) {
            _.pull(updatedReverts, nodeId);
        }

        updatedRecent.unshift(nodeId);

        this.setState({
            recentActions: updatedRecent,
            forcedReverts :updatedReverts,
            actions: {
                ...actions,
                [nodeId]: {
                    type: action.type,
                    data: {
                        ...existingActionData,
                        ...action.data,
                    },
                },
            },
        });
    };

    removeEdit = (nodeId) => {
        const {forcedReverts, actions, recentActions} = this.state;

        this.setState({
            recentActions: _.without(recentActions, nodeId),
            actions: _.omit(actions, nodeId),
            forcedReverts: [
                ...forcedReverts,
                nodeId,
            ],
        });
    }

    handleSubmit = () => {
        const {onSubmit} = this.props;
        const {nodes, actions} = this.state;

        const updatedNodes = nodes.map((node) => {
            const action = actions[node.id];

            if (!action) return node;

            switch (action.type) {
                case 'delete':
                    return null;
                case 'update':
                    return {
                        ...node,
                        ...action.data,
                    };
                default:
                    return node;
            }
        }).filter(n => !!n);

        const markDown = BookService.parseAstIntoMarkdown({
            type: "root",
            children: updatedNodes,
        });

        console.log(nodes, updatedNodes);

        onSubmit(markDown);
    }

    goToNode = (nodeId) => {
        const element = document.getElementById(`node_${nodeId}`);

        if (element) {
            element.scrollIntoView();
        }
    };

    setCurrentMode = (mode) => {
        this.setState({
            currentMode: mode,
        });
    };

    handleBlockClick = (event) => {
        const {currentMode, nodes} = this.state;

        if (currentMode === "view") return;

        const {nodeId} = event.target.dataset;

        if (!nodeId) return;

        switch (currentMode) {
            case "delete":
                this.handleAction({type:"delete"}, nodeId);
                break;
            case "part":
            case "chapter":
            case "paragraph":
                const node = nodes.find(node => node.id === nodeId);

                const updatedNode = {
                    ...node,
                    ...updateNodeWithAction(currentMode),
                };

                this.handleAction({
                    type:"update",
                    data: {
                        ...updatedNode,
                        content: BookService.getNodeContent(updatedNode),
                    },
                }, nodeId);
                break;
            case "editing":
                this.handleAction({type:"editing"}, nodeId);
                break;
            default:
                break;
        }
    };

    render() {
        const {nodes, showChapters, showChanges, actions, recentActions, forcedReverts, currentMode} = this.state;

        return (
            <div className="PreviewEditor">
                {showChapters && <div className="PreviewEditor__Contents">
                    <h3>Table of contents</h3>
                    {nodes.map(node => {
                        const action = actions[node.id];

                        if (action?.type === 'delete') return null;

                        let viewNode = node;

                        if (action) {
                            viewNode = action.data;

                        }

                        if (viewNode.type !== 'heading' || ![1,2].includes(viewNode.depth)) return null;

                        return <div key={viewNode.id} onClick={() => this.goToNode(viewNode.id)} className="PreviewEditor__Contents__GoTo">
                            {viewNode.depth === 1 && <span className="PreviewEditor__Contents__Part">{viewNode.content.replaceAll('#', '').trim()}</span>}
                            {viewNode.depth === 2 && <span className="PreviewEditor__Contents__Chapter">{viewNode.content.replaceAll('#', '').trim()}</span>}
                        </div>;
                    })}
                </div>}
                <div className={`PreviewEditor__MainContent PreviewEditor__MainContent--${currentMode}`} >
                    <div className="PreviewEditor__MainContent__Controls">
                        <Container>
                            <div className="PreviewEditor__MainContent__ControlsContent">
                                <div>
                                    <span>Current Mode: {currentMode}</span>
                                </div>
                                <div>
                                    <div className="PreviewEditor__MainContent__Control" onClick={() => this.setCurrentMode("view")}>
                                        <Icon icon="eye"/>
                                        <span>View</span>
                                    </div>
                                    <div className="PreviewEditor__MainContent__Control" onClick={() => this.setCurrentMode("part")}>
                                        <Icon icon="highlight-part"/>
                                        <span>Part</span>
                                    </div>
                                    <div className="PreviewEditor__MainContent__Control" onClick={() => this.setCurrentMode("chapter")}>
                                        <Icon icon="highlight-chapter"/>
                                        <span>Chapter</span>
                                    </div>
                                    <div className="PreviewEditor__MainContent__Control" onClick={() => this.setCurrentMode("paragraph")}>
                                        <Icon icon="highlight-paragraph"/>
                                        <span>Paragraph</span>
                                    </div>
                                    <div className="PreviewEditor__MainContent__Control" onClick={() => this.setCurrentMode("editing")}>
                                        <Icon icon="edit-2"/>
                                        <span>Edit</span>
                                    </div>
                                    <div className="PreviewEditor__MainContent__Control" onClick={() => this.setCurrentMode("delete")}>
                                        <Icon icon="trash-2"/>
                                        <span>Delete</span>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className="PreviewEditor__MainContent_ScrollArea" onClick={this.handleBlockClick}>
                        <Container>
                            {nodes.map((node, index) => <EditorBlock key={node.uniqueId} index={index} action={actions[node.id]}
                                                                     onAction={(action) => this.handleAction(action, node.id)}
                                                                     reverts={forcedReverts} block={node}/>)}
                        </Container>
                    </div>
                </div>
                {showChanges && <div className="PreviewEditor__Contents">
                    <h3>Changes</h3>
                    {recentActions.map(actionId => {
                        const action = actions[actionId];

                        if (action.type === "view" || action.type === "editing") return null;

                        return <div className="PreviewEditor__Contents__Change" key={actionId}>
                            <div className="PreviewEditor__Contents__ChangeCancel"
                                 onClick={() => this.removeEdit(actionId)}>
                                <Icon icon="x-square"/>
                            </div>
                            <div className="PreviewEditor__Contents__ChangeBefore" data-content={nodes[actionId].content}/>
                            {action.type === "update" && <div className="PreviewEditor__Contents__ChangeAfter" data-content={action.data.content}/>}
                        </div>
                    })}
                </div>}
                <div className="PublishingEditor__ControlWrapper">
                    <Container>
                        <div className="PublishingEditor__ControlPanel">
                            <div onClick={this.handleSubmit} className="PublishingEditor__ControlPanel__Button PublishingEditor__ControlPanel__Button--Green">
                                <Icon className="PublishingEditor__ControlPanel__Button__Icon" icon="check"/>
                                <span>Done</span>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default PreviewEditor;
