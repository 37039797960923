import LocalStorage from '../Utils/LocalStorage';
import {
    AppFontFamilyTypes,
    AppFontSizeTypes,
    AppSpacingTypes,
    AppThemeTypes,
    LocalStorageKeys
} from "../Common/constants";

export const DEFAULT_APP_SETTINGS = {
    theme: AppThemeTypes.DEFAULT,
    fontSize: AppFontSizeTypes.NORMAL,
    fontFamily: AppFontFamilyTypes.LITERATA,
    spacing: AppSpacingTypes.CONDENSED,
}

class AppSettingsProvider {
    constructor() {
        const storageSettings = LocalStorage.getItem(LocalStorageKeys.APP_SETTINGS) ?? {};

        this.settings = Object.assign({}, DEFAULT_APP_SETTINGS, storageSettings);
    }

    getAppSettings() {
        return this.settings;
    }

    updateAppSettings(data) {
        this.settings = Object.assign({}, this.settings, data);

        LocalStorage.setItem(LocalStorageKeys.APP_SETTINGS, this.settings);

        return this.settings;
    }

    /**
     * @param {AppThemeTypes} theme
     */
    setThemeSetting(theme) {
        return this.updateAppSettings({theme});
    }

    /**
     * @param {AppSpacingTypes} spacing
     */
    setSpacingSetting(spacing) {
        return this.updateAppSettings({spacing});
    }

    /**
     * @param {AppFontSizeTypes} fontSize
     */
    setFontSizeSetting(fontSize) {
        return this.updateAppSettings({fontSize});
    }

    /**
     * @param {AppFontFamilyTypes} fontFamily
     */
    setFontFamilySetting(fontFamily) {
        return this.updateAppSettings({fontFamily});
    }

    /**
     * @return {AppThemeTypes[]}
     */
    getThemeOptions() {
        return Object.values(AppThemeTypes);
    }

    /**
     * @return {AppFontSizeTypes[]}
     */
    getFontSizeOptions() {
        return Object.values(AppFontSizeTypes);
    }

    /**
     * @return {AppFontFamilyTypes[]}
     */
    getFontFamilyOptions() {
        return Object.values(AppFontFamilyTypes);
    }

    /**
     * @param {AppFontFamilyTypes} fontFamily
     * @return {string}
     */
    getFontFamilyLabel(fontFamily) {
        switch (fontFamily) {
            case AppFontFamilyTypes.LITERATA:
                return 'Literata';
            case AppFontFamilyTypes.OPEN_SANS:
                return 'Open Sans';
            case AppFontFamilyTypes.ROBOTO:
                return 'Roboto';
            case AppFontFamilyTypes.MERRIWEATHER:
                return 'Merriweather';
            default:
                return '';
        }
    }
}

const AppSettingsService = new AppSettingsProvider();

export default AppSettingsService;
