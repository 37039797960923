import React, {Component} from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import {APP_ENV, LocalStorageKeys} from './Common/constants';
import LocalStorage from './Utils/LocalStorage';

import store from './Core/store';
import {appActions, authActions} from './Core/actions';

import Pages from "./Pages/Pages";

import 'swiper/swiper.scss';
import 'animate.css/animate.compat.css';
import 'rc-slider/assets/index.css';

import * as serviceWorker from "./serviceWorker";

import './Common/Styles/reset.scss';
import './Common/Styles/base.scss';
import './Common/Styles/utilities.scss';
import Container from "./Elements/Container/Container";
import AppLoader from "./Components/AppLoader/AppLoader";
import {ResponsiveContainer} from "./Components/ResponsiveContainer/ResponsiveContainer";

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: "https://c6b06f844023497db15217e84df55fce@o455255.ingest.sentry.io/5446578",
        environment: APP_ENV,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        tracesSampleRate: 1.0,
    });
}

class App extends Component {
    state = {
        appLoaded: false,
    }

    async componentDidMount() {
        serviceWorker.register({ onUpdate: this.onServiceWorkerUpdate });

        const onboardingFinished = LocalStorage.getItem(LocalStorageKeys.INTRODUCTION_PASSED) ?? false;

        store.dispatch(appActions.setIntroductionFinished(onboardingFinished));
        await store.dispatch(authActions.checkSessionAndLogin());
        await store.dispatch(appActions.getConfig());

        this.setState({
            appLoaded: true,
        });
    }

    onServiceWorkerUpdate = registration => {
        this.setState({
            waitingWorker: registration && registration.waiting,
            newVersionAvailable: true
        });
    }

    updateServiceWorker = () => {
        const {waitingWorker} = this.state;

        waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });

        this.setState({ newVersionAvailable: false });
        window.location.reload();
    }

    render() {
        const {appLoaded, newVersionAvailable} = this.state;

        if (!appLoaded) {
            return <div className="ScriptarnicaApp">
                <AppLoader/>
            </div>;
        }

        return (
            <ResponsiveContainer>
                <div className="ScriptarnicaApp">
                    {newVersionAvailable && <div className="NewVersionBanner">
                        <Container>
                            <div className="NewVersionBanner__Container">
                                <div className="NewVersionBanner__Info">
                                    <div className="NewVersionBanner__Info__Title">New Version Available</div>
                                    <div className="NewVersionBanner__Info__Description">There is a newer version of the application available. Click on the reload button to load the newer version.</div>
                                </div>
                                <button className="NewVersionBanner__Button" onClick={this.updateServiceWorker}>
                                    <span>Reload</span>
                                </button>
                            </div>
                        </Container>
                    </div>}
                    <Provider store={store}>
                        <BrowserRouter>
                            <Pages/>
                        </BrowserRouter>
                    </Provider>
                </div>
            </ResponsiveContainer>
        );

    }
}

export default App;
