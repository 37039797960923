import {BigNumber} from "ethers";

class Book {
    constructor(data) {
        /** @type {string} */
        this.id = data.id;

        /**
         * Number rating ranged from 0 to 5
         * @type {number}
         */
        this.rating = data.rating;

        this.votes = data.votes;

        /** @type {string} */
        this.title = data.title;

        /** @type {string} */
        this.description = data.description;

        this.author = data.author;

        this.authorId = data.authorId;

        /** @type {GenreTypes} */
        this.genre = data.genre;

        /**
         * Amount of SCRPT tokens needed - decimals 18
         * @type {number}
         */
        this.price = data.price;

        this.coverUrl = data.coverUrl;

        /** @type {Chapter.id[]} */
        this.chapters = data.chapters;

        this.currentProgress = data.currentProgress;

        /** @type {boolean} */
        this.purchased = data.purchased;

        this.path = data.path;
    }

    isFree = () => {
        return BigNumber.from(this.price).eq(0);
    }

    /**
     * @param {Object} data
     */
    update(data) {
        const updatedData = Object.assign({}, this, data);

        return new Book(updatedData);
    }

    /**
     * @param {Object} response
     * @param {boolean} [purchased]
     * @return {Book}
     */
    static buildFromResponse(response, purchased = false) {
        return new Book({
            id: response.id,
            rating: response.rating,
            title: response.title,
            description: response.description,
            author: response.author,
            authorId: response.author_id,
            genre: response.genre,
            price: response.price,
            coverUrl: response.cover_photo,
            currentProgress: 15,
            votes: response.number_of_votes,
            path: response.path,
            purchased,
        });
    }
}

export default Book;
