import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {authActions} from "../../Core/actions";

import MainFooter from "../../Components/MainFooter/MainFooter";
import AppLayout from "../../Elements/AppLayout/AppLayout";
import ProfileHeader from "../../Components/ProfileHeader/ProfileHeader";
import Button from "../../Elements/Button/Button";
import Container from "../../Elements/Container/Container";
import {getUser} from "../../Selectors/AuthSelectors";

class ProfileSettingsPage extends Component {
    handleLogout = async () => {
        const {authActions} = this.props;

        await authActions.logout();
    }

    render() {
        const {user} = this.props;

        return (
            <AppLayout pageId="ProfileSettingsPage"
                       renderHeader={() => <ProfileHeader title="Profile"/>}
                       renderFooter={() => <MainFooter/>}>
                <Container>
                    <div>
                        <div>Email: {user.email}</div>
                    </div>
                    <div>
                        <Button onClick={this.handleLogout} color="turquoise">
                            <span className="BoldText">Logout</span>
                        </Button>
                    </div>
                </Container>
            </AppLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: getUser(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        authActions: bindActionCreators(authActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProfileSettingsPage);
