import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Range} from 'rc-slider';

import './Slider.scss';
import Icon from "../Icon/Icon";

const SliderHandle = ({value, offset}) => {
    return <div className="SliderHandle" style={{left: `${offset}%`}}>
        <div className="SliderHandle__Handle">
            <Icon icon="double-line" className="SliderHandle__Handle__Icon"/>
        </div>
        <div className="SliderHandle__Info">
            <div className="SliderHandle__Info__Value">{value}</div>
            <div className="SliderHandle__Info__Token">SCRPT</div>
        </div>
    </div>
}

class Slider extends Component {
    render() {
        const {value, range, onChange} = this.props;

        const [min, max] = range;

        return (
            <div className="Slider">
                <div className="Slider__RangeInfo">
                    <div className="Slider__RangeInfo__Value">{min}</div>
                    <div className="Slider__RangeInfo__Token">SCRPT</div>
                </div>
                <div className="Slider__RangeWrapper">
                    <Range className="Slider__Control" handle={SliderHandle} onChange={onChange} value={value} min={min} max={max}/>
                </div>
                <div className="Slider__RangeInfo">
                    <div className="Slider__RangeInfo__Value">{max-1}+</div>
                    <div className="Slider__RangeInfo__Token">SCRPT</div>
                </div>
            </div>
        );
    }
}

Slider.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func,
    range: PropTypes.array,
};

Slider.defaultProps = {
    value: [0, 100],
    range: [0, 100],
    onChange: () => {},
};

export default Slider;
