import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Icon from "../../Elements/Icon/Icon";

import './RatingPicker.scss';

class RatingPicker extends Component {
    render() {
        const {value, onChange} = this.props;

        return (
            <div className="RatingPicker">
                <div className="RatingPicker__Star" onClick={() => onChange(1)}>
                    <Icon className="RatingPicker__Star__Icon" icon={value >= 1 ? "star-fill" : "star"}/>
                </div>
                <div className="RatingPicker__Star" onClick={() => onChange(2)}>
                    <Icon className="RatingPicker__Star__Icon" icon={value >= 2 ? "star-fill" : "star"}/>
                </div>
                <div className="RatingPicker__Star" onClick={() => onChange(3)}>
                    <Icon className="RatingPicker__Star__Icon" icon={value >= 3 ? "star-fill" : "star"}/>
                </div>
                <div className="RatingPicker__Star" onClick={() => onChange(4)}>
                    <Icon className="RatingPicker__Star__Icon" icon={value >= 4 ? "star-fill" : "star"}/>
                </div>
                <div className="RatingPicker__Star" onClick={() => onChange(5)}>
                    <Icon className="RatingPicker__Star__Icon" icon={value >= 5 ? "star-fill" : "star"}/>
                </div>
            </div>
        );
    }
}

RatingPicker.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default RatingPicker;
