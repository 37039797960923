import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from "../../Elements/Dialog/Dialog";
import _ from 'lodash';
import classNames from 'classnames';

import './AdvancedFiltersModal.scss';
import Button from "../../Elements/Button/Button";
import Container from "../../Elements/Container/Container";
import {CategoriesData, CategoryTypes} from "../../Common/constants";
import Icon from "../../Elements/Icon/Icon";
import Slider from "../../Elements/Slider/Slider";
import RatingPicker from "../RatingPicker/RatingPicker";

const defaultFilters = {
    price: [0, 20],
    rating: 5,
}

class AdvancedFiltersModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: defaultFilters,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {open, activeFilters} = this.props;

        if (open && open !== prevProps.open) {
            this.handleOpenFilters(activeFilters);
        }
    }

    handleOpenFilters = (activeFilters) => {
        if (!activeFilters) return;

        this.setState({
            filters: activeFilters,
        });
    };

    handleCloseFilters = () => {
        this.setState({
            filters: defaultFilters,
        });
    }

    /**
     * @return {CategoryTypes[]|*[]}
     */
    getCurrentActiveCategories = () => {
        const {filters} = this.state;

        return filters?.categories ?? [];
    }

    /**
     * @param {CategoryTypes} category
     */
    handleCategorySelect = (category) => {
        const {filters} = this.state;

        const current = this.getCurrentActiveCategories();

        const newCategories = _.xor(current, [category]);

        this.setState({
            filters: {
                ...filters,
                categories: newCategories,
            },
        });
    }

    /**
     * @param {number[]} value
     */
    handlePriceChange = (value) => {
        const {filters} = this.state;

        this.setState({
            filters: {
                ...filters,
                price: value,
            },
        });
    }

    /**
     * @param {number} value
     */
    handleRatingChange = (value) => {
        const {filters} = this.state;

        this.setState({
            filters: {
                ...filters,
                rating: value,
            },
        });
    }

    handleSubmitFilters = () => {
        const {filters} = this.state;
        const {onSubmit, onClose} = this.props;

        onClose();
        onSubmit(filters);
    };

    handleResetFilters = () => {
        const {onClose, onSubmit} = this.props;

        onClose();
        onSubmit({});
    };

    render() {
        const {open, onClose} = this.props;
        const {filters} = this.state;

        const priceFilter = filters.price;
        const ratingFilter = filters.rating;
        const categories = this.getCurrentActiveCategories();

        return (
            <Dialog fullPage noPadding open={open} onClose={onClose} onAfterClose={this.handleCloseFilters} className="AdvancedFiltersModal">
                <div className="AdvancedFiltersModal__Content">
                    <div className="AdvancedFiltersModal__Heading">
                        <span>Filter</span>
                    </div>
                    <div className="AdvancedFiltersModal__Form">
                        <div className="AdvancedFiltersModal__Form__Label">Categories</div>
                        <div className="AdvancedFiltersModal__Form__CategoriesWrapper">
                            <div className="AdvancedFiltersModal__Form__Categories">
                                {Object.values(CategoryTypes).map(cat => <div key={cat} onClick={() => this.handleCategorySelect(cat)} className={classNames(
                                    "AdvancedFiltersModal__Form__Category",
                                    {
                                        "AdvancedFiltersModal__Form__Category--Active": categories.includes(cat),
                                    },
                                )}>
                                    <Icon icon={CategoriesData[cat].icon} className="AdvancedFiltersModal__Form__Category__Icon"/>
                                    <span>{CategoriesData[cat].label}</span>
                                </div>)}
                            </div>
                        </div>
                        <div className="AdvancedFiltersModal__Form__Divider"/>
                        <div className="AdvancedFiltersModal__Form__Label">Price Range</div>
                        <div>
                            <Slider value={priceFilter} range={[0, 101]} onChange={this.handlePriceChange}/>
                        </div>
                        <div className="AdvancedFiltersModal__Form__Divider"/>
                        <div className="AdvancedFiltersModal__Form__QualityRow">
                            <div className="AdvancedFiltersModal__Form__Label">Quality</div>
                            <RatingPicker value={ratingFilter} onChange={this.handleRatingChange}/>
                        </div>
                    </div>
                    <Container>
                        <div className="AdvancedFiltersModal__Controls">
                            <div onClick={this.handleResetFilters}>Clear all filters</div>
                            <Button color="turquoise" size="large" onClick={this.handleSubmitFilters}>
                                <span>Filter books</span>
                            </Button>
                        </div>
                    </Container>
                </div>
            </Dialog>
        );
    }
}

AdvancedFiltersModal.propTypes = {
    open: PropTypes.bool.isRequired,
    activeFilters: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default AdvancedFiltersModal;
