import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

import LocalStorage from '../../Utils/LocalStorage';
import {LocalStorageKeys} from '../../Common/constants';

import {appActions} from "../../Core/actions";
import IntroductionSlider from "../../Components/IntroductionSlide/IntroductionSlider";
import AppLayout from "../../Elements/AppLayout/AppLayout";

class IntroductionPage extends Component {
    constructor(props) {
        super(props);

        const skipIntro = LocalStorage.getItem(LocalStorageKeys.INTRODUCTION_PASSED) ?? false;

        this.state = {
            skipIntro,
        };
    }


    handleSkipIntro = () => {
        const {appActions} = this.props;

        appActions.setIntroductionFinished(true);

        this.setState({
            skipIntro: true,
        });
    }

    render() {
        const {skipIntro} = this.state;

        if (skipIntro) {
            return <Redirect to="/"/>
        }

        return (
            <AppLayout pageId="IntroductionPage" fullscreen>
                <IntroductionSlider onSkip={this.handleSkipIntro}/>
            </AppLayout>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        appActions: bindActionCreators(appActions, dispatch),
    }
};

export default connect(
    null,
    mapDispatchToProps,
)(IntroductionPage);
