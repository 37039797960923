import React from 'react';
import classNames from 'classnames';

import './BookLoader.scss';

const BookLoader = ({dark}) => {
    return (
        <div className={classNames("BookLoader", {
            "BookLoader--Dark": dark,
        })}>
            <div className="inner">
                <div className="left"/>
                <div className="middle"/>
                <div className="right"/>
            </div>
            <ul>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
            </ul>
        </div>
    );
};

export default BookLoader;
