import React, {useEffect, useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {STRIPE_KEY} from "../../Common/constants";
import Button from "../../Elements/Button/Button";

import './AddTokensStripeStep.scss';
import Icon from "../../Elements/Icon/Icon";
import ButtonGroup from "../../Elements/ButtonGroup/ButtonGroup";
import {accountActions} from "../../Core/actions";
import {arePaymentMethodsLoaded, getPaymentMethods} from "../../Selectors/AccountSelectors";

const stripePromise = loadStripe(STRIPE_KEY);

const StripeForm = ({onBack, onTokenSuccess}) => {
    const [error, setError] = useState(null);
    const [inProgress, setInProgress] = useState(false);
    const [valid, setValid] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const handleAddCreditCard = async (event) => {
        // Block native form submission.
        event.preventDefault();

        setInProgress(true);

        const cardElement = elements.getElement(CardElement);

        const {error, token} = await stripe.createToken(cardElement);

        setInProgress(false);

        setError(error);

        if (!error && token) {
            onTokenSuccess(token);
        }
    }

    const handleCreditCardChange = (event) => {
        setValid(event.complete);
    }

    return <form onSubmit={handleAddCreditCard} className="AddTokensStripeStep__Form">
        <p>Add new payment method details</p>
        {!!error && <div className="AddTokensStripeStep__Error">
            {error.message}
        </div>}
        <div className="AddTokensStripeStep__StripeInputWrapper">
            <CardElement onChange={handleCreditCardChange} options={{
                style: {
                    base: {
                        iconColor: '#8594A8',
                        color: '#ffffff',
                        fontSize: '1em',
                        '::placeholder': {
                            color: '#8594A8',
                        },
                    },
                }
            }}/>
        </div>
        <ButtonGroup>
            <Button onClick={onBack} size="large" color="white">
                <Icon icon="chevron-left"/>
            </Button>
            <Button color="turquoise" size="large" type="submit" stretch disabled={!valid || inProgress}>
                <span className="BoldText">Add Payment Method</span>
            </Button>
        </ButtonGroup>
    </form>
};

const AddTokensStripeStep = ({onBack, onSelectPaymentMethod, onDeletePaymentMethod, accountActions, paymentMethodsLoaded, paymentMethods}) => {
    useEffect(() => {
        if (!paymentMethodsLoaded) {
            accountActions.fetchPaymentMethods();
        }
        // eslint-disable-next-line
    }, []);

    const handleCreatePaymentMethod = async (token) => {
        const pm = await accountActions.createPaymentMethod(token.id);

        if (!pm) return;

        handleSelectPaymentMethod(pm);
    };

    const handleSelectPaymentMethod = (pm) => {
        onSelectPaymentMethod(pm);
    }

    const handleDeletePaymentMethod = (pm, event) => {
        event.preventDefault();
        event.stopPropagation();

        onDeletePaymentMethod(pm);
    }

    return (
        <div className="AddTokensStripeStep">
            <h2 className="AddTokensModal__StepHeading">Payment Method</h2>
            <p className="AddTokensModal__StepDescription">Select payment method or add a new one</p>
            <div className="AddTokensStripeStep__PaymentMethods">
                {paymentMethods.map(pm => <div key={pm.id} onClick={() => handleSelectPaymentMethod(pm)} className="AddTokensStripeStep__PaymentMethod">
                    <Icon icon="credit-card" className="AddTokensStripeStep__PaymentMethod__Icon"/>
                    <span className="AddTokensStripeStep__PaymentMethod__Label">
                        <span>{pm.cardBrand} </span>
                        <span>•••• - {pm.last4}</span>
                    </span>
                    <div onClick={(event) => handleDeletePaymentMethod(pm, event)} className="AddTokensStripeStep__PaymentMethod__DeleteButton">
                        <Icon icon="x"/>
                    </div>
                </div>)}
            </div>
            <Elements stripe={stripePromise}>
                <StripeForm onBack={onBack} onTokenSuccess={handleCreatePaymentMethod}/>
            </Elements>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        paymentMethods: getPaymentMethods(state),
        paymentMethodsLoaded: arePaymentMethodsLoaded(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        accountActions: bindActionCreators(accountActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddTokensStripeStep);
