import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {isLoggedIn} from "../../Selectors/AuthSelectors";

class OAuthPage extends Component {
    render() {
        const {loggedIn} = this.props;

        if (!loggedIn) {
            return <Redirect to="/login"/>;
        }

        return <Redirect to="/home"/>;
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: isLoggedIn(state),
    }
}

export default connect(
    mapStateToProps,
)(OAuthPage);
