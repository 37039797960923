export function isBookLoaded(state, bookId) {
    return !!state.book.books[bookId];
}

export function getBook(state, bookId) {
    if (!isBookLoaded(state, bookId)) {
        return null;
    }

    return state.book.books[bookId];
}

export function areOwnedBooksLoaded(state) {
    return state.book.libraryFetched;
}

export function getUserOwnedBooks(state) {
    return Object.values(state.book.library);
}

export function isCurrentlyReadingLoaded(state) {
    return state.book.currentlyReadingFetched;
}

export function getCurrentlyReadingBooks(state) {
    if (!isCurrentlyReadingLoaded(state)) {
        return [];
    }

    return Object.values(state.book.currentlyReading);
}
