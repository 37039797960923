import React from 'react';
import classNames from 'classnames';

import './Container.scss';

const Container = ({children, fullWidth, className}) => {
    return (
        <div className={classNames(
            "Container",
            {
                "Container--FullWidth": fullWidth,
            },
            className,
        )}>
            {children}
        </div>
    );
};

export default Container;
