import React from 'react';
import BookRecommendationsCategory from "./BookRecommendationsCategory";

const BookRecommendations = ({recommendations}) => {
    return (
        <div>
            {Object.keys(recommendations).map(category => <div key={category}>
                <BookRecommendationsCategory category={category} books={recommendations[category]}/>
            </div>)}
        </div>
    );
};

export default BookRecommendations;
