import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';

import './WithdrawTokensModal.scss';
import Dialog from "../../Elements/Dialog/Dialog";
import WithdrawTokensModalAmountStep from "./WithdrawTokensModalAmountStep";
import WithdrawTokensModalAddressStep from "./WithdrawTokensModalAddressStep";
import WithdrawTokensModalConfirmStep from "./WithdrawTokensModalConfirmStep";
import {SupportedTokens} from "../../Common/constants";
import {getAmountForToken} from "../../Utils/Ethereum";
import {accountActions} from "../../Core/actions";

class WithdrawTokensModal extends Component {
    steps = {
        AMOUNT: 'amount',
        ADDRESS: 'address',
        CONFIRM: 'confirm',
    };

    initialState = {
        step: this.steps.AMOUNT,
        withdrawAmount: null,
        withdrawAddress: null,
        actionInProgress: false,
    };

    state = this.initialState;

    handleAfterClose = () => {
        this.setState(this.initialState);
    };

    goToStep = (step) => {
        this.setState({
            step,
        });
    };

    handleSwapStep = (amount) => {
        this.setState({
            withdrawAmount: getAmountForToken(amount, SupportedTokens.SCRPT),
            step: this.steps.ADDRESS,
        });
    }

    handleConfirmAddress = (address) => {
        this.setState({
            withdrawAddress: address,
            step: this.steps.CONFIRM,
        });
    };

    handleConfirmWithdrawal = async () => {
        const {onClose, accountActions} = this.props;
        const {withdrawAmount, withdrawAddress} = this.state;

        this.setState({
            actionInProgress: true,
        });

        const actionResult = await accountActions.withdrawFunds(withdrawAddress, withdrawAmount);

        this.setState({
            actionInProgress: false,
        });

        if (actionResult) {
            onClose();
        }
    };

    render() {
        const {open, onClose, balance} = this.props;
        const {step, withdrawAmount, withdrawAddress, actionInProgress} = this.state;

        return (
            <Dialog className="WithdrawTokensModal" open={open} onClose={onClose} onAfterClose={this.handleAfterClose}>
                {actionInProgress && <div className="AddTokensModal__ProgressOverlay"/>}
                {step === this.steps.AMOUNT && <WithdrawTokensModalAmountStep initialAmount={withdrawAmount} balance={balance} onSwap={this.handleSwapStep}/>}
                {step === this.steps.ADDRESS && <WithdrawTokensModalAddressStep initialAddress={withdrawAddress} onBack={() => this.goToStep(this.steps.AMOUNT)} amount={withdrawAmount} onConfirmAddress={this.handleConfirmAddress}/>}
                {step === this.steps.CONFIRM && <WithdrawTokensModalConfirmStep onBack={() => this.goToStep(this.steps.ADDRESS)} amount={withdrawAmount} address={withdrawAddress} onConfirm={this.handleConfirmWithdrawal}/>}
            </Dialog>
        );
    }
}

WithdrawTokensModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {
        accountActions: bindActionCreators(accountActions, dispatch),
    };
};

export default connect(
    null,
    mapDispatchToProps,
)(WithdrawTokensModal);
