/**
 * @param {Object} state
 * @return {boolean}
 */
export function isLoggedIn(state) {
    return state.auth.loggedIn;
}

/**
 * @param {Object} state
 * @return {User}
 */
export function getUser(state) {
    return state.auth.user;
}
