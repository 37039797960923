import React from 'react';
import {withRouter} from "react-router-dom";

import Container from "../../Elements/Container/Container";
import Icon from "../../Elements/Icon/Icon";

import './LibraryHeader.scss';

const LibraryHeader = ({history}) => {
    return <div className="LibraryHeader">
        <Container>
            <div className="LibraryHeader__Content">
                <div onClick={history.goBack} className="LibraryHeader__Content__Button">
                    <Icon className="LibraryHeader__Content__Button__Icon" icon="chevron-left"/>
                </div>
                <div className="LibraryHeader__Content__Button">
                    <Icon className="LibraryHeader__Content__Button__Icon" icon="more-horizontal"/>
                </div>
            </div>
        </Container>
    </div>;
};

export default withRouter(LibraryHeader);
