import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink} from "react-router-dom";
import Icon from "../../Elements/Icon/Icon";

import './MainFooter.scss';
import Container from "../../Elements/Container/Container";

class MainFooter extends Component {
    render() {
        return (
            <div className="MainFooter">
                <Container>
                    <div className="MainFooter__Navigation">
                        <NavLink to="/home" strict className="MainFooter__NavLink" activeClassName="MainFooter__NavLink--Active">
                            <Icon icon="book-open"/>
                        </NavLink>
                        <NavLink to="/write" strict className="MainFooter__NavLink" activeClassName="MainFooter__NavLink--Active">
                            <Icon icon="feather"/>
                        </NavLink>
                        <NavLink to="/search" strict className="MainFooter__NavLink" activeClassName="MainFooter__NavLink--Active">
                            <Icon icon="search"/>
                        </NavLink>
                        <NavLink to="/profile" strict className="MainFooter__NavLink" activeClassName="MainFooter__NavLink--Active">
                            <Icon icon="align-right"/>
                        </NavLink>
                    </div>
                </Container>
            </div>
        );
    }
}

export default connect(
    null,
    null,
)(MainFooter);
