import React, {PureComponent} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import Icon from "../../Elements/Icon/Icon";
import Button from "../../Elements/Button/Button";
import Dialog from "../../Elements/Dialog/Dialog";

import './CreateBookDialog.scss';
import Dropzone from "react-dropzone";
import {bindActionCreators} from "redux";
import {writeActions} from "../../Core/actions";

class CreateBookDialog extends PureComponent {
    state = {
        loading: false,
    };

    handleNewClick = () => {
        const {history} = this.props;

        history.push('/publish');
    }

    handleFileUpload = async (files) => {
        const {writeActions, history} = this.props;

        const file = files[0];

        this.setState({loading: true,});

        const response = await writeActions.parseEpub(file);

        this.setState({loading: false,});

        if (response.success) {
            history.push({
                pathname: '/publish',
                state: {
                    content: response.parsed,
                },
            });
        }
    }

    render() {
        const {onClose, open} = this.props;
        const {loading} = this.state;

        return <Dialog className="CreateBookDialog" open={open} onClose={onClose}>
            {loading && <div className="CreateBookDialog__Overlay">
                Importing book...
            </div>}
            <h2>Start a New Masterpiece</h2>
            <div className="CreateBookDialog__Picker">
                <div className="CreateBookDialog__PickerItem" onClick={this.handleNewClick}>
                    <Icon icon="file" className="CreateBookDialog__PickerItem__Icon"/>
                    <div>Write your new masterpiece</div>
                </div>
                <div className="CreateBookDialog__PickerDivider">or</div>
                <Dropzone onDrop={this.handleFileUpload}>
                    {({getRootProps, getInputProps}) => (
                        <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="CreateBookDialog__PickerItem">
                                    <Icon icon="file-plus" className="CreateBookDialog__PickerItem__Icon"/>
                                    <div>
                                        <div>Browse your device for</div>
                                        <div className="CreateBookDialog__PickerItem__Exts">.MD, .EPUB</div>
                                        <Button color="turquoise">
                                            <span>Upload</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>
        </Dialog>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        writeActions: bindActionCreators(writeActions, dispatch),
    };
};

export default withRouter(connect(
    null,
    mapDispatchToProps,
)(CreateBookDialog));
