import React from 'react';

import BookLoader from "../BookLoader/BookLoader";

import './PublishingLoader.scss';

const PublishingLoader = () => {
    return (
        <div className="PublishingLoader">
            <BookLoader dark/>
            <h2 className="PublishingLoader__Text">Publishing your book</h2>
        </div>
    );
};

export default PublishingLoader;
