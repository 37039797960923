import React, {Component} from 'react';
import {connect} from 'react-redux';

import Container from "../../Elements/Container/Container";
import AppLayout from "../../Elements/AppLayout/AppLayout";
import MainFooter from "../../Components/MainFooter/MainFooter";
import SearchButton from "../../Components/SearchButton/SearchButton";
import ExploreHeader from "../../Components/ExploreHeader/ExploreHeader";
import {bindActionCreators} from "redux";
import {bookActions} from "../../Core/actions";
import BookRecommendations from "../../Components/BookRecommendations/BookRecommendations";
import BookLoader from "../../Components/BookLoader/BookLoader";
import CategoryQuickFilters from "../../Components/CategoryQuickFilters/CategoryQuickFilters";
import AdvancedFiltersModal from "../../Components/AdvancedFiltersModal/AdvancedFiltersModal";

class ExplorePage extends Component {
    state = {
        loading: true,
        recommendations: null,
        filtersOpen: false,
    };

    handleFilersClick = () => {
        this.setState({
            filtersOpen: true,
        });
    };

    handleFilersClose = () => {
        this.setState({
            filtersOpen: false,
        });
    };

    async componentDidMount() {
        const {bookActions} = this.props;

        const recommendations = await bookActions.fetchExploreContent();

        this.setState({
            loading: false,
            recommendations,
        })
    }

    handleFiltersApply = (filters) => {
        const {history} = this.props;

        const queryData = new URLSearchParams();

        if (filters.price) {
            queryData.append('priceFrom', filters.price[0]);
            queryData.append('priceTo', filters.price[1]);
        }

        if (filters.categories && filters.categories?.length > 0) {
            filters.categories.forEach(category => {
                queryData.append('categories', category);
            });
        }

        if (filters.rating) {
            queryData.append('rating', filters.rating);
        }

        history.push({
            pathname: '/search',
            search: queryData.toString(),
        });
    };

    render() {
        const {loading, recommendations, filtersOpen} = this.state;

        return (
            <AppLayout pageId="ExplorePage" stickyHeader renderHeader={() => <ExploreHeader onFiltersToggle={this.handleFilersClick}/>} renderFooter={() => <MainFooter/>}>
                <Container>
                    <h2>Explore</h2>
                    <p>Some of these books may find a new special place on your shelf, or in your hearth.</p>
                    <SearchButton/>
                </Container>
                {loading && <BookLoader/>}
                {!loading && <div>
                    <CategoryQuickFilters/>
                    {!!recommendations && <BookRecommendations recommendations={recommendations}/>}
                </div>}
                <AdvancedFiltersModal onSubmit={this.handleFiltersApply} open={filtersOpen} onClose={this.handleFilersClose}/>
            </AppLayout>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
         bookActions: bindActionCreators(bookActions, dispatch),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(ExplorePage);
