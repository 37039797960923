import React from 'react';
import {components} from 'react-select';
import {hasFlag} from 'country-flag-icons';
import Flags from 'country-flag-icons/react/1x1';

import './CountrySelectComponents.scss';

const CountrySelectComponent = ({country}) => {
    const FlagComponents = Flags[country.countryKey];

    return <div className="CountrySelectComponent">
        <div className="CountrySelectComponent__FlagWrapper">
            {hasFlag(country.countryKey) && <FlagComponents className="CountrySelectComponent__Flag"/>}
        </div>
        <div className="CountrySelectComponent__Label">{country.name}</div>
    </div>
};

const CountrySelectOption = (props) => {
    const country = props.data;

    return (
        <components.Option {...props} className="CountrySelectOption">
            <CountrySelectComponent country={country}/>
        </components.Option>
    );
}

const CountrySelectValue = (props) => {
    const country = props.data;

    return (
        <components.SingleValue {...props} className="CountrySelectValue">
            <CountrySelectComponent country={country}/>
        </components.SingleValue>
    );
};

const LanguageSelectComponent = ({language}) => {
    return <div className="LanguageSelectComponent">
        <div className="LanguageSelectComponent__IconWrapper">
            <span className="CountrySelectComponent__Language">{language.languageKey.toUpperCase()}</span>
        </div>
        <div className="LanguageSelectComponent__Label">{language.name}</div>
    </div>
};

const LanguageSelectOption = (props) => {
    const language = props.data;

    return (
        <components.Option {...props} className="LanguageSelectOption">
            <LanguageSelectComponent language={language}/>
        </components.Option>
    );
}

const LanguageSelectValue = (props) => {
    const language = props.data;

    return (
        <components.SingleValue {...props} className="LanguageSelectValue">
            <LanguageSelectComponent language={language}/>
        </components.SingleValue>
    );
};

export {
    CountrySelectValue,
    CountrySelectOption,
    LanguageSelectOption,
    LanguageSelectValue,
};
