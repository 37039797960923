import {CategoriesData} from "../Common/constants";

class CategoryService {
    /**
     * @param {CategoryTypes} category
     * @return {string}
     */
    static getLabelForCategory(category) {
        return CategoriesData[category]?.label ?? category;
    }
}

export default CategoryService;
