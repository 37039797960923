import React from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import AppLayout from "../../Elements/AppLayout/AppLayout";
import {isLoggedIn} from "../../Selectors/AuthSelectors";
import MainFooter from "../../Components/MainFooter/MainFooter";

import Button from "../../Elements/Button/Button";
import Container from "../../Elements/Container/Container";

import './PageNotFoundPage.scss';

const PageNotFoundPage = ({isLoggedIn}) => {
    return (
        <AppLayout pageId="PageNotFoundPage" renderFooter={() => {
            if (!isLoggedIn) return null;

            return <MainFooter/>;
        }}>
            <Container className="PageNotFoundPage__Container">
                <div className="PageNotFoundPage__EmptyState">
                    <h2 className="PageNotFoundPage__EmptyState__Heading">We couldn't<br/>find this page</h2>
                    <p className="PageNotFoundPage__EmptyState__Description">
                        <span>Looking for a specific book?</span>
                        <br/>
                        <span>Try exploring our plentiful library.</span>
                    </p>
                    <Button color="turquoise" to="/explore" size="large">Explore Scriptarnica</Button>
                </div>
            </Container>
        </AppLayout>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: isLoggedIn(state),
    };
};

export default withRouter(connect(
    mapStateToProps,
)(PageNotFoundPage));
