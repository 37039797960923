import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "react-router-dom";

import Container from "../../Elements/Container/Container";
import CategoryService from "../../Services/CategoryService";

import './BookRecommendationsCategory.scss';
import BookCover from "../BookCover/BookCover";

/**
 * @param {CategoryTypes} category
 * @param {Book[]} books
 */
const BookRecommendationsCategory = ({category, books}) => {
    return (
        <div className="BookRecommendationsCategory">
            <Container>
                <div className="BookRecommendationsCategory__Title">Most popular in {CategoryService.getLabelForCategory(category)}</div>
            </Container>
            <div>
                <Swiper className="BookRecommendationsCategory__Swiper"
                        slidesPerView="auto">
                    {books?.map(book => <SwiperSlide key={book.id} className="BookRecommendationsCategory__Book">
                        <Link to={`/book/${book.id}`} className="BookRecommendationsCategory__BookLink">
                            <BookCover book={book} className="BookRecommendationsCategory__Book__Img"/>
                            <div className="BookRecommendationsCategory__Book__Info">
                                <div className="BookRecommendationsCategory__Book__Info__Title">{book.title}</div>
                                <div className="BookRecommendationsCategory__Book__Info__Author">{book.author}</div>
                            </div>
                        </Link>
                    </SwiperSlide>)}
                </Swiper>
            </div>
        </div>
    );
};

export default BookRecommendationsCategory;
