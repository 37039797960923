import React from 'react';

const BookAuthor = ({book}) => {
    if (!book) return null;

    return (
        <div className="BookAuthor">
            {book.author}
        </div>
    );
};

export default BookAuthor;
