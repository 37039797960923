import React, {Component} from 'react';
import Icon from "../../Elements/Icon/Icon";
import Input from "../../Elements/Input/Input";
import Container from "../../Elements/Container/Container";

import './LoginPageForm.scss';
import {isValidEmail} from "../../Utils/FormValidators";

class LoginPageForm extends Component {
    state = {
        email: '',
        error: null,
    };

    handleInputChange = (value) => {
        this.setState({
            email: value,
        });
    };

    handleInputButtonClick = () => {
        const {email} = this.state;
        const {onSubmit} = this.props;

        if (!isValidEmail(email)) {
            this.setState({
                error: "Invalid email address. Please try again.",
            });
            return;
        }

        this.setState({
            error: null,
        });

        onSubmit(email);
    };

    handleSocialLogin = (social) => {
        const {onSocialLogin} = this.props;

        onSocialLogin(social)
    };

    render() {
        const {email, error} = this.state;

        return (
            <Container>
                <div className="LoginPageForm">
                    <h1 className="LoginPageForm__Heading">Welcome to Scriptarnica</h1>
                    <p className="LoginPageForm__Description">Let's dive into the world of books.</p>
                    <form className="LoginPageForm__Form" onSubmit={this.handleInputButtonClick}>
                        <Input type="email" value={email}
                               placeholder="Email" onChange={this.handleInputChange}
                               buttonIcon="arrow-right"
                               buttonType="submit"
                               error={!!error}
                               onButtonClick={this.handleInputButtonClick}/>
                        {!!error && <div className="LoginPageForm__Form__Error">{error}</div>}
                    </form>
                    <div className="LoginPageForm__OrSeparator">
                        <div>or</div>
                    </div>
                    <div className="LoginPageForm__SocialLinks">
                        <div className="LoginPageForm__SocialLinks__Link" onClick={() => this.handleSocialLogin('google-oauth2')}>
                            <Icon icon="google" className="LoginPageForm__SocialLinks__Link__Icon"/>
                            <div>Google</div>
                        </div>
                        <div className="LoginPageForm__SocialLinks__Link" onClick={() => this.handleSocialLogin('facebook')}>
                            <Icon icon="facebook" className="LoginPageForm__SocialLinks__Link__Icon"/>
                            <div>Facebook</div>
                        </div>
                        <div className="LoginPageForm__SocialLinks__Link" onClick={() => this.handleSocialLogin('twitter')}>
                            <Icon icon="twitter" className="LoginPageForm__SocialLinks__Link__Icon"/>
                            <div>Twitter</div>
                        </div>
                        <div className="LoginPageForm__SocialLinks__Link" onClick={() => this.handleSocialLogin('linkedin')}>
                            <Icon icon="linkedin" className="LoginPageForm__SocialLinks__Link__Icon"/>
                            <div>LinkedIn</div>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}

export default LoginPageForm;
