import unified from 'unified';
import remarkParse from "remark-parse";
import remarkStringify from "remark-stringify";

function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
}

function b64DecodeUnicode(str) {
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

const Parser = unified().use(remarkParse);
const Compiler = unified().use(remarkStringify);

class BookService {

    /**
     * @param {string} content - Base64 Encoded content
     * @return {string} - Base64 Decoded Content
     */
    static decodeBookContent(content) {
        return b64DecodeUnicode(content);
    }

    /**
     * @param {string} content
     * @return {string} - Base64 Encoded Content
     */
    static encodeBookContent(content) {
        return b64EncodeUnicode(content);
    }

    static getNodeContent (node) {
        return BookService.parseAstIntoMarkdown(node);
    }

    /**
     * @param {string} markdown
     */
    static parseMarkdownIntoAst = (markdown) => {
        try {
            return Parser.parse(markdown);
        } catch (e) {
            return null;
        }
    };

    /**
     * @param {string} markdown
     */
    static parseMarkdownIntoRichAst = (markdown) => {
        try {
            const ast = BookService.parseMarkdownIntoAst(markdown);

            return {
                children: ast.children.filter(node => {
                    node.content = BookService.getNodeContent(node).trim();

                    const oldContent = node.content;

                    if (node.content === '***' || node.content === '.') return false;

                    if (node.content.startsWith('{:') && node.content.endsWith('}')) return false;

                    // This has the potential to produce bugs
                    if (node.content.startsWith('!!!(') && node.content.endsWith(')')) return false;

                    if (node.content.startsWith('[![')) {
                        // This is parsing images
                        // eslint-disable-next-line
                        node.content = node.content.trim().replace(/^\[(!\[[^\)]*\)).*/g, '$1');
                    }

                    if (node.content.includes('!!!(')) {
                        // eslint-disable-next-line
                        node.content = node.content.replaceAll(/!!!\([^\)]*\).\{\:\.[^\}]*\}?./g, '');
                    }

                    if (oldContent !== node.content) {
                        const updatedNode = BookService.parseMarkdownIntoAst(node.content);

                        node.children = updatedNode.children;
                    }

                    if (node.type === 'heading') {
                        if (node.content.replaceAll('#','').trim().toLowerCase().startsWith('chapter')) {
                            node.depth = 2;
                        } else if (node.content.replaceAll('#','').trim().toLowerCase().startsWith('part')) {
                            node.depth = 1;
                        }
                    }

                    node.uniqueId = `${node.type}${node.depth ?? ''}_${node.position.start.offset}_${node.position.end.offset}`;

                    return !!node.content;
                }).map((node, index) => {
                    node.id = index.toString();
                    return node;
                }),
            };
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    static getEmptyRichAstNode = (id) => {
        const {position} = BookService.parseMarkdownIntoAst('');

        return {
            id,
            position,
            content: "",
        };
    }

    /**
     * @param {Node} ast
     */
    static parseAstIntoMarkdown = (ast) => {
        try {
            return Compiler.stringify(ast);
        } catch (e) {
            return null;
        }
    };

    static parseMarkdownIntoBookContent = (markdown) => {
        const partsSplit = markdown.replace(/(\n#\s|^#\s)/g, '[SCRPT_PART]$1')
            .split('[SCRPT_PART]')
            .map(s => s.trim())
            .filter(s => !!s);

        const data = [];

        partsSplit.forEach(part => {
            let chaptersContent, partName;

            if (part.startsWith('# ')) {
                const firstPartNewLine = part.indexOf('\n');

                partName = part.slice(0, firstPartNewLine).replace('#', '').trim();
                chaptersContent = part.slice(firstPartNewLine).trim();
            } else {
                partName = '';
                chaptersContent = part.trim();
            }

            const chaptersSplit = chaptersContent.replace(/(\n##\s|^##\s)/g, '[SCRPT_CHAPTER]$1')
                .split('[SCRPT_CHAPTER]')
                .map(s => s.trim())
                .filter(s => !!s);

            const partData = {
                name: partName,
                chapters: [],
            };

            chaptersSplit.forEach(chapter => {
                let chapterName, chapterContent;

                if (chapter.startsWith('## ')) {
                    const firstChapterNewLine = chapter.indexOf('\n');

                    chapterName = chapter.slice(0, firstChapterNewLine).replace('##', '').trim();
                    chapterContent = chapter.slice(firstChapterNewLine).trim();
                } else {
                    chapterName = '';
                    chapterContent = chapter.trim();
                }

                const chapterData = {
                    name: chapterName,
                    content: BookService.encodeBookContent(chapterContent),
                };

                partData.chapters.push(chapterData);
            });

            data.push(partData);
        });

        return data;
    };
}

export default BookService;
