import React from 'react';

import BookLoader from "../BookLoader/BookLoader";

import './AppLoader.scss';

const AppLoader = () => {
    return (
        <div className="AppLoader">
            <div className="AppLoader__Loader">
                <BookLoader/>
            </div>
            <div className="AppLoader__Logo">Scriptarnica</div>
        </div>
    );
};

export default AppLoader;
