import React from 'react';
import BookLoader from "../BookLoader/BookLoader";

import './PageLoader.scss';

const PageLoader = ({text}) => {
    return (
        <div className="PageLoader">
            <BookLoader dark/>
            {!!text && <div className="PageLoader__Text">{text}</div>}
        </div>
    );
};

export default PageLoader;
