import React from 'react';
import Icon from "../../Elements/Icon/Icon";

const AddTokensTypeSelect = ({onSelect}) => {
    return (
        <div className="AddTokensTypeSelect">
            <h2 className="AddTokensModal__StepHeading">Let’s Add More Coins</h2>
            <div className="WalletProviderSelect">
                <div onClick={() => onSelect('stripe')} className="WalletProviderSelect__Option">
                    <Icon className="WalletProviderSelect__Option__Logo" icon="credit-card"/>
                    <div className="WalletProviderSelect__Option__Info">
                        <span className="WalletProviderSelect__Option__Info__Name">Credit Card</span>
                    </div>
                    <Icon className="WalletProviderSelect__Option__Arrow" icon="chevron-right"/>
                </div>
                <div onClick={() => onSelect('crypto')} className="WalletProviderSelect__Option">
                    <Icon className="WalletProviderSelect__Option__Logo" icon="eth"/>
                    <div className="WalletProviderSelect__Option__Info">
                        <span className="WalletProviderSelect__Option__Info__Name">Crypto</span>
                    </div>
                    <Icon className="WalletProviderSelect__Option__Arrow" icon="chevron-right"/>
                </div>
            </div>
        </div>
    );
};

export default AddTokensTypeSelect;
