import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './PublishingEditor.scss';
import BookService from "../../Services/BookService";
import PreviewEditor from "../PreviewEditor/PreviewEditor";

class PublishingEditor extends Component {
    constructor(props) {
        super(props);

        const {initialText} = props;

        this.state = {
            parsedAst: BookService.parseMarkdownIntoRichAst(initialText),
        };
    }

    handleSubmitContent = (markdown) => {
        const {onSubmit} = this.props;

        const content = BookService.parseMarkdownIntoBookContent(markdown);

        console.log(content)

        onSubmit(content);
    }

    render() {
        const {parsedAst} = this.state;

        return (
            <div className="PublishingEditor">
                <PreviewEditor onSubmit={this.handleSubmitContent} ast={parsedAst}/>
            </div>
        );
    }
}

PublishingEditor.propTypes = {
    initialText: PropTypes.string,
};

PublishingEditor.defaultProps = {
    initialText: '',
};

export default PublishingEditor;
