import React, {Component} from 'react';
import {connect} from 'react-redux';
import AppLayout from "../../Elements/AppLayout/AppLayout";
import MainFooter from "../../Components/MainFooter/MainFooter";
import Button from "../../Elements/Button/Button";
import CreateBookDialog from "../../Components/CreateBookDialog/CreateBookDialog";
import PageEmptyState from "../../Components/PageEmptyState/PageEmptyState";
import {bindActionCreators} from "redux";
import {writeActions} from "../../Core/actions";
import PublishedBooks from "../../Components/PublishedBooks/PublishedBooks";

class WritePage extends Component {
    state = {
        createProjectModalOpen: false,
        publishedBooks: [],
        loaded: false,
    };

    async componentDidMount() {
        const {writeActions} = this.props;

        const response = await writeActions.fetchPublishedBooks();

        if (response.success) {
            this.setState({
                publishedBooks: response.books,
            });
        }

        this.setState({
            loaded: true,
        });
    }

    openCreateProject = () => {
        this.setState({
            createProjectModalOpen: true,
        })
    };

    closeCreateProject = () => {
        this.setState({
            createProjectModalOpen: false,
        })
    };

    render() {
        const {createProjectModalOpen, loaded, publishedBooks} = this.state;

        return (
            <AppLayout pageId="WritePage"
                       renderFooter={() => <MainFooter/>}>
                {loaded && <>
                    {publishedBooks.length === 0 && <PageEmptyState title={<span>This is where your<br/>books will live</span>}
                                     description="Upload or start writing your first book." actions={<>
                        <Button size="large" color="turquoise" onClick={this.openCreateProject}>
                            <span>Create your first project</span>
                        </Button>
                    </>}/>}
                    {publishedBooks.length >0 && <PublishedBooks books={publishedBooks} onNewBook={this.openCreateProject}/>}
                </>}
                <CreateBookDialog open={createProjectModalOpen} onClose={this.closeCreateProject}/>
            </AppLayout>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        writeActions: bindActionCreators(writeActions, dispatch),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WritePage);
