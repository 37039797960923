import Fortmatic from 'fortmatic';
import ethers, {BigNumber} from "ethers";

import {APP_ENV, ETHEREUM_NETWORK, FORTMATIC_KEY, SupportedTokens} from "../Common/constants";
import {TokenProvider} from "../Utils/TokenProvider";
import {getAmountForToken} from "../Utils/Ethereum";
import * as Sentry from "@sentry/react";

class FortmaticServiceProvider {
    provider = null;
    signer = null;

    userAccount = null;
    tokenContracts = {};
    scriptarnicaContract = null;

    lastAllowance = null;
    lastTokenBalance = null

    constructor() {
        let fortmatic;

        if (ETHEREUM_NETWORK === 'mainnet') {
            fortmatic = new Fortmatic(FORTMATIC_KEY);
        } else {
            fortmatic = new Fortmatic(FORTMATIC_KEY, ETHEREUM_NETWORK);
        }

        this.provider = new ethers.providers.Web3Provider(fortmatic.getProvider());
        this.signer = this.provider.getSigner();
    }

    setMainScriptarnicaContract(address) {
        this.scriptarnicaContract = address;
    }

    /**
     * @param {SupportedTokens} token
     * @param {string} address
     */
    setupTokenContract(token, address) {
        this.tokenContracts[token] = new ethers.Contract(address, TokenProvider.abi, this.signer);
    }

    /**
     * @param {SupportedTokens} token
     */
    getTokenContract(token) {
        if (!this.tokenContracts[token]) {
            throw new Error(`No contract instantiated for ${token}`)
        }
        return this.tokenContracts[token];
    }

    /**
     * @return {Promise<string>}
     */
    async getUserAccount() {
        if (this.provider) {
            if (!this.userAccount) {
                try {
                    this.userAccount = await this.signer.getAddress();
                } catch (e) {
                    return null;
                }
            }

            return this.userAccount;
        }
    }

    /**
     * @param {SupportedTokens} token
     * @param {boolean} refresh
     *
     * @return {Promise<BigNumber>}
     */
    async getUserAllowance(token, refresh = false) {
        const account = await this.getUserAccount();

        try {
            if (refresh || !this.lastAllowance) {
                const tokenContract = this.getTokenContract(token);

                this.lastAllowance =  await tokenContract.allowance(account, this.scriptarnicaContract);
            }

            return this.lastAllowance;
        } catch (e) {
            console.log(e)

            return BigNumber.from(0);
        }
    }

    /**
     * @param {SupportedTokens} token
     * @param {BigNumber} allowance
     * @return {*}
     */
    isAllowanceMaxed(token, allowance) {
        const maxThreshold = getAmountForToken(100000, token);

        return allowance.gte(ethers.constants.MaxUint256.sub(maxThreshold));
    }

    /**
     * @param {SupportedTokens} token
     * @param {BigNumber} [checkAmount]
     * @return {Promise<boolean>}
     */
    async isAllowanceSetToMaxOrHasEnough(token, checkAmount) {
        const allowance = await this.getUserAllowance(token);

        const hasMaxAllowance = this.isAllowanceMaxed(token, allowance);

        let hasNeededAmount = false;

        if (checkAmount) {
            hasNeededAmount = allowance.gte(checkAmount);
        }

        return hasMaxAllowance || hasNeededAmount;
    }

    supportsIncreaseAllowanceMethod(token) {
        switch (token) {
            case SupportedTokens.USDT:
                return APP_ENV !== 'production';
            default:
                return false;
        }
    }

    /**
     * @param {SupportedTokens} token
     * @param {BigNumber} amount
     * @return {Promise<BigNumber|any>}
     */
    async setUserAllowance(token, amount) {
        try {
            const tokenContract = this.getTokenContract(token);

            let allowance;

            if (this.supportsIncreaseAllowanceMethod(token)) {
                allowance = await tokenContract.increaseAllowance(this.scriptarnicaContract, amount, {
                    gasLimit: 50000,
                });
            } else {
                allowance = await tokenContract.approve(this.scriptarnicaContract, amount, {
                    gasLimit: 50000,
                });
            }

            this.getUserAllowance(token, true);

            return {
                success: true,
                allowance,
            };
        } catch (e) {
            console.log(e)
            Sentry.captureException(e);

            return {
                success: false,
            };
        }
    }

    /**
     * @param {SupportedTokens} token
     * @return {Promise<BigNumber|*>}
     */
    async setUserAllowanceToMax(token) {
        return this.setUserAllowance(token, ethers.constants.MaxUint256);
    }

    /**
     * @param {SupportedTokens} token
     * @param {boolean} forceRefresh
     * @return {Promise<null|BigNumber>}
     */
    async getUserBalance(token, forceRefresh = false) {
        const account = await this.getUserAccount();

        try {
            if (!this.lastTokenBalance || forceRefresh) {
                const tokenContract = this.getTokenContract(token);

                this.lastTokenBalance = await tokenContract.balanceOf(account);
            }

            return this.lastTokenBalance;
        } catch (e) {
            console.log(e);

            return BigNumber.from(0);
        }
    }

    /**
     * @return {Uint8Array}
     */
    getRandomNonce = () => {
        return ethers.utils.randomBytes(32);
    }

    /**
     * @param {SupportedTokens} token
     * @param {BigNumber} amount
     * @param {User} user
     * @param nonce
     */
    async signDepositTransaction(token, amount, user, nonce) {
        if (!user) return;

        const account = await this.getUserAccount();
        const tokenContract = this.getTokenContract(token);

        const messageAmount = ethers.utils.hexZeroPad(ethers.BigNumber.from(amount).toHexString(), 32);

        const messageByes = [
            ...ethers.utils.arrayify(account),
            ...ethers.utils.arrayify(user.scriptarnicaWallet),
            ...ethers.utils.arrayify(tokenContract.address),
            ...ethers.utils.arrayify(messageAmount),
            ...nonce,
        ];

        return await this.signer.signMessage(messageByes);
    }
}

const FortmaticService = new FortmaticServiceProvider();

export default FortmaticService;
