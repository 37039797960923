import {FETCH_BOOK, FETCH_BOOKS, FETCH_CURRENT_READING} from "./Book.actions";
import {BUY_BOOK} from "../Account/Account.actions";

const initialState = {
    libraryFetched: false,
    library: {},
    currentlyReadingFetched: false,
    books: {},
    currentlyReading: {},
    bookContent: {},
};

const BookReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CURRENT_READING:
            const books = action.books.reduce((data, book) => {
                data[book.id] = book;

                return data;
            }, {});
            return {
                ...state,
                currentlyReadingFetched: true,
                currentlyReading: {
                    ...books,
                },
            }
        case FETCH_BOOK:
            return {
                ...state,
                books: {
                    ...state.books,
                    [action.book.id]: action.book,
                },
            }
        case FETCH_BOOKS:
            return {
                ...state,
                libraryFetched: true,
                library: action.books.reduce((data, book) => {
                    data[book.id] = book;
                    return data;
                }, {}),
            };
        case BUY_BOOK:
            const boughtBook = state.books[action.bookId];

            const updatedBook = boughtBook.update({
                purchased: true,
            });

            return {
                ...state,
                library: {
                    ...state.library,
                    [action.bookId]: updatedBook,
                },
                books: {
                    ...state.books,
                    [action.bookId]: updatedBook,
                },
            };
        default:
            return state;
    }
}

export default BookReducer;
