class PaymentMethod {
    constructor(data) {
        /** @type {string} */
        this.id = data.id;

        /** @type {string} */
        this.cardBrand = data.cardBrand;

        /** @type {string} */
        this.last4 = data.last4;
    }


    static buildFromResponse(response) {
        return new PaymentMethod({
            id: response.id,
            last4: response.last_4,
            cardBrand: response.card_brand,
        });
    }
}

export default PaymentMethod;
