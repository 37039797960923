import React from 'react';
import {Link} from "react-router-dom";

import Container from "../../Elements/Container/Container";

import ExploreImage from './images/explore.png';
import LibraryImage from './images/library.png';
import BookClubImage from './images/book-club.png';
import ChallengesImage from './images/challenges.png';

import './HomeNavigation.scss';

const HomeNavigation = () => {
    return <Container>
        <div className="HomeNavigation">
            <Link to="/explore" className="HomeNavigation__LinkWrapper">
                <div className="HomeNavigation__Link">
                    <div className="HomeNavigation__Link__Icon">
                        <img src={ExploreImage} alt="" className="HomeNavigation__Link__Icon__Image"/>
                    </div>
                    <h4 className="HomeNavigation__Link__Title">Explore</h4>
                    <div className="HomeNavigation__Link__Description">Discover your next favorite book.</div>
                </div>
            </Link>
            <Link to="/library" className="HomeNavigation__LinkWrapper">
                <div className="HomeNavigation__Link">
                    <div className="HomeNavigation__Link__Icon">
                        <img src={LibraryImage} alt="" className="HomeNavigation__Link__Icon__Image"/>
                    </div>
                    <h4 className="HomeNavigation__Link__Title">Library</h4>
                    <div className="HomeNavigation__Link__Description">Your very own books and activity.</div>
                </div>
            </Link>
            <div className="HomeNavigation__Link HomeNavigation__Link--Disabled">
                <div className="HomeNavigation__Link__Icon">
                    <img src={BookClubImage} alt="" className="HomeNavigation__Link__Icon__Image"/>
                </div>
                <h4 className="HomeNavigation__Link__Title">Book Club</h4>
                <div className="HomeNavigation__Link__Description">Start a discussion with the community.</div>
            </div>
            <div className="HomeNavigation__Link HomeNavigation__Link--Disabled">
                <div className="HomeNavigation__Link__Icon">
                    <img src={ChallengesImage} alt="" className="HomeNavigation__Link__Icon__Image"/>
                </div>
                <h4 className="HomeNavigation__Link__Title">Challenges</h4>
                <div className="HomeNavigation__Link__Description">Are you up for a challenge?</div>
            </div>
        </div>
    </Container>;
};

export default HomeNavigation;
