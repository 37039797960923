import React from 'react';
import {withRouter} from "react-router-dom";
import Container from "../../Elements/Container/Container";

import './LoginCodeHeader.scss';
import Icon from "../../Elements/Icon/Icon";

const LoginCodeHeader = ({history}) => {
    return (
        <div className="LoginCodeHeader">
            <Container>
                <div className="LoginCodeHeader__Content">
                    <div onClick={() => history.push("/login")} className="LibraryHeader__Content__Button">
                        <Icon className="LibraryHeader__Content__Button__Icon" icon="chevron-left"/>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default withRouter(LoginCodeHeader);
