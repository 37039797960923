import React, {useContext, useRef} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";
import classNames from 'classnames';
import {connect} from 'react-redux';

import {isMobileDevice} from "../../Utils/ResponsiveHelpers";
import {isLoggedIn} from "../../Selectors/AuthSelectors";
import ResponsiveContext from "../../Components/ResponsiveContainer/ResponsiveContainer";

import DesktopHeader from "../../Components/DesktopHeader/DesktopHeader";

import Container from "../Container/Container";
import './AppLayout.scss';

const AppLayout = ({pageId, fullscreen, background, loggedIn, className, children, stickyHeader, renderHeader, onContentScroll, renderFooter, tabs}) => {
    const contentRef = useRef();
    const {device} = useContext(ResponsiveContext);

    const isMobile = isMobileDevice(device);

    return (
        <div className={classNames(
            `AppLayout`,
            `AppLayout--Background__${background}`,
            className,
        )} id={pageId}>
            {loggedIn && !fullscreen && !isMobile && <DesktopHeader/>}
            <div className={`AppLayout__Content ${pageId}__Content`} ref={contentRef} onScroll={(event) => onContentScroll(contentRef, event)}>
                {!!renderHeader && <div className={classNames(
                    "AppLayout__Header",
                    {
                        "AppLayout__Header--Sticky": stickyHeader,
                    },
                )}>
                    {renderHeader()}
                </div>}
                {tabs && <Container className="AppLayout__Tabs">
                    {tabs.map(tab => <NavLink exact className="AppLayout__Tabs__Tab" key={tab.value} to={tab.to}
                                              activeClassName="AppLayout__Tabs__Tab--Active">
                        {tab.label}
                    </NavLink>)}
                </Container>}
                {children}
            </div>
            {!!renderFooter && isMobile && <div className="AppLayout__Footer">
                {renderFooter()}
            </div>}
        </div>
    );
};

AppLayout.propTypes = {
    pageId: PropTypes.string.isRequired,
    tabs: PropTypes.array,
    fullscreen: PropTypes.bool,
    renderHeader: PropTypes.func,
    renderFooter: PropTypes.func,
    stickyHeader: PropTypes.bool,
    onContentScroll: PropTypes.func,
    background: PropTypes.oneOf(['dark-gray', 'green']),
};

AppLayout.defaultProps = {
    fullscreen: false,
    onContentScroll: () => {},
};

const mapStateToProps = (state) => {
    return {
        loggedIn: isLoggedIn(state),
    };
}

export default connect(
    mapStateToProps,
    null,
)(AppLayout);
