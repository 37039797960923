import React, {createContext, PureComponent} from 'react';
import PropTypes from 'prop-types';

const FormContext = createContext({
    data: {},
    fieldErrors: {},
    onFieldUpdate: () => {},
    registerField: () => {},
});

class Form extends PureComponent {
    constructor(props) {
        super(props);

        const {initialValues} = props;

        this.state = {
            fields: [],
            values: initialValues ?? {},
        };
    }

    handleSubmit = (event) => {
        const {onSubmit} = this.props;
        const {values} = this.state;

        event.preventDefault();

        onSubmit(values, event);
    };

    /**
     * @param {string} field
     * @param {*} value
     */
    handleFieldUpdate = (field, value) => {
        const {values} = this.state;
        const {onFormUpdate} = this.props;

        const newValues = {
            ...values,
            [field]: value,
        };

        onFormUpdate(newValues);

        this.setState({
            values: newValues,
        });
    };

    /**
     * @param {string} field
     * @param {Function} validator
     */
    setFieldValidator = (field, validator) => {
        // console.log(field, validator)
    }

    registerField = (field, validator) => {
        this.setState((prevState) => {
            const {fields, values} = prevState;

            const newFields = [...fields, field];

            const initialFieldValue = values[field] ?? '';

            return {
                fields: newFields,
                values: {
                    ...values,
                    [field]: initialFieldValue,
                },
            };
        }, () => this.setFieldValidator(field, validator));
    }

    /**
     * @param {string} field
     * @param {Object} validation
     */
    handleFieldValidation = (field, validation) => {

    };

    render() {
        const {children} = this.props;
        const {values, fieldErrors} = this.state;

        return (
            <form onSubmit={this.handleSubmit}>
                <FormContext.Provider value={{
                    values,
                    fieldErrors,
                    registerField: this.registerField,
                    onFieldChange: this.handleFieldUpdate,
                    onFieldValidation: this.handleFieldValidation,
                }}>
                    {children}
                </FormContext.Provider>
            </form>
        );
    }
}

Form.propTypes = {
    onSubmit: PropTypes.func,
    onFormUpdate: PropTypes.func,
    initialValues: PropTypes.object,
};

Form.defaultProps = {
    onSubmit: () => {},
    onFormUpdate: () => {},
};

export {
    FormContext,
};

export default Form;
