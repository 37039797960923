import React from 'react';
import {connect} from "react-redux";
import raw from 'raw.macro';
import ReactMarkdown from "react-markdown";

import AppLayout from "../../Elements/AppLayout/AppLayout";
import {isLoggedIn} from "../../Selectors/AuthSelectors";
import MainFooter from "../../Components/MainFooter/MainFooter";

import Container from "../../Elements/Container/Container";

import './TermsOfServicePage.scss';

const TOS = raw('./TERMS_OF_SERVICE.md');

const TermsOfServicePage = ({isLoggedIn}) => {
    return (
        <AppLayout pageId="TermsOfServicePage" renderFooter={() => {
            if (!isLoggedIn) return null;

            return <MainFooter/>;
        }}>
            <Container className="TermsOfServicePage__Container">
                <ReactMarkdown source={TOS}/>
            </Container>
        </AppLayout>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: isLoggedIn(state),
    };
};

export default connect(
    mapStateToProps,
)(TermsOfServicePage);
