import React, {Component} from 'react';
import {connect} from 'react-redux';
import FortmaticService from "../../Services/FortmaticService";
import {getUser} from "../../Selectors/AuthSelectors";
import {bindActionCreators} from "redux";
import {accountActions} from "../../Core/actions";
import Dialog from "../../Elements/Dialog/Dialog";
import AddTokensProviderStep from "./AddTokensProviderStep";

import './AddTokensModal.scss';
import AddTokensDepositStep from "./AddTokensDepositStep";
import AddTokensSwapStep from "./AddTokensSwapStep";
import AddTokensAllowanceStep from "./AddTokensAllowanceStep";
import {SupportedTokens} from "../../Common/constants";
import AddTokensTypeSelect from "./AddTokensTypeSelect";
import AddTokensStripeStep from "./AddTokensStripeStep";
import AddTokensStripeAmountStep from "./AddTokensStripeAmountStep";
import AddTokensRemovePaymentStep from "./AddTokensRemovePaymentStep";

const ModalSteps = {
    TYPE: 'type',
    PROVIDER: 'provider',
    DEPOSIT: 'deposit',
    SWAP: 'swap',
    ALLOWANCE: 'allowance',
    STRIPE_AMOUNT: 'stripe_amount',
    REMOVE_PM: 'remove_payment',
    STRIPE: 'stripe',
};

class AddTokensModal extends Component {
    state = {
        currentStep: ModalSteps.TYPE,
        actionInProgress: false,
        provider: null,
        wallet: null,
        swapAmount: null,
        error: null,
        selectedPaymentMethod: null,
    };

    handleAfterClose = () => {
        this.setState({
            currentStep: ModalSteps.TYPE,
            actionInProgress: false,
            provider: null,
            wallet: null,
            swapAmount: null,
            error: null,
            selectedPaymentMethod: null,
        });
    };

    handleTypeSelect = (type) => {
        this.setState({
            swapAmount: null,
        });

        if (type === "stripe") {
            this.goToStep(ModalSteps.STRIPE_AMOUNT);
        } else if (type === "crypto") {
            this.goToStep(ModalSteps.PROVIDER);
        }
    };

    handleStripeSetAmount = (amount) => {
        this.setState({
            swapAmount: amount,
            currentStep: ModalSteps.STRIPE,
        });
    }

    handleProviderSelect = async (provider) => {
        let wallet;

        this.setState({
            actionInProgress: true,
        });

        switch (provider) {
            case 'fortmatic':
                wallet = await FortmaticService.getUserAccount();
                break;
            default:
                break;
        }

        if (wallet) {
            this.setState({
                currentStep: ModalSteps.DEPOSIT,
                wallet,
                provider,
            });
        }

        this.setState({
            actionInProgress: false,
        });
    }

    handleUserDeposited = () => {
        this.setState({
            currentStep: ModalSteps.SWAP,
        });
    };

    swapTokensAndDeposit = async () => {
        const {accountActions, onClose} = this.props;
        const {swapAmount} = this.state;

        this.setState({
            actionInProgress: true,
        });

        const deposited = await accountActions.depositFunds(SupportedTokens.USDT, swapAmount);

        if (deposited) {
            onClose();
        } else {
            this.setState({
                actionInProgress: false,
            });
        }
    };

    /**
     * @param {BigNumber} amount
     */
    handleUserSwapTokens = (amount) => {
        this.setState({
            swapAmount: amount,
        }, async () => {
            const maxAllowance = await FortmaticService.isAllowanceSetToMaxOrHasEnough(SupportedTokens.USDT, amount);

            if (maxAllowance) {
                this.swapTokensAndDeposit();
            } else {
                this.setState({
                    currentStep: ModalSteps.ALLOWANCE,
                });
            }
        });
    };

    /**
     * @param {'once', 'max'} option
     */
    handleAllowanceOptionSelect = async (option) => {
        const {swapAmount} = this.state;

        this.setState({
            actionInProgress: true,
            error: null,
        });

        let response;

        switch (option) {
            case "max":
                response = await FortmaticService.setUserAllowanceToMax(SupportedTokens.USDT);
                break;
            case "once":
                response = await FortmaticService.setUserAllowance(SupportedTokens.USDT, swapAmount);
                break;
            default:
                break;
        }

        if (!response.success) {
            this.setState({
                actionInProgress: false,
                error: "There was an error trying to perform this action",
            });

            return false;
        }

        await this.swapTokensAndDeposit();
    };

    goToStep = (step) => {
        this.setState({
            currentStep: step,
        });
    }

    /**
     * @param {PaymentMethod} pm
     */
    handlePaymentMethodSelect = async (pm) => {
        const {swapAmount} = this.state;
        const {accountActions, onClose} = this.props;

        this.setState({
            actionInProgress: true,
        });

        const response = await accountActions.chargePaymentMethod(pm, swapAmount);

        this.setState({
            actionInProgress: false,
        });

        if (response) {
            onClose();
        }
    };

    /**
     * @param {PaymentMethod} pm
     */
    handleRemovePaymentMethod = (pm) => {
        this.setState({
            selectedPaymentMethod: pm,
        }, () => this.goToStep(ModalSteps.REMOVE_PM));
    }

    handleDeletePaymentMethod = async () => {
        const {accountActions} = this.props;
        const {selectedPaymentMethod} = this.state;

        if (!selectedPaymentMethod) return;

        const response = await accountActions.deletePaymentMethod(selectedPaymentMethod.id);

        if (response) {
            this.goToStep(ModalSteps.STRIPE);
        }
    }

    render() {
        const {open, onClose} = this.props;
        const {currentStep, actionInProgress, wallet, swapAmount, error, selectedPaymentMethod} = this.state;

        return (
            <Dialog className="AddTokensModal" open={open} onClose={onClose} onAfterClose={this.handleAfterClose}>
                {!!error && <div className="WarningText SemiBoldText">
                    {error}
                </div>}
                {actionInProgress && <div className="AddTokensModal__ProgressOverlay"/>}
                {currentStep === ModalSteps.TYPE && <AddTokensTypeSelect onSelect={this.handleTypeSelect}/>}
                {currentStep === ModalSteps.STRIPE_AMOUNT && <AddTokensStripeAmountStep onSetAmount={this.handleStripeSetAmount} onBack={() => this.goToStep(ModalSteps.TYPE)}/>}
                {currentStep === ModalSteps.STRIPE && <AddTokensStripeStep onBack={() => this.goToStep(ModalSteps.STRIPE_AMOUNT)}
                                                                           onDeletePaymentMethod={this.handleRemovePaymentMethod}
                                                                           onSelectPaymentMethod={this.handlePaymentMethodSelect}/>}
                {currentStep === ModalSteps.REMOVE_PM && <AddTokensRemovePaymentStep pm={selectedPaymentMethod} onConfirm={this.handleDeletePaymentMethod} onBack={() => this.goToStep(ModalSteps.STRIPE)}/>}
                {currentStep === ModalSteps.PROVIDER && <AddTokensProviderStep onSelect={this.handleProviderSelect}/>}
                {currentStep === ModalSteps.DEPOSIT && <AddTokensDepositStep onDeposited={this.handleUserDeposited} wallet={wallet}/>}
                {currentStep === ModalSteps.SWAP && <AddTokensSwapStep onSwapConfirm={this.handleUserSwapTokens}/>}
                {currentStep === ModalSteps.ALLOWANCE && <AddTokensAllowanceStep amount={swapAmount} onSetAllowance={this.handleAllowanceOptionSelect}/>}
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: getUser(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        accountActions: bindActionCreators(accountActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddTokensModal);
