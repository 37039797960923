import React from 'react';
import classNames from 'classnames';
import {withRouter} from "react-router-dom";

import './ReadingNavigation.scss';
import Icon from "../../Elements/Icon/Icon";
import Container from "../../Elements/Container/Container";

const ReadingNavigation = ({visible, book, history, onMenuOpen}) => {
    return (
        <div className={classNames("ReadingNavigation", {
            "ReadingNavigation--Visible": visible,
        })}>
            <Container>
                <div className="ReadingNavigation__Content">
                    <div onClick={() => history.push(`/book/${book.id}`)} className="ReadingNavigation__Content__Button">
                        <Icon className="ReadingNavigation__Content__Button__Icon" icon="chevron-left"/>
                    </div>
                    <div className="ReadingNavigation__Content__Button" onClick={onMenuOpen}>
                        <Icon className="ReadingNavigation__Content__Button__Icon" icon="more-horizontal"/>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default withRouter(ReadingNavigation);
