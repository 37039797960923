import React from 'react';
import {components} from 'react-select';

import Icon from "../../Elements/Icon/Icon";

import './CategorySelectComponents.scss';

const CategorySelectComponent = ({category}) => {
    return <div className="CategorySelectComponent">
        <div className="CategorySelectComponent__IconWrapper">
            <Icon icon={category.icon} className="CategorySelectComponent__Icon"/>
        </div>
        <div className="CategorySelectComponent__Label">{category.label}</div>
    </div>
};

const CategorySelectOption = (props) => {
    const category = props.data;

    return (
        <components.Option {...props} className="CategorySelectOption">
            <CategorySelectComponent category={category}/>
        </components.Option>
    );
}

const CategorySelectValue = (props) => {
    const category = props.data;

    return (
        <components.SingleValue {...props} className="CategorySelectValue">
            <CategorySelectComponent category={category}/>
        </components.SingleValue>
    );
};

export {
    CategorySelectValue,
    CategorySelectOption,
};
