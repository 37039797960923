import React, {Fragment, PureComponent} from 'react';
import classNames from 'classnames';

import './ReadingSettings.scss';
import Container from "../../Elements/Container/Container";
import Icon from "../../Elements/Icon/Icon";
import {AppSpacingTypes} from "../../Common/constants";
import AppSettingsService from "../../Services/AppSettingsService";

const ReadingSettingsModeTypes = {
    MAIN: 'main',
    TYPOGRAPHY: 'typography',
    GENERAL: 'general',
}

class ReadingSettings extends PureComponent {
    state = {
        mode: ReadingSettingsModeTypes.MAIN,
    };

    changeSettingsMode = (mode) => {
        this.setState({
            mode,
        });
    }

    render() {
        const {visible, onAction, appSettings} = this.props;
        const {mode} = this.state;

        return (
            <div className={classNames("ReadingSettings", {
                "ReadingSettings--Visible": visible,
            })}>
                <Container>
                    <div className="ReadingSettings__Wrapper">
                        <div className="ReadingSettings__ControlPanel">
                            {mode === ReadingSettingsModeTypes.MAIN && <Fragment>
                                <div className="ReadingSettings__ControlPanel__Actions">
                                    <div className="ReadingSettings__ControlPanel__Action"
                                         onClick={() => this.changeSettingsMode(ReadingSettingsModeTypes.TYPOGRAPHY)}>
                                        <Icon icon="font"/>
                                    </div>
                                    <div className="ReadingSettings__ControlPanel__Action"
                                         onClick={() => onAction('change_theme')}>
                                        <Icon icon="color-picker"/>
                                    </div>
                                    <div className="ReadingSettings__ControlPanel__Action"
                                         onClick={() => this.changeSettingsMode(ReadingSettingsModeTypes.GENERAL)}>
                                        <Icon icon="settings"/>
                                    </div>
                                </div>
                                <div className="ReadingSettings__ControlPanel__ModeToggle">
                                    <div className="ReadingSettings__ControlPanel__ModeToggle__Button ReadingSettings__ControlPanel__ModeToggle__Button--Active">
                                        <Icon icon="book-open" className="ReadingSettings__ControlPanel__ModeToggle__Button__Icon"/>
                                    </div>
                                    <div className="ReadingSettings__ControlPanel__ModeToggle__Button">
                                        <Icon icon="message-square" className="ReadingSettings__ControlPanel__ModeToggle__Button__Icon"/>
                                    </div>
                                </div>
                            </Fragment>}
                            {mode === ReadingSettingsModeTypes.TYPOGRAPHY && <Fragment>
                                <div className="ReadingSettings__ControlPanel__ToggleState" onClick={() => onAction('change_font_family')}>
                                    <div className="ReadingSettings__ControlPanel__ToggleState__Label">{AppSettingsService.getFontFamilyLabel(appSettings.fontFamily)}</div>
                                    <div className="ReadingSettings__ControlPanel__ToggleState__Dots">
                                        {AppSettingsService.getFontFamilyOptions().map(ff =>
                                            <div key={ff}
                                                 className={classNames("ReadingSettings__ControlPanel__ToggleState__Dot", {
                                                     "ReadingSettings__ControlPanel__ToggleState__Dot--Active": ff === appSettings.fontFamily,
                                                 })}/>
                                        )}
                                    </div>
                                </div>
                                <div className="ReadingSettings__ControlPanel__Buttons">
                                    <div className="ReadingSettings__ControlPanel__Buttons__Button" onClick={() => onAction('change_font_size', -1)}>
                                        <Icon icon="minus"/>
                                    </div>
                                    <div className="ReadingSettings__ControlPanel__Buttons__Button" onClick={() => onAction('change_font_size', 1)}>
                                        <Icon icon="plus"/>
                                    </div>
                                </div>
                            </Fragment>}
                            {mode === ReadingSettingsModeTypes.GENERAL && <Fragment>
                                <div className="ReadingSettings__ControlPanel__Actions">
                                    <div className={classNames("ReadingSettings__ControlPanel__Action", {
                                        "ReadingSettings__ControlPanel__Action--Active": appSettings.spacing === AppSpacingTypes.CONDENSED,
                                    })}
                                         onClick={() => onAction('change_spacing', AppSpacingTypes.CONDENSED)}>
                                        <Icon icon="line-height-less"/>
                                    </div>
                                    <div className={classNames("ReadingSettings__ControlPanel__Action", {
                                        "ReadingSettings__ControlPanel__Action--Active": appSettings.spacing === AppSpacingTypes.NORMAL,
                                    })}
                                         onClick={() => onAction('change_spacing', AppSpacingTypes.NORMAL)}>
                                        <Icon icon="line-height-medium"/>
                                    </div>
                                    <div className={classNames("ReadingSettings__ControlPanel__Action", {
                                        "ReadingSettings__ControlPanel__Action--Active": appSettings.spacing === AppSpacingTypes.SPREAD,
                                    })}
                                         onClick={() => onAction('change_spacing', AppSpacingTypes.SPREAD)}>
                                        <Icon icon="line-height-more"/>
                                    </div>
                                </div>
                                <div className="ReadingSettings__ControlPanel__Divider"/>
                                <div className="ReadingSettings__ControlPanel__ToggleLabel">Scroll</div>
                                <div className="ReadingSettings__ControlPanel__ModeToggle">
                                    <div className="ReadingSettings__ControlPanel__ModeToggle__Button ReadingSettings__ControlPanel__ModeToggle__Button--Active">
                                        <span>OFF</span>
                                    </div>
                                    <div className="ReadingSettings__ControlPanel__ModeToggle__Button">
                                        <span>ON</span>
                                    </div>
                                </div>
                            </Fragment>}
                        </div>
                        {mode !== ReadingSettingsModeTypes.MAIN && <div className="ReadingSettings__BackButton"
                                                                        onClick={() => this.changeSettingsMode(ReadingSettingsModeTypes.MAIN)}>
                            <Icon icon="x"/>
                        </div>}
                    </div>
                </Container>
            </div>
        );

    }
}

export default ReadingSettings;
