import React from 'react';

import './ButtonGroup.scss';

const ButtonGroup = ({children}) => {
    return (
        <div className="ButtonGroup">
            {children}
        </div>
    );
};

export default ButtonGroup;
