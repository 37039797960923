import Api from "../Utils/ApiProvider";

class IpfsServiceProvider {
    async readFolderStructure(path) {
        if (!path) {
            throw new Error('No IPFS path specified');
        }

        try {
            console.log(path);
        } catch (e) {

        }
    }

    /**
     * @param {Book} book
     * @return {Promise<string|any>}
     */
    async readFileContent(book) {
        if (!book) {
            throw new Error('No IPFS book specified');
        }

        try {
            const {data} = await Api.get(`/api/v1/user/book/${book.id}/path/${book.path}`);

            return data;
        } catch (e) {
            return '';
        }
    }
}

const IpfsService = new IpfsServiceProvider();

export default IpfsService;
