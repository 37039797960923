import React from 'react';
import BookLoader from "../BookLoader/BookLoader";

import './SearchingEmptyState.scss';

const SearchingEmptyState = () => {
    return (
        <div className="SearchingEmptyState">
            <BookLoader dark/>
        </div>
    );
};

export default SearchingEmptyState;
