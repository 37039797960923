import React from 'react';

import Container from "../../Elements/Container/Container";

import FeaturedImage from "./check-email-empty-state.svg";

import './CheckEmailEmptyState.scss';

const CheckEmailEmptyState = () => {
    return (
        <div className="CheckEmailEmptyState">
            <div className="CheckEmailEmptyState__FeaturedImage">
                <img className="CheckEmailEmptyState__FeaturedImage__Img" src={FeaturedImage} alt=""/>
            </div>
            <div className="CheckEmailEmptyState__Content">
                <Container>
                    <h1 className="CheckEmailEmptyState__Content__Heading">Check Your Email</h1>
                    <p className="CheckEmailEmptyState__Content__Description">We have sent an e-mail that contains a link. Click on the link in order to login</p>
                </Container>
            </div>
        </div>
    );
};

export default CheckEmailEmptyState;
