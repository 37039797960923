import React, {Component} from 'react';
import {connect} from 'react-redux';
import AppLayout from "../../Elements/AppLayout/AppLayout";
import PublishingEditor from "../../Components/PublishingEditor/PublishingEditor";
import PublishingInformation from "../../Components/PublishingInformation/PublishingInformation";
import {bindActionCreators} from "redux";
import {writeActions} from "../../Core/actions";
import ethers from "ethers";
import PublishingConfirmation from "../../Components/PublishingConfirmation/PublishingConfirmation";
import PublishingLoader from "../../Components/PublishingLoader/PublishingLoader";

class PublishPage extends Component {
    constructor(props) {
        super(props);

        const {location: {state}} = props;

        const initialText = state?.content ?? '';

        this.state = {
            initialText,
            content: '',
            mode: "editor",
            publishing: false,
        };
    }

    handleContentSubmit = (content) => {
        this.setState({
            content,
            mode: 'information',
        });
    };

    handleInformationSubmit = async (data) => {
        const {content} = this.state;
        const {writeActions} = this.props;

        this.setState({
            publishing: true,
        });

        const bookData = {
            title: data.title,
            type: "Book",
            author: data.author,
            genre: data.category.slug,
            cover_photo: "",
            description: data.description,
            language: data.language.name,
            country: data.country.name,
            price: ethers.utils.parseUnits(data.price, 18).toHexString().replace('0x0', '0x'),
            parts: content,
        };

        const response = await writeActions.publishBook(bookData, data.coverImage ?? null);

        this.setState({
            publishing: false,
        });

        if (response.success) {
            this.setState({
                mode: 'published',
                publishedBook: response.book,
            });
        }
    };

    render() {
        const {initialText, mode, publishedBook, publishing} = this.state;

        return (
            <AppLayout pageId="PublishPage" background={mode === 'published' ? 'dark-gray' : null}>
                {publishing && <PublishingLoader/>}
                {mode === 'editor' && <PublishingEditor initialText={initialText} onSubmit={this.handleContentSubmit}/>}
                {mode === 'information' && <PublishingInformation onSubmit={this.handleInformationSubmit}/>}
                {mode === 'published' && <PublishingConfirmation book={publishedBook}/>}
            </AppLayout>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        writeActions: bindActionCreators(writeActions, dispatch),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PublishPage);
