import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {getAmountForToken, getFormattedScrptPrice} from "../../Utils/Ethereum";

import Icon from "../../Elements/Icon/Icon";

import './ScriptBalance.scss';
import {SupportedTokens} from "../../Common/constants";
import {BigNumber} from "ethers";

class ScriptBalance extends Component {
    render() {
        const {balance, onAction} = this.props;

        const displayBalance = getFormattedScrptPrice(balance?.toString() ?? BigNumber.from(0), SupportedTokens.SCRPT);

        return (
            <div className="ScriptBalance">
                <div className="ScriptBalance__BalanceInfo">
                    <div className="ScriptBalance__BalanceInfo__TokenLabel"><Icon icon="scrpt-token"/> SCRPT</div>
                    <div className="ScriptBalance__BalanceInfo__Balance">
                        <span>{displayBalance}</span>
                    </div>
                    {balance?.eq(0) && <div className="ScriptBalance__BalanceInfo__Description">You </div>}
                    {!balance?.eq(0) && <div className="ScriptBalance__BalanceInfo__Description">You can read ~ {balance?.div(getAmountForToken(10, SupportedTokens.SCRPT)).toString()} books</div>}
                </div>
                <div className="ScriptBalance__Actions">
                    <div className="ScriptBalance__Actions__Button" onClick={() => onAction('add-more')}>
                        <div className="ScriptBalance__Actions__Button__Icon ScriptBalance__Actions__Button__Icon--Blue">
                            <Icon icon="plus-circle"/>
                        </div>
                        <div className="ScriptBalance__Actions__Button__Label">Add</div>
                    </div>
                    <div className="ScriptBalance__Actions__Button" onClick={() => onAction('withdraw')}>
                        <div className="ScriptBalance__Actions__Button__Icon">
                            <Icon icon="minus-circle"/>
                        </div>
                        <div className="ScriptBalance__Actions__Button__Label">Withdraw</div>
                    </div>
                    <div className="ScriptBalance__Actions__Button" onClick={() => onAction('set-allowance')}>
                        <div className="ScriptBalance__Actions__Button__Icon">
                            <Icon icon="database"/>
                        </div>
                        <div className="ScriptBalance__Actions__Button__Label">Allowance</div>
                    </div>
                    <div className={classNames("ScriptBalance__Actions__Button", {
                        "ScriptBalance__Actions__Button--Disabled": true,
                    })} onClick={() => onAction('activity')}>
                        <div className="ScriptBalance__Actions__Button__Icon">
                            <Icon icon="bar-chart-2"/>
                        </div>
                        <div className="ScriptBalance__Actions__Button__Label">Activity</div>
                    </div>
                </div>
            </div>
        );
    }
}

ScriptBalance.propTypes = {
    loading: PropTypes.bool,
    balance: PropTypes.any.isRequired,
    onAction: PropTypes.func.isRequired,
};

export default ScriptBalance;
