import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";

import {authActions} from "../../Core/actions";

import {isLoggedIn} from "../../Selectors/AuthSelectors";

import AppLayout from "../../Elements/AppLayout/AppLayout";

class Auth0LoginPage extends Component {
    state = {
        error: null,
        errorMessage: null,
    };

    async componentDidMount() {
        const {authActions, loggedIn, location: {hash}} = this.props;

        if (hash && !loggedIn) {
            const response = await authActions.parseAuth0HashAndLogin(hash);

            if (response.error) {
                this.setState({
                    error: response.error,
                    errorMessage: response.errorDescription,
                });
            }
        }
    }

    render() {
        const {loggedIn} = this.props;
        const {error, errorMessage} = this.state;

        if (loggedIn) {
            return <Redirect to="/home"/>
        }

        return (
            <AppLayout pageId="Auth0LoginPage">
                {!!error && <div>
                    <div>
                        <h3>{error}</h3>
                        <p>{errorMessage}</p>
                    </div>
                    <Link to="/login">Back to login</Link>
                </div>}
            </AppLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: isLoggedIn(state),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        authActions: bindActionCreators(authActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Auth0LoginPage);
