import LocalStorage from "../../Utils/LocalStorage";
import {LocalStorageKeys, SupportedTokens} from "../../Common/constants";
import Api from "../../Utils/ApiProvider";
import {isLoggedIn} from "../../Selectors/AuthSelectors";
import FortmaticService from "../../Services/FortmaticService";

export const SET_INTRODUCTION_FINISHED = 'SET_INTRODUCTION_FINISHED_ACTION';
export const GET_CONFIG = 'GET_CONFIG_ACTION';

/**
 * @param {boolean} value
 */
export const setIntroductionFinished = (value) => {
    return dispatch => {
        LocalStorage.setItem(LocalStorageKeys.INTRODUCTION_PASSED, value);

        dispatch({
            type: SET_INTRODUCTION_FINISHED,
            introductionFinished: value,
        });
    };
}

export const getConfig = () => {
    return async (dispatch, getState) => {
        const state = getState();

        const loggedIn = isLoggedIn(state);

        if (!loggedIn) return;

        const {data} = await Api.get('/api/v1/config');

        if (data.manager) {
            FortmaticService.setMainScriptarnicaContract(data.manager);
        }

        if (data.main_token) {
            FortmaticService.setupTokenContract(SupportedTokens.USDT, data.main_token);
        }

        dispatch({
            type: GET_CONFIG,
            config: data,
        });
    }
};
