import React, {Component} from "react";
import {getDeviceForWidth} from "../../Utils/ResponsiveHelpers";

const ResponsiveContext = React.createContext({
    width: 0,
    device: null,
});

class ResponsiveContainer extends Component {
    constructor(props) {
        super(props);

        const width = window.innerWidth;

        this.state = {
            width,
            device: getDeviceForWidth(width),
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        const width = window.innerWidth;

        this.setState({
            width,
            device: getDeviceForWidth(width),
        });
    };

    render() {
        const {children} = this.props;
        const {width, device} = this.state;

        return (
            <ResponsiveContext.Provider value={{
                width,
                device,
            }}>
                {children}
            </ResponsiveContext.Provider>
        );
    }
}

export default ResponsiveContext;

export {ResponsiveContainer};
