import React, {Component} from 'react';

class OnboardingPage extends Component {
    render() {
        return (
            <div>

            </div>
        );
    }
}

export default OnboardingPage;
