import React from 'react';
import PropTypes from 'prop-types';

import './ReadingFooter.scss';

const ReadingFooter = ({progress}) => {
    return (
        <div className="ReadingFooter">
            <div className="ReadingFooter__Progress">
                <div className="ReadingFooter__Progress__Bar" style={{
                    width: `${progress ?? 0}%`,
                }}/>
            </div>
        </div>
    );
};

ReadingFooter.propTypes = {
    progress: PropTypes.number.isRequired,
};

export default ReadingFooter;
