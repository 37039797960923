import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";

import {bookActions} from "../../Core/actions";

import {getCurrentlyReadingBooks, isCurrentlyReadingLoaded} from "../../Selectors/BookSelectors";
import Icon from "../../Elements/Icon/Icon";

import './ContinueReading.scss';

/**
 * @param {Book} book
 */
const BookProgressPreview = ({book}) => {
    return <Link className="BookProgressPreview" to={`/book/${book.id}`}>
        <img src={book.coverUrl} alt={book.title} className="BookProgressPreview__Cover"/>
        <div className="BookProgressPreview__Info">
            <div className="BookProgressPreview__Info__Resume">Resume reading</div>
            <div className="BookProgressPreview__Info__Title">{book.title}</div>
        </div>
    </Link>
};

class ContinueReading extends Component {
    state = {
        currentBook: null,
        currentIndex: 0,
    };

    async componentDidMount() {
        const {bookActions, booksLoaded} = this.props;

        if (!booksLoaded) {
            const books = await bookActions.fetchCurrentlyReadingBooks();

            if (books?.length > 0) {
                this.setState({
                    currentBook: books[0],
                    currentIndex: 0,
                });
            }
        }
    }

    /**
     * @param {Swiper} swiper
     */
    handleSlideChange = (swiper) => {
        const {books} = this.props;

        this.setState({
            currentBook: books[swiper.realIndex],
            currentIndex: swiper.realIndex,
        });
    }

    render() {
        const {books, booksLoaded} = this.props;
        const {currentBook, currentIndex} = this.state;

        if (booksLoaded && !books?.length) {
            return null;
        }

        return (
            <div className="ContinueReading">
                <div className="ContinueReading__Content">
                    {booksLoaded && <Swiper className="ContinueReading__Content__Swiper"
                                            onSlideChange={this.handleSlideChange}
                                            slidesPerView={1}>
                        {books.map(book => <SwiperSlide key={book.id}>
                            <BookProgressPreview book={book}/>
                        </SwiperSlide>)}
                    </Swiper>}
                    {currentBook && <div className="ContinueReading__Content__CurrentProgress">
                        <div style={{width: `${currentBook.currentProgress}%`}} className="ContinueReading__Content__CurrentProgress__Bar"/>
                    </div>}
                    {currentIndex < (books.length - 1) && <div className="ContinueReading__Content__SliderIcon">
                        <Icon icon="chevrons-right"/>
                    </div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        books: getCurrentlyReadingBooks(state),
        booksLoaded: isCurrentlyReadingLoaded(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        bookActions: bindActionCreators(bookActions, dispatch),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ContinueReading);
