import React from 'react';
import Button from "../../Elements/Button/Button";

import './PublishingConfirmation.scss';

const PublishingConfirmation = ({book}) => {
    return (
        <div className="PublishingConfirmation">
            <h2 className="PublishingConfirmation__Heading">Your book has been successfully published!</h2>
            <img className="PublishingConfirmation__Cover" src={book?.coverUrl} alt=""/>
            <Button color="turquoise" size="large" to={book ? `/book/${book.id}` : `/write`}>
                <span>Go to Book</span>
            </Button>
        </div>
    );
};

export default PublishingConfirmation;
