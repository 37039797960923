import {AUTH0_MAGIC_LINK_USER, CHECK_SESSION_AND_LOGIN_USER, LOGIN_USER} from "./Auth.actions";

const initialState = {
    loggedIn: false,
};

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
            };
        case AUTH0_MAGIC_LINK_USER:
        case CHECK_SESSION_AND_LOGIN_USER:
            return {
                ...state,
                loggedIn: true,
                user: action.user,
            };
        default:
            return state;
    }
}

export default AuthReducer;
