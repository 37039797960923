import React from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';

import './Dialog.scss';
import Icon from "../Icon/Icon";

Modal.setAppElement('#root');

const Dialog = ({open = false, onClose, children, onAfterOpen, onAfterClose, noPadding = false, fullPage = false, overlayClose = true, escapeClose = true, className}) => {
    return (
        <Modal isOpen={open}
               onRequestClose={onClose}
               closeTimeoutMS={200}
               overlayClassName="DialogOverlay"
               className={classNames(
                   "DialogContent",
                   {
                       "DialogContent--FullPage": fullPage,
                       "DialogContent--NoPadding": noPadding,
                   },
                   className,
               )}
               onAfterOpen={onAfterOpen}
               onAfterClose={onAfterClose}
               bodyOpenClassName="DialogOpened"
               shouldCloseOnOverlayClick={overlayClose}
               shouldCloseOnEsc={escapeClose}>
            <div className="DialogContent__CloseButton" onClick={onClose}>
                <Icon icon="x"/>
            </div>
            {children}
        </Modal>
    )
};

export default Dialog;
