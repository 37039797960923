import React from 'react';
import Icon from "../../Elements/Icon/Icon";

const Rating = ({rating, votes}) => {
    return (
        <div>
            <div>
                <Icon icon="star"/>
                <Icon icon="star"/>
                <Icon icon="star"/>
                <Icon icon="star"/>
                <Icon icon="star"/>
            </div>
            {!!votes && <div>({votes})</div>}
        </div>
    );
};

export default Rating;
