import React from 'react';
import Rating from "../Rating/Rating";
import {getFormattedScrptPrice} from "../../Utils/Ethereum";
import Container from "../../Elements/Container/Container";

import './SearchResults.scss';
import {SupportedTokens} from "../../Common/constants";
import BookCover from "../BookCover/BookCover";

/**
 * @param {Book[]} results
 * @param {Function} onBookSelect
 */
const SearchResults = ({label, results, onBookSelect}) => {
    return (
        <div className="SearchResults">
            <Container>
                <div className="SearchResults__Label">{label}</div>
                <div className="SearchResults__Results">
                    {results.map(book => <div onClick={() => onBookSelect(book)} key={book.id} className="SearchResults__Result">
                        <div className="SearchResults__Result__Cover">
                            <BookCover book={book} className="SearchResults__Result__Cover__Image"/>
                        </div>
                        <div className="SearchResults__Result__Info">
                            <Rating rating={book.rating} votes={book.votes}/>
                            <div className="SearchResults__Result__Info__Title">{book.title}</div>
                            <div className="SearchResults__Result__Info__Author">{book.author}</div>
                            <div className="SearchResults__Result__Info__Price">
                                <span className="SearchResults__Result__Info__Price__Tokens">{getFormattedScrptPrice(book.price, SupportedTokens.SCRPT)} SCRIPT</span>
                                <span> / entire book</span>
                            </div>
                        </div>
                    </div>)}
                </div>
            </Container>
        </div>
    );
};

export default SearchResults;
