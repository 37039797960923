import React, {Component} from 'react';
import classNames from 'classnames';

import Form from "../../Elements/Form/Form";
import FormInput from "../../Elements/Form/FormInput";
import {CategoriesData, CountriesData, FormInputTypes, LanguagesData} from "../../Common/constants";
import FormButton from "../../Elements/Form/FormButton";

import './PublishingInformation.scss';
import {CategorySelectOption, CategorySelectValue} from "../FormComponents/CategorySelectComponents";
import {
    CountrySelectOption,
    CountrySelectValue,
    LanguageSelectOption,
    LanguageSelectValue
} from "../FormComponents/CountrySelectComponents";

class PublishingInformation extends Component {
    state = {
        step: 'details',
        categoryOptions: Object.values(CategoriesData),
        details: {},
        localization: {},
        pricing: {},
        allocation: {},
    }

    setStep = (step) => {
        this.setState({
            step,
        });
    }

    handleFormDataUpdate = (form, data) => {
        this.setState({
            [form]: data,
        });
    };

    handleDetailsSubmit = (data) => {
        this.handleFormDataUpdate('details', data);
        this.setState({
            step: 'localization',
        });
    };

    handleLocalizationSubmit = (data) => {
        this.handleFormDataUpdate('localization', data);
        this.setState({
            step: 'pricing',
        });
    };

    handlePriceSubmit = (data) => {
        this.handleFormDataUpdate('pricing', data);
        this.setState({
            step: 'allocation',
        });
    };

    handleAllocationSubmit = (data) => {
        this.handleFormDataUpdate('allocation', data);
        this.handleBookPublish();
    };

    handleBookPublish = () => {
        const {details, localization, pricing, allocation} = this.state;
        const {onSubmit} = this.props;

        onSubmit({
            ...details,
            ...localization,
            ...pricing,
            ...allocation,
        });
    }

    render() {
        const {step, categoryOptions, details, localization, pricing, allocation} = this.state;

        return (
            <div className="PublishingInformation">
                <div className="PublishingInformation__Container">
                    <h2 className="PublishingInformation__Heading">Book Information</h2>
                    <div className="PublishingInformation__Tabs">
                        <div className={classNames("PublishingInformation__Tab", {
                            'PublishingInformation__Tab--Active': step === 'details',
                        })} onClick={() => this.setStep('details')}>
                            <span>01 Book Details</span>
                        </div>
                        <div className={classNames("PublishingInformation__Tab", {
                            'PublishingInformation__Tab--Active': step === 'localization',
                        })} onClick={() => this.setStep('localization')}>
                            <span>02 Localization</span>
                        </div>
                        <div className={classNames("PublishingInformation__Tab", {
                            'PublishingInformation__Tab--Active': step === 'pricing',
                        })} onClick={() => this.setStep('pricing')}>
                            <span>03 Pricing</span>
                        </div>
                        <div className={classNames("PublishingInformation__Tab", {
                            'PublishingInformation__Tab--Active': step === 'allocation',
                        })} onClick={() => this.setStep('allocation')}>
                            <span>04 Profit Allocation</span>
                        </div>
                    </div>
                    {step === 'details' && <div className="PublishingInformation__FormWrapper">
                        <Form onFormUpdate={(data) => this.handleFormDataUpdate('details', data)} onSubmit={this.handleDetailsSubmit} initialValues={{
                            category: categoryOptions[0],
                            ...details,
                        }}>
                            <FormInput field="title" label="Book Title" placeholder="Make it memorable…"/>
                            <FormInput field="author" label="Book Author" placeholder="Genious behind it…"/>
                            <FormInput field="description" type={FormInputTypes.TEXTAREA} label="Book Description" placeholder="What can readers expect…"/>
                            <FormInput field="category" getOptionValue={option => option.slug} options={categoryOptions} selectComponents={{
                                Option: CategorySelectOption,
                                SingleValue: CategorySelectValue,
                            }} type={FormInputTypes.SELECT} label="Book Category"/>
                            <FormInput field="coverImage" label="Book Cover" type={FormInputTypes.UPLOAD} placeholder="Upload or drag & drop your book cover" previewDimensions={{
                                width: 160,
                                height: 240,
                            }}/>
                            <div>
                                <FormButton size="large">
                                    <span className="SemiBoldText">Continue</span>
                                </FormButton>
                            </div>
                        </Form>
                    </div>}
                    {step === 'localization' && <div className="PublishingInformation__FormWrapper">
                        <Form onFormUpdate={(data) => this.handleFormDataUpdate('localization', data)} onSubmit={this.handleLocalizationSubmit} initialValues={{
                            language: LanguagesData[0],
                            country: CountriesData[0],
                            ...localization,
                        }}>
                            <FormInput field="country" getOptionLabel={o => o.name} getOptionValue={o => o.countryKey} options={CountriesData} selectComponents={{
                                Option: CountrySelectOption,
                                SingleValue: CountrySelectValue,
                            }} type={FormInputTypes.SELECT} label="Where is your book written?"/>
                            <FormInput field="language" getOptionLabel={o => o.name} getOptionValue={o => o.languageKey} options={LanguagesData} selectComponents={{
                                Option: LanguageSelectOption,
                                SingleValue: LanguageSelectValue,
                            }} type={FormInputTypes.SELECT} label="In what language is your book written?"/>
                            <div>
                                <FormButton size="large">
                                    <span className="SemiBoldText">Continue</span>
                                </FormButton>
                            </div>
                        </Form>
                    </div>}
                    {step === 'pricing' && <div className="PublishingInformation__FormWrapper">
                        <Form onFormUpdate={(data) => this.handleFormDataUpdate('pricing', data)} onSubmit={this.handlePriceSubmit} initialValues={{
                            price: "0",
                            ...pricing,
                        }}>
                            <FormInput field="price" label="Book Price" type={FormInputTypes.TEXT}/>
                            <div>
                                <FormButton size="large">
                                    <span className="SemiBoldText">Continue</span>
                                </FormButton>
                            </div>
                        </Form>
                    </div>}
                    {step === 'allocation' && <div className="PublishingInformation__FormWrapper">
                        <Form onFormUpdate={(data) => this.handleFormDataUpdate('allocation', data)} onSubmit={this.handleAllocationSubmit} initialValues={{
                            ...allocation,
                        }}>
                            <div>
                                <div>Fixed rate goes to the platform</div>
                                <div>
                                    <div>Scriptarnica</div>
                                    <div>5%</div>
                                </div>
                            </div>
                            <FormButton size="large">
                                <span className="SemiBoldText">Finish</span>
                            </FormButton>
                        </Form>
                    </div>}

                </div>
            </div>
        );
    }
}

export default PublishingInformation;
