import React from 'react';

import './AddTokensProviderStep.scss';
import WalletProviderSelect from "../WalletProviderSelect/WalletProviderSelect";

const AddTokensProviderStep = ({onSelect}) => {
    return (
        <div className="AddTokensProviderStep">
            <h2 className="AddTokensModal__StepHeading">Let’s Add More Coins</h2>
            <p className="AddTokensModal__StepDescription">Start by connecting with the external wallet. Select one of the options below</p>
            <WalletProviderSelect onSelect={onSelect}/>
        </div>
    );
};

export default AddTokensProviderStep;
