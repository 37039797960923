import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from "../../Elements/Dialog/Dialog";
import ReadingMenuContent from "./ReadingMenuContent";
import Icon from "../../Elements/Icon/Icon";

import './ReadingMenu.scss';

const ReadingMenuTypes = {
    CONTENTS: 'contents',
};

const ReadingMenuNavigation = ({onSelect}) => {
    return <div className="ReadingMenuNavigation">
        <div className="ReadingMenuNavigation__Item" onClick={() => onSelect(ReadingMenuTypes.CONTENTS)}>
            <Icon icon="list" className="ReadingMenuNavigation__Item__Icon"/>
            <span className="ReadingMenuNavigation__Item__Label">Table of content</span>
        </div>
        <div className="ReadingMenuNavigation__Item ReadingMenuNavigation__Item--Disabled" onClick={() => onSelect(ReadingMenuTypes.CONTENTS)}>
            <Icon icon="book" className="ReadingMenuNavigation__Item__Icon"/>
            <span className="ReadingMenuNavigation__Item__Label">Book description</span>
        </div>
        <div className="ReadingMenuNavigation__Item ReadingMenuNavigation__Item--Disabled" onClick={() => onSelect(ReadingMenuTypes.CONTENTS)}>
            <Icon icon="search" className="ReadingMenuNavigation__Item__Icon"/>
            <span className="ReadingMenuNavigation__Item__Label">Search inside book</span>
        </div>
        <div className="ReadingMenuNavigation__Item ReadingMenuNavigation__Item--Disabled" onClick={() => onSelect(ReadingMenuTypes.CONTENTS)}>
            <Icon icon="share-2" className="ReadingMenuNavigation__Item__Icon"/>
            <span className="ReadingMenuNavigation__Item__Label">Share</span>
        </div>
        <div className="ReadingMenuNavigation__Item ReadingMenuNavigation__Item--Disabled" onClick={() => onSelect(ReadingMenuTypes.CONTENTS)}>
            <Icon icon="alert-triangle" className="ReadingMenuNavigation__Item__Icon"/>
            <span className="ReadingMenuNavigation__Item__Label">Report an issue</span>
        </div>
    </div>
}

class ReadingMenu extends Component {
    state = {
        menu: null,
    };

    handleAfterClose = () => {
        this.setState({
            menu: null,
        });
    };

    handleMenuSelect = (menu) => {
        this.setState({
            menu,
        });
    };

    render() {
        const {onClose, open, content, handleGoTo} = this.props;
        const {menu} = this.state;

        return (
            <div className="ReadingMenu">
                <Dialog fullPage open={open} onClose={onClose} onAfterClose={this.handleAfterClose}>
                    {!menu && <ReadingMenuNavigation onSelect={this.handleMenuSelect} />}
                    {menu === ReadingMenuTypes.CONTENTS && <ReadingMenuContent content={content} handleGoTo={handleGoTo}/>}
                </Dialog>
            </div>
        );
    }
}

ReadingMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ReadingMenu;
