import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";

import PrivateRoute from "../Components/PrivateRoute/PrivateRoute";

import OnboardingPage from "./Onboarding/Onboarding.page";
import IntroductionPage from "./Introduction/Introduction.page";
import HomePage from "./Home/Home.page";
import LoginPage from "./Login/Login.page";
import PageNotFoundPage from "./Misc/PageNotFound.page";
import CheckEmailPage from "./Login/CheckEmail.page";
import ReadBookPage from "./Reading/ReadBookPage";
import Auth0LoginPage from "./Login/Auth0Login.page";
import BookPage from "./Book/Book.page";
import ExplorePage from "./Explore/Explore.page";
import LibraryPage from "./Library/Library.page";
import ProfilePage from "./Profile/Profile.page";
import LoginCodePage from "./Login/LoginCode.page";
import OAuthPage from "./Login/OAuth.page";
import ProfileBillingPage from "./Profile/ProfileBilling.page";
import ProfileSettingsPage from "./Profile/ProfileSettings.page";
import SearchPage from "./Explore/Search.page";
import WritePage from "./Write/Write.page";
import PublishPage from "./Write/Publish.page";
import TermsOfServicePage from "./Misc/TermsOfService.page";

const Pages = () => {
    return <Switch>
        <PrivateRoute path="/home" component={HomePage}/>
        <PrivateRoute path="/library" component={LibraryPage}/>
        <PrivateRoute path="/explore" component={ExplorePage}/>
        <PrivateRoute path="/write" component={WritePage}/>
        <PrivateRoute path="/publish" component={PublishPage}/>
        <PrivateRoute path="/search" component={SearchPage}/>
        <PrivateRoute path="/profile" exact component={ProfilePage}/>
        <PrivateRoute path="/profile/settings" exact component={ProfileSettingsPage}/>
        <PrivateRoute path="/profile/app" exact component={ProfileBillingPage}/>
        <PrivateRoute path="/onboarding" component={OnboardingPage}/>
        <PrivateRoute path="/read/:bookId" component={ReadBookPage}/>
        <PrivateRoute path="/book/:bookId" component={BookPage}/>
        <Route path="/terms-of-service" component={TermsOfServicePage}/>
        <Route path="/intro" component={IntroductionPage}/>
        <Route path="/login" component={LoginPage}/>
        <Route path="/auth0-login" component={Auth0LoginPage}/>
        <Route path="/check-email" component={CheckEmailPage}/>
        <Route path="/login-code" component={LoginCodePage}/>
        <Route path="/oauth" component={OAuthPage}/>
        <Redirect exact from="/" to="/home"/>
        <Route component={PageNotFoundPage}/>
    </Switch>;
}

export default Pages;
