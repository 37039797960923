import * as Sentry from "@sentry/react";
import Api from "../../Utils/ApiProvider";
import LocalStorage from "../../Utils/LocalStorage";
import Book from "./Book.model";
import IpfsService from "../../Services/IpfsService";
import {LocalStorageKeys} from "../../Common/constants";

export const FETCH_BOOK = 'FETCH_BOOK_ACTION';
export const FETCH_BOOK_CONTENT = 'FETCH_BOOK_CONTENT_ACTION';
export const FETCH_BOOKS = 'FETCH_BOOKS_ACTION';
export const FETCH_CURRENT_READING = 'FETCH_CURRENT_READING_ACTION';

export const fetchCurrentlyReadingBooks = () => {
    return async dispatch => {
        dispatch({
            type: FETCH_CURRENT_READING,
            books: [],
        });

        return [];
    }
};

export const fetchBook = (bookId) => {
    return async dispatch => {
        const {data} = await Api.get(`/api/v1/book/${bookId}`);

        if (!data) {
            return null;
        }

        let hasPurchased;

        try {
            await Api.get(`/api/v1/user/book/${bookId}`);

            hasPurchased = true;
        } catch (e) {
            hasPurchased = false;
        }

        const book = Book.buildFromResponse(data, hasPurchased);

        dispatch({
            type: FETCH_BOOK,
            book,
        });

        return book;
    }
}

export const fetchBookContent = (book) => {
    return async dispatch => {
        const encodedBookContent = await IpfsService.readFileContent(book);

        if (!encodedBookContent) {
            return null;
        }

        dispatch({
            type: FETCH_BOOK_CONTENT,
            bookId: book.id,
            contentPath: book.path,
            content: encodedBookContent,
        })

        return encodedBookContent;
    }
};

export const fetchExploreContent = () => {
    return async dispatch => {
        const {data} = await Api.get('/api/v1/user/explore');

        if (!data?.genre) {
            return []
        }

        const recommendations = {};

        Object.keys(data.genre).forEach(genre => {
            recommendations[genre] = data.genre[genre].map(Book.buildFromResponse);
        });

        return recommendations;
    };
}

export const fetchUserLibrary = () => {
    return async dispatch => {
        const {data} = await Api.get('/api/v1/user/books');

        if (!data) {
            return []
        }

        const books = data.map(Book.buildFromResponse);

        dispatch({
            type: FETCH_BOOKS,
            books,
        });

        return books;
    };
}

/**
 * @param {string} query
 * @param {Object} filters
 * @param {number[]} filters.price
 * @param {CategoryTypes[]} filters.categories
 * @param {number} filters.rating
 */
export const findBooksBySearch = (query, filters) => {
    return async dispatch => {
        let books = [];

        const params = {
            query,
        };

        if (filters?.price) {
            params.price_from = filters.price[0];
            params.price_to = filters.price[1];
        }

        if (filters?.categories) {
            params.genre = filters.categories.join(',');
        }

        if (filters?.rating) {
            params.rating_from = 0;
            params.rating_to = filters.rating;
        }

        try {
            const {data} = await Api.get(`/api/v1/books`, {
                params,
            });

            books = data.map(Book.buildFromResponse);
        } catch (e) {
            Sentry.captureException(e);
        }

        return books;
    };
}

/**
 * @return {function(): }
 */
export const getRecentSearches = () => {
    return () => {
        const currentRecentSearches = LocalStorage.getItem(LocalStorageKeys.RECENT_SEARCHES) || [];

        return currentRecentSearches.map(data => new Book(data));
    };
}

/**
 * @param {Book} book
 */
export const addBookToRecentSearches = (book) => {
    return () => {
        const currentRecentSearches = LocalStorage.getItem(LocalStorageKeys.RECENT_SEARCHES) || [];

        const newRecentSearches = [
            book,
            ...currentRecentSearches.filter(cb => cb.id !== book.id).slice(0, 9),
        ];

        LocalStorage.setItem(LocalStorageKeys.RECENT_SEARCHES, newRecentSearches);

        return newRecentSearches;
    };
};
