import React, {useEffect, useState} from 'react';

import Button from "../../Elements/Button/Button";
import Icon from "../../Elements/Icon/Icon";
import {getAmountForToken, getFormattedScrptPrice} from "../../Utils/Ethereum";
import {SupportedTokens} from "../../Common/constants";

import './WithdrawTokensModalAmountStep.scss';

/**
 * @param {BigNumber} initialAmount
 * @param {BigNumber} balance
 * @param onSwap
 */
const WithdrawTokensModalAmountStep = ({initialAmount, balance, onSwap}) => {
    const [amount, setAmount] = useState('');
    const [error, setError] = useState(null);

    const withdrawMinimum = getAmountForToken("10", SupportedTokens.SCRPT);

    function handleAmountChange(newAmount) {
        const parsedAmount = getAmountForToken(newAmount || "0", SupportedTokens.SCRPT);

        if (parsedAmount.lt(withdrawMinimum)) {
            setError("Minimum amount is 10 SCRPT");
        } else if (parsedAmount.gt(balance)) {
            setError("Insufficient balance");
        } else {
            setError(null);
        }

        setAmount(newAmount);
    }

    useEffect(() => {
        if (initialAmount) {
            handleAmountChange(getFormattedScrptPrice(initialAmount, SupportedTokens.SCRPT));
        } else {
            handleAmountChange('10');
        }
        // We are disabling this because it gets to complicated to write hook compatible code.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const amountValue = getAmountForToken(amount || "0", SupportedTokens.USDT);

    return (
        <div className="WithdrawTokensModalAmountStep">
            {/*<div className="WithdrawTokensModalAmountStep__Description">Let’s start by swapping your SCRPT into ETH or ERC-20 token.</div>*/}
            <div className="WithdrawTokensModalAmountStep__Description">Let’s start by swapping your SCRPT into USDT token.</div>
            <div className="WithdrawTokensModalAmountStep__Input">
                <input className="WithdrawTokensModalAmountStep__Input__Control" autoFocus value={amount} onChange={event => handleAmountChange(event.target.value)} type="text" pattern="[0-9]+([,\.][0-9]+)?"  inputMode="numeric"/>
                <div className="WithdrawTokensModalAmountStep__Input__Token">SCRPT</div>
            </div>
            {!error && <div
                className="WithdrawTokensModalAmountStep__InputBalance">You will have {getFormattedScrptPrice(balance.sub(amountValue), SupportedTokens.SCRPT)} SCRPT left</div>}
            {!!error && <div className="WithdrawTokensModalAmountStep__InputError">{error}</div>}
            <div className="WithdrawTokensModalAmountStep__SwapInfo">
                <div className="WithdrawTokensModalAmountStep__SwapInfo__For">To</div>
                <div className="WithdrawTokensModalAmountStep__SwapInfo__Token">
                    <Icon icon="eth"/>
                    <span>Tether USD</span>
                </div>
                <div className="WithdrawTokensModalAmountStep__SwapInfo__Cost">
                    <div className="WithdrawTokensModalAmountStep__SwapInfo__Cost__Amount">{getFormattedScrptPrice(amountValue, SupportedTokens.USDT)}</div>
                    <div className="WithdrawTokensModalAmountStep__SwapInfo__Cost__Token">USDT</div>
                </div>
                <div className="WithdrawTokensModalAmountStep__SwapInfo__Fee">+ transaction fee</div>
            </div>
            <Button stretch onClick={() => onSwap(amount)} disabled={!!error} color="turquoise" size="large">
                <span className="UppercaseText SemiBoldText">Swap</span>
            </Button>
        </div>
    );
};

export default WithdrawTokensModalAmountStep;
