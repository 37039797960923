import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";

import Book from "../../Core/Book/Book.model";
import Container from "../../Elements/Container/Container";
import Button from "../../Elements/Button/Button";
import Icon from "../../Elements/Icon/Icon";
import Rating from "../Rating/Rating";

import './BookHedaer.scss';
import {getFormattedScrptPrice} from "../../Utils/Ethereum";
import {SupportedTokens} from "../../Common/constants";
import BookCover from "../BookCover/BookCover";
import ButtonGroup from "../../Elements/ButtonGroup/ButtonGroup";
import GiftBookButton from "../GiftBookButton/GiftBookButton";

/**
 * @param {Book} book
 * @param {function} onBuyBook
 * @param {function} onGiftBook
 * @param {History} history
 */
const BookHeader = ({book, onBuyBook, onGiftBook, history}) => {
    return (
        <div className="BookHeader">
            <div className="BookHeader__Background">
                <div className="BookHeader__Background__Cover">
                    <BookCover book={book} className="BookHeader__Background__Cover__Image"/>
                </div>
                <div className="BookHeader__Background__Overlay"/>
            </div>
            <Container>
                <div className="BookHeader__Content">
                    <div onClick={() => history.push('/explore')} className="BookHeader__Content__BackButton">
                        <Icon icon="x"/>
                    </div>
                    <div className="BookHeader__Content__Cover">
                        <BookCover book={book} className="BookHeader__Content__Cover__Image"/>
                    </div>
                    <div className="BookHeader__Content__Info">
                        <Rating rating={book.rating}/>
                        <h2 className="BookHeader__Content__Info__Title">{book.title}</h2>
                        <div className="BookHeader__Content__Info__Author">by {book.author}</div>
                    </div>
                    <div className="BookHeader__Content__Actions">
                        <ButtonGroup>
                            {!book.purchased && !book.isFree() && <Button icon="scrpt-token" iconPosition="left" color="turquoise" size="large" onClick={onBuyBook}>
                                <span className="BoldText UppercaseText">{getFormattedScrptPrice(book.price, SupportedTokens.SCRPT)} Buy</span>
                            </Button>}
                            {book.purchased && <Button icon="book-open" iconPosition="left" color="white" size="large" to={`/read/${book.id}`}>
                                <span className="BoldText UppercaseText">Read</span>
                            </Button>}
                            {!book.purchased && book.isFree() && <Button icon="book-open" iconPosition="left" color="white" size="large" onClick={onBuyBook}>
                                <span className="BoldText UppercaseText">Read</span>
                            </Button>}
                            {!book.isFree() && <GiftBookButton onGiftBook={onGiftBook}/>}
                        </ButtonGroup>
                    </div>
                </div>
            </Container>
        </div>
    );
};

BookHeader.propTypes = {
    book: PropTypes.instanceOf(Book),
    loading: PropTypes.bool,
    onBuyBook: PropTypes.func.isRequired,
    onGiftBook: PropTypes.func.isRequired,
};

export default withRouter(BookHeader);
