import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import NavigatorUtil from "../../Utils/NavigatorUtil";

import Button from "../../Elements/Button/Button";
import Icon from "../../Elements/Icon/Icon";

import './AddTokensDepositStep.scss';

const AddTokensDepositStep = ({wallet, onDeposited}) => {
    return (
        <div className="AddTokensDepositStep">
            <h2 className="AddTokensModal__StepHeading">Deposit ETH or ERC-20 tokens</h2>
            <p className="AddTokensModal__StepDescription">This is your public Fortmatic address for receiving funds. Scan the QR code with your wallet app that already holds some ETH or ERC-20 tokens.</p>
            <div className="AddTokensDepositStep__WalletInfo">
                <div className="AddTokensDepositStep__WalletInfo__QrCode">
                    <QRCode value={wallet} bgColor="#ffffff00" fgColor="#ffffff"/>
                </div>
                <div className="AddTokensDepositStep__WalletInfo__Address">
                    {wallet}
                </div>
            </div>
            <div className="AddTokensDepositStep__Actions">

                <CopyToClipboard text={wallet}>
                    <div className="AddTokensDepositStep__Actions__Button">
                        <Icon icon="copy"/>
                        <span> Copy</span>
                    </div>
                </CopyToClipboard>
                {NavigatorUtil.canShare() && <div onClick={() => NavigatorUtil.shareData("Wallet Address", wallet)} className="AddTokensDepositStep__Actions__Button">
                    <Icon icon="share"/>
                    <span> Share</span>
                </div>}
            </div>
            <Button color="turquoise" size="large" onClick={onDeposited} stretch>
                <span className="BoldText">I MADE MY DEPOSIT</span>
            </Button>
        </div>
    );
};

AddTokensDepositStep.propTypes = {
    wallet: PropTypes.string.isRequired,
};

export default AddTokensDepositStep;
