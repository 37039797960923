import {SET_INTRODUCTION_FINISHED} from "./App.actions";

const initialState = {
    introductionFinished: false,
}

const AppReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INTRODUCTION_FINISHED:
            return {
                ...state,
                introductionFinished: action.introductionFinished,
            }
        default:
            return state;
    }
}

export default AppReducer;
