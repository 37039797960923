import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from "../Icon/Icon";

import './Input.scss';

const InputColorClassMap = {
    "dark": "Input--Color__Dark"
}

const Input = ({value, onChange, error, color, onBlur, buttonIcon, placeholder, onButtonClick, type, buttonType}) => {
    const [isFocused, setFocused] = useState(false);

    return (
        <div className={classNames("Input", InputColorClassMap[color], {
            "Input--Focused": isFocused,
            "Input--HasValue": !!value,
            "Input--HasError": error,
        })}>
            <div className={classNames("Input__Placeholder", {
                "Input__Placeholder--Active": isFocused || !!value,
            })}>{placeholder}</div>
            <input className="Input__Control"
                   type={type}
                   value={value}
                   onChange={event => onChange(event.target.value, event)}
                   onBlur={() => {
                       if (onBlur) {
                           onBlur();
                       }

                       setFocused(false);
                   }}
                   onFocus={() => setFocused(true)}/>
            {!!buttonIcon && !!onButtonClick && <button type={buttonType} onClick={onButtonClick} className="Input__Button">
                <Icon icon={buttonIcon} className="Input__Button__Icon"/>
            </button>}
        </div>
    );
};

Input.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    onButtonClick: PropTypes.func,
    buttonIcon: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    buttonType: PropTypes.string,
    error: PropTypes.bool,
    color: PropTypes.oneOf(["light", "dark"]),
}

Input.defaultProps = {
    onButtonClick: () => {},
    onChange: () => {},
    type: "text",
    buttonType: "button",
    color: "light",
}

export default Input;
