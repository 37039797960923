import {utils as EthereumUtils} from "ethers";

import {getUser} from "../../Selectors/AuthSelectors";

import Api from "../../Utils/ApiProvider";

import SidechainService from "../../Services/SidechainService";
import FortmaticService from "../../Services/FortmaticService";
import PaymentMethod from "./PaymentMethod.model";


export const FETCH_SCRIPT_BALANCE = 'FETCH_SCRIPT_BALANCE_ACTION';
export const BUY_BOOK = 'BUY_BOOK_ACTION';
export const GIFT_BOOK = 'GIFT_BOOK_ACTION';
export const DEPOSITED_FUNDS = 'DEPOSITED_FUNDS_ACTION';
export const WITHDREW_FUNDS = 'WITHDREW_FUNDS_ACTION';
export const CREATE_PAYMENT_METHOD = 'CREATE_PAYMENT_METHOD_ACTION';
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD_ACTION';
export const FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS_ACTION';

export const fetchUserScriptBalance = () => {
    return async (dispatch, getState) => {
        const state = getState();

        const user = getUser(state);

        try {
            const balance = await SidechainService.getUserScriptBalance(user.scriptarnicaWallet);

            dispatch({
                type: FETCH_SCRIPT_BALANCE,
                balance,
            })

            return balance;
        } catch (e) {
            return false;
        }
    }
};

/**
 * @param {SupportedTokens} token
 * @param {BigNumber} amount
 */
export const depositFunds = (token, amount) => {
    return async (dispatch, getState) => {
        const state = getState();

        const user = getUser(state);

        try {
            const wallet = await FortmaticService.getUserAccount();
            const nonce = FortmaticService.getRandomNonce();

            const tokenContract = FortmaticService.getTokenContract(token);

            const message = await FortmaticService.signDepositTransaction(token, amount, user, nonce);

            await Api.post(`/api/v1/deposit`, {
                amount: EthereumUtils.hexValue(amount),
                from: wallet,
                token: tokenContract.address,
                nonce: EthereumUtils.hexValue(nonce),
                signature: message,
            });

            dispatch({
                type: DEPOSITED_FUNDS,
                amount,
            });

            return true;
        } catch (e) {
            console.log(e)
            return false;
        }
    }
}

/**
 * @param {string} withdrawAddress
 * @param {BigNumber} amount
 */
export const withdrawFunds = (withdrawAddress, amount) => {
    return async dispatch => {
        try {
            await Api.post(`/api/v1/withdraw`, {
                amount: EthereumUtils.hexValue(amount),
                to: withdrawAddress,
            });

            dispatch({
                type: WITHDREW_FUNDS,
                amount,
                address: withdrawAddress,
            });

            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    };
};

/**
 * @param {Book} book
 */
export const buyBook = (book) => {
    return async dispatch => {
        if (!book) {
            throw new Error('No book propagated');
        }

        try {
            await Api.post(`/api/v1/user/book/${book.id}`, {
                path: book.path,
            });

            dispatch({
                type: BUY_BOOK,
                bookId: book.id,
            })

            await dispatch(fetchUserScriptBalance());

            return true;
        } catch (e) {
            return false;
        }
    };
};

/**
 * @param {Book} book
 */
export const giftBook = (book, email) => {
    return async dispatch => {
        if (!book || !email) {
            throw new Error('No book propagated');
        }

        try {
            await Api.post(`/api/v1/user/book/${book.id}/gift`, {
                email,
                path: book.path,
            });

            dispatch({
                type: GIFT_BOOK,
                bookId: book.id,
            })

            await dispatch(fetchUserScriptBalance());

            return true;
        } catch (e) {
            return false;
        }
    };
};


export const createPaymentMethod = (token) => {
    return async dispatch => {
        try {
            const {data} = await Api.post(`/api/v1/user/payment-method`, {
                token,
            });

            let paymentMethod = PaymentMethod.buildFromResponse(data);

            dispatch({
                type: CREATE_PAYMENT_METHOD,
                paymentMethod,
            });

            return paymentMethod;
        } catch (e) {
            return null;
        }
    }
}

export const deletePaymentMethod = (paymentMethodId) => {
    return async dispatch => {
        try {
            await Api.delete(`/api/v1/user/payment-method/${paymentMethodId}`);

            dispatch({
                type: DELETE_PAYMENT_METHOD,
                paymentMethodId,
            });

            return true;
        } catch (e) {
            return false;
        }
    }
}

export const fetchPaymentMethods = () => {
    return async dispatch => {
        try {
            const {data} = await Api.get(`/api/v1/user/payment-methods`);

            let paymentMethods = data.map(PaymentMethod.buildFromResponse);

            dispatch({
                type: FETCH_PAYMENT_METHODS,
                paymentMethods,
            });

            return paymentMethods;
        } catch (e) {
            return null;
        }
    }
}

/**
 * @param {PaymentMethod} pm
 * @param {string} amount
 */
export const chargePaymentMethod = (pm, amount) => {
    return async dispatch => {
        try {
            const parsedAmount = parseInt(parseFloat(amount) * 100);

            await Api.post(`/api/v1/user/payment-method/${pm.id}/charge`, {
                amount: parsedAmount,
            });

            return true;
        } catch (e) {
            return false;
        }
    }
};
