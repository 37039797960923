import React from 'react';
import Icon from "../../Elements/Icon/Icon";
import {Link} from "react-router-dom";
import Container from "../../Elements/Container/Container";

import './ProfileNavigation.scss';

const ProfileNavigation = () => {
    return (
        <div className="ProfileNavigation">
            <Container>
                <Link className="ProfileNavigation__Link" to="/profile/settings">
                    <div className="ProfileNavigation__Link__Icon">
                        <Icon icon="user"/>
                    </div>
                    <div className="ProfileNavigation__Link__Info">
                        <div className="ProfileNavigation__Link__Info__Label">Account Details</div>
                        <div className="ProfileNavigation__Link__Info__Description">Set your profile details</div>
                    </div>
                    <div className="ProfileNavigation__Link__ArrowIcon">
                        <Icon icon="chevron-right"/>
                    </div>
                </Link>
                <Link className="ProfileNavigation__Link ProfileNavigation__Link--Disabled" to="/profile/app">
                    <div className="ProfileNavigation__Link__Icon">
                        <Icon icon="settings"/>
                    </div>
                    <div className="ProfileNavigation__Link__Info">
                        <div className="ProfileNavigation__Link__Info__Label">App Settings</div>
                        <div className="ProfileNavigation__Link__Info__Description">Make the app work according to your preferences</div>
                    </div>
                    <div className="ProfileNavigation__Link__ArrowIcon">
                        <Icon icon="chevron-right"/>
                    </div>
                </Link>
                <div className="ProfileNavigation__Separator"/>
                <a className="ProfileNavigation__Link" href="mailto:scriptarnica@scriptarnica.com">
                    <div className="ProfileNavigation__Link__Icon">
                        <Icon icon="help-circle"/>
                    </div>
                    <div className="ProfileNavigation__Link__Info">
                        <div className="ProfileNavigation__Link__Info__Label">Help</div>
                    </div>
                    <div className="ProfileNavigation__Link__ArrowIcon">
                        <Icon icon="chevron-right"/>
                    </div>
                </a>
                <Link className="ProfileNavigation__Link" to="/terms-of-service">
                    <div className="ProfileNavigation__Link__Icon">
                        <Icon icon="paperclip"/>
                    </div>
                    <div className="ProfileNavigation__Link__Info">
                        <div className="ProfileNavigation__Link__Info__Label">Terms of Service</div>
                    </div>
                    <div className="ProfileNavigation__Link__ArrowIcon">
                        <Icon icon="chevron-right"/>
                    </div>
                </Link>
            </Container>
        </div>
    );
};

export default ProfileNavigation;
