import Auth0Service from "../../Services/Auth0Service";
import Api, {ApiProvider} from "../../Utils/ApiProvider";
import User from "./User.model";
import * as Sentry from "@sentry/react";

export const LOGIN_USER = 'LOGIN_USER_ACTION';
export const AUTH0_MAGIC_LINK_USER = 'AUTH0_MAGIC_LINK_USER_ACTION';
export const CHECK_SESSION_AND_LOGIN_USER = 'CHECK_SESSION_AND_LOGIN_USER_ACTION';
export const LOGOUT_USER = 'LOGOUT_USER_ACTION';

export const passwordLessLogin = (email) => {
    return async dispatch => {
        try {
            await Auth0Service.passwordLessLogin(email);

            dispatch({
                type: LOGIN_USER,
                email,
            });

            return true;
        } catch (e) {
            return false;
        }
    }
}

export const loginWithOtpCode = (email, code) => {
    return async dispatch => {
        try {
            const response = await Auth0Service.loginWithOtpCode(email, code);

            return response;
        } catch (e) {
            return false;
        }
    }
}

export const loginWithSocialAccount = (social) => {
    return async dispatch => {
        try {
            const response = await Auth0Service.loginWithSocial(social);

            return response;
        } catch (e) {
            return false;
        }
    }
};

export const parseAuth0HashAndLogin = (hash) => {
    return async dispatch => {
        try {
            const data = await new Promise((resolve, reject) => {
                Auth0Service.parseHash(hash, resolve, reject);
            });

            if (!data?.authResult?.idToken) {
                return {
                    error: 'no_id_token',
                    errorDescription: 'No id token was provided from Auth0',
                };
            }

            ApiProvider.setAuthorizationHeader(data?.authResult?.idToken);

            const {data: responseData} = await Api.get('/api/v1/user');

            if (!responseData) {
                return {
                    error: 'load_user_failed',
                    errorDescription: 'Failed to load user from api',
                };
            }

            const user = User.buildFromResponse(responseData);

            dispatch({
                type: AUTH0_MAGIC_LINK_USER,
                user,
            });

            return data;
        } catch (e) {
            Sentry.captureException(e);
            return {
                error: 'error',
                errorDescription: 'Error catched',
            };
        }
    };
};

export const checkSessionAndLogin = () => {
    return async dispatch => {
        try {
            const data = await new Promise((resolve, reject) => {
                Auth0Service.checkSession(resolve, reject);
            });

            if (!data?.idToken) {
                return null;
            }

            ApiProvider.setAuthorizationHeader(data?.idToken);

            const {data: responseData} = await Api.get('/api/v1/user');

            if (!responseData) {
                return null;
            }

            const user = User.buildFromResponse(responseData);

            Sentry.configureScope(scope => {
                scope.setUser({
                    id: user.id,
                    email: user.email,
                });
            });

            dispatch({
                type: CHECK_SESSION_AND_LOGIN_USER,
                user,
            });

            return user;
        } catch (e) {
            Sentry.captureException(e);
            console.error(e);
            return e;
        }
    }
}

export const logout = () => {
    return async dispatch => {
        try {
            await Auth0Service.logout();

            dispatch({
                type: LOGOUT_USER,
            });

            return true;
        } catch (e) {
            Sentry.captureException(e);
            return false;
        }
    }
}

