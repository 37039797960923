import React, {useState} from "react";

const Image = ({fallbackSrc, fallbackRender, ...props}) => {
    const [error, setError] = useState(null);

    if (error) {
        if (fallbackRender) {
            return fallbackRender();
        }

        if (fallbackSrc) {
            return <img alt="" {...props} src={fallbackSrc}/>;
        }
    }

    return <img alt="" {...props} onError={() => setError(true)}/>;
};

export default Image;
