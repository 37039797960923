import React from 'react';
import Icon from "../../Elements/Icon/Icon";

import './WalletProviderSelect.scss';

const WalletProviderSelect = ({onSelect}) => {
    return (
        <div className="WalletProviderSelect">
            <div onClick={() => onSelect('fortmatic')} className="WalletProviderSelect__Option">
                <Icon className="WalletProviderSelect__Option__Logo" icon="fortmatic"/>
                <div className="WalletProviderSelect__Option__Info">
                    <span className="WalletProviderSelect__Option__Info__Name">Fortmatic</span>
                    <span className="WalletProviderSelect__Option__Info__Badge">Recommended</span>
                </div>
                <Icon className="WalletProviderSelect__Option__Arrow" icon="chevron-right"/>
            </div>
            <div onClick={() => onSelect('metamask')} className="WalletProviderSelect__Option WalletProviderSelect__Option--Disabled">
                <Icon className="WalletProviderSelect__Option__Logo" icon="metamask"/>
                <div className="WalletProviderSelect__Option__Info">
                    <span className="WalletProviderSelect__Option__Info__Name">Metamask</span>
                    <span className="WalletProviderSelect__Option__Info__Badge">Soon</span>
                </div>
                <Icon className="WalletProviderSelect__Option__Arrow" icon="chevron-right"/>
            </div>
            <div onClick={() => onSelect('wallet_connect')} className="WalletProviderSelect__Option WalletProviderSelect__Option--Disabled">
                <Icon className="WalletProviderSelect__Option__Logo" icon="wallet-connect"/>
                <div className="WalletProviderSelect__Option__Info">
                    <span className="WalletProviderSelect__Option__Info__Name">Wallet Connect</span>
                    <span className="WalletProviderSelect__Option__Info__Badge">Soon</span>
                </div>
                <Icon className="WalletProviderSelect__Option__Arrow" icon="chevron-right"/>
            </div>
        </div>
    );
};

export default WalletProviderSelect;
