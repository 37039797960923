import {DeviceCutoffMap, DeviceTypes} from "../Common/constants";

/**
 * @param width
 * @return {DeviceTypes}
 */
export function getDeviceForWidth(width) {
    if (width <= DeviceCutoffMap[DeviceTypes.MOBILE]) {
        return DeviceTypes.MOBILE;
    }

    if (width <= DeviceCutoffMap[DeviceTypes.TABLET]) {
        return DeviceTypes.TABLET;
    }

    return DeviceTypes.DESKTOP;
}

/**
 * @param {DeviceTypes} device
 * @return {boolean}
 */
export function isMobileDevice(device) {
    return device === DeviceTypes.MOBILE;
}
