import React from 'react';
import ButtonGroup from "../../Elements/ButtonGroup/ButtonGroup";
import Button from "../../Elements/Button/Button";
import Icon from "../../Elements/Icon/Icon";

/**
 * @param {PaymentMethod} pm
 * @param onConfirm
 * @param onBack
 */
const AddTokensRemovePaymentStep = ({pm, onConfirm, onBack}) => {
    return (
        <div>
            <h2 className="AddTokensModal__StepHeading">Remove Payment</h2>
            <p className="AddTokensModal__StepDescription">Are your sure you wish to remove this payment method?</p>
            <div className="AddTokensStripeStep__PaymentMethods">
                <div className="AddTokensStripeStep__PaymentMethod">
                    <Icon icon="credit-card" className="AddTokensStripeStep__PaymentMethod__Icon"/>
                    <span className="AddTokensStripeStep__PaymentMethod__Label">
                        <span>{pm.cardBrand} </span>
                        <span>**** - {pm.last4}</span>
                    </span>
                </div>
            </div>
            <ButtonGroup>
                <Button onClick={onBack} size="large" color="white">
                    <Icon icon="chevron-left"/>
                </Button>
                <Button color="turquoise" size="large" onClick={onConfirm} stretch>
                    <span className="BoldText">Confirm</span>
                </Button>
            </ButtonGroup>
        </div>
    );
};

export default AddTokensRemovePaymentStep;
