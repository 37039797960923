import React from 'react';

import Button from "../Button/Button";

const FormButton = ({children, color, ...props}) => {
    return (
        <Button type="submit" color={color} {...props}>
            {children}
        </Button>
    );
};

FormButton.defaultProps = {
    color: "turquoise",
}

export default FormButton;
