import React, {forwardRef, PureComponent} from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from "react-markdown";

import './BookContent.scss';
import Container from "../../Elements/Container/Container";
import BookService from "../../Services/BookService";

class BookChapterContent extends PureComponent {
    render() {
        const {chapter, partIndex, chapterIndex} = this.props;

        const chapterContent = BookService.decodeBookContent(chapter.content);

        return <div className="BookPartContent" id={`chapter_${partIndex}_${chapterIndex}`}>
            {!!chapter.name && <h3>{chapter.name}</h3>}
            <ReactMarkdown source={chapterContent}/>
        </div>;
    }
}

class BookPartContent extends PureComponent {
    render() {
        const {part, partIndex} = this.props;

        return <div className="BookPartContent" id={`part_${partIndex}`}>
            {!!part.name && <h2>{part.name}</h2>}
            {part.chapters.map((chapter, index) => <BookChapterContent key={chapter.name + index} partIndex={partIndex} chapterIndex={index} chapter={chapter}/>)}
        </div>;
    }
}

class BookContent extends PureComponent {
    render() {
        const {content, onContentClick, outerRef} = this.props;

        return (
            <div className="BookContent" onClick={onContentClick} ref={outerRef}>
                <Container>
                    {content.map((part, index) => <BookPartContent key={part.name + index} partIndex={index} part={part}/>)}
                </Container>
            </div>
        );
    }
}

BookContent.propTypes = {
    content: PropTypes.array.isRequired,
    onContentClick: PropTypes.func,
};

BookContent.defaultProps = {
    onContentClick: () => {},
}

export default forwardRef((props, ref) =>
    <BookContent outerRef={ref} {...props}/>
);
